import '@/assets/main.css'
import "@fontsource/open-sans/300.css"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/inter/100.css"
import "@fontsource/inter/400.css"
import "@fontsource/inter/600.css"
import "@fontsource/inter/700.css"
import "@fontsource/source-serif-pro/700.css"
import MasonryWall from "@yeger/vue-masonry-wall"
import { head, setupDefaultMeta } from "@/plugins/unhead"
import UDCClient from "@/plugins/UDC"

import { createApp } from "vue"
import { createPinia } from "pinia"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"
import { setupI18n } from "./i18n"

import App from "./App.vue"
import Router from "./router"

const app = createApp(App)
const i18n = setupI18n()
const Pinia = createPinia()

const UDC = new UDCClient({
  batchSize: 10,
  flushInterval: 30000
})

export const useUDC = () => UDC

app.config.globalProperties.$UDC = UDC

Pinia.use(piniaPluginPersistedstate)

app.use(Pinia)
app.use(i18n)
app.use(Router)
app.use(MasonryWall)
app.use(head)

Router.afterEach((to, from) => {
  UDC.trackPageView(
    to.path,
    document.title,
    document.referrer
  )
})

// Also add unload as a fallback
window.addEventListener('unload', () => {
  const sessionDuration = Math.floor((Date.now() - performance.timeOrigin) / 1000)
  UDC.trackSessionEnd(sessionDuration)
  UDC.flush(true)
})

setupDefaultMeta()
app.mount('#app')
