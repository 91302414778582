<script setup lang="ts">
import { useUserStore } from "@/stores/User"
import { useHead } from "@unhead/vue"
import { storeToRefs } from "pinia"
import { ref } from "vue"

useHead({
  meta: [
    { name: "robots", content: "index,follow" },
  ]
})

const userStore = useUserStore()
const { signedIn } = storeToRefs(userStore)

const alert = ref(false)
const testimonials = [
  {
    text: `Submit has been like a breath of fresh air, my feed is full of positivity, I've had no negative experiences at all! A big positive for me is being able to restrict my inbox so that only people I follow can send me a DM, this takes away a lot of anxiety for me personally.`,
    author: "33, Toy, She/Her"
  },
  {
    text: `The transparency report has been my favorite behind the curtain feature so far. I appreciate knowing how the site I'm using is handling user/community safety. In addition the ability to set circles which allow for more specific sharing controls is also one of my current favorite user controls. I can't wait to see how submit continues to evolve.`,
    author: "31, Brat, She/Her"
  },
  {
    text: `It's been an awesome experience to be on a platform that puts users needs first. There's been a lot of new things rolled out in the past year and I’m excited to see what the future holds. Transparent, user focused, and community driven. It's a much needed breath of fresh air for the kink community and looking forward to seeing Submit grow even more.`,
    author: "51, Sadomasochist, She/Her"
  },
  {
    text: `Quite a few lessons had to be learned to finally arrive at something that truly can earn the title of a kinky social network. Submit.gg earns it by showing time and time again that they know and understand what they're doing.`,
    author: "44, Submissive"
  },
  {
    text: `Since joining submit, I've found a place where I belong. Everyone was welcoming and it's been great getting to meet new kinksters who share in the same ones I do and to learn about new ones without being pressured or judged. Submit's commitment to make sure the user is safe with various privacy settings is game changing and they take feedback and listen and implement to the best of their ability. I can't wait to see what's in store.`,
    author: "27, Demon, She/Her"
  }
]

const testimonialContainer = ref<HTMLElement | null>(null)

const showNext = () => {
  if (!testimonialContainer.value) return
  testimonialContainer.value.scrollBy({
    left: testimonialContainer.value.offsetWidth,
    behavior: 'smooth'
  })
}

const showPrevious = () => {
  if (!testimonialContainer.value) return
  testimonialContainer.value.scrollBy({
    left: -testimonialContainer.value.offsetWidth,
    behavior: 'smooth'
  })
}
</script>

<template>
  <main class="mt-20 mb-40">
    <div class="container mx-auto max-w-[1080px] flex justify-center py-24 lg:py-48 px-2">
      <article class="max-w-[544px] space-y-6">
        <h1 class="font-display text-3xl lg:text-5xl font-bold dark:text-gold-500 text-gold-700 text-center">A Future Built for Kink</h1>
        <p>The BDSM, fetish, and kink communities deserve a platform that prioritizes privacy, safety, and inclusivity.</p>
        <p>At Submit, we're building a community-driven social network where you can connect, explore, and express yourself freely. Rooted in trust and respect, Submit empowers you to take control of your content and experience while fostering meaningful connections.</p>
        <p>Join us and help shape the future of kink&mdash;together.</p>
        <ul class="grid xs:grid-cols-2 gap-10 justify-center pt-4 justify-items-center" v-if="!signedIn">
          <li>
            <router-link class="py-2 px-6 rounded-lg dark:bg-submit-900 bg-white font-bold" to="/signin">Sign In</router-link>
          </li>
          <li>
            <router-link class="py-2 px-6 rounded-lg dark:bg-submit-900 bg-white font-bold" to="/signup">Join Waitlist</router-link>
          </li>
        </ul>
      </article>
    </div>
    <div class="container mx-auto max-w-[1080px] dark:bg-submit-900 bg-white rounded-lg px-20 py-6" v-if="alert">
      Alert Bar
    </div>
    <section class="container mx-auto max-w-[1080px] lg:py-40 py-20 grid gap-y-20 px-2">
      <article class="space-y-12 justify-self-center flex flex-col items-center">
        <h2 class="font-display text-3xl lg:text-5xl font-bold dark:text-gold-500 text-gold-700 text-center">Shape Your Experience</h2>
        <p class="max-w-[544px]">Create a safe, respectful, and inclusive space to share and explore. With privacy at the core, Submit provides you with powerful tools to moderate your environment, control your content, and connect with others in meaningful ways.</p>
      </article>
      <ul class="grid md:grid-cols-2 gap-x-20 gap-y-12 px-2">
        <li class="space-y-4">
          <h3 class="flex gap-2 font-display font-bold dark:text-white items-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9996 0.26709L12.5387 0.47334L22.2653 4.22803L23.1653 4.5749L23.2215 5.54053C23.3574 7.87021 22.9918 11.428 21.4731 14.9062C19.9403 18.4124 17.2028 21.8952 12.5903 23.878L11.9996 24.1312L11.409 23.878C6.7965 21.8952 4.059 18.4124 2.52619 14.9062C1.00275 11.4233 0.637126 7.86553 0.777751 5.53584L0.834 4.57022L1.734 4.22803L11.4606 0.478027L11.9996 0.26709ZM14.6246 9.7499C14.6246 8.30147 13.4481 7.1249 11.9996 7.1249C10.5512 7.1249 9.37463 8.30147 9.37463 9.7499C9.37463 10.7952 9.98869 11.6999 10.8746 12.1218V15.3749V16.4999H13.1246V15.3749V12.1218C14.0106 11.6999 14.6246 10.7952 14.6246 9.7499Z" fill="url(#paint0_linear_2949_241)"/>
              <defs>
              <linearGradient id="paint0_linear_2949_241" x1="0.75" y1="11.0805" x2="23.2477" y2="11.0805" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8B6DDF"/>
              <stop offset="1" stop-color="#E177A4"/>
              </linearGradient>
              </defs>
            </svg>
            <span>Privacy-First in Everything We Do</span>
          </h3>
          <p>Your data is yours. From private messages to group interactions, we prioritize your privacy with advanced settings and leading-edge technology.</p>
        </li>
        <li class="space-y-4">
          <h3 class="flex gap-2 font-display font-bold dark:text-white items-center">
            <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 0.333496C5.4 0.333496 1.25 6.55433 0 9.66683C1.25 12.7793 5.4 19.0002 12 19.0002C18.6 19.0002 22.75 12.7793 24 9.66683C22.75 6.55433 18.6 0.333496 12 0.333496ZM18 9.66683C18 12.9793 15.3125 15.6668 12 15.6668C8.6875 15.6668 6 12.9793 6 9.66683C6 6.35433 8.6875 3.66683 12 3.66683C15.3125 3.66683 18 6.35433 18 9.66683ZM8 9.66683C8 11.8752 9.79167 13.6668 12 13.6668C14.2083 13.6668 16 11.8752 16 9.66683C16 7.4585 14.2083 5.66683 12 5.66683C11.7333 5.66683 11.4708 5.69183 11.2167 5.74183L12 9.66683L8.075 8.8835C8.025 9.13766 8 9.40016 8 9.66683Z" fill="url(#paint0_linear_2949_238)"/>
              <defs>
              <linearGradient id="paint0_linear_2949_238" x1="1.6205e-07" y1="8.79183" x2="24" y2="8.79183" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8B6DDF"/>
              <stop offset="1" stop-color="#E177A4"/>
              </linearGradient>
              </defs>
            </svg>
            <span>Leading Content Moderation Technology</span>
          </h3>
          <p>Combining AI and human review, our systems actively detect and remove hate, abuse, discrimination, and prohibited content, ensuring a secure space for all.</p>
        </li>
        <li class="space-y-4">
          <h3 class="flex gap-2 font-display font-bold dark:text-white items-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.0234375 11.25C0.192187 8.49844 1.28906 6 3 4.05937L5.12813 6.1875C3.95156 7.57969 3.1875 9.32812 3.03281 11.25H0.0234375ZM6.1875 5.12813L4.05937 3C6 1.28906 8.49844 0.192187 11.25 0.0234375V3.03281C9.32812 3.19219 7.57969 3.95156 6.1875 5.12813ZM12.75 20.9719C14.6719 20.8125 16.4203 20.0531 17.8125 18.8766L19.9406 21C18 22.7109 15.4969 23.8078 12.75 23.9766V20.9672V20.9719ZM18.8719 17.8125C20.0484 16.425 20.8125 14.6719 20.9672 12.75H23.9766C23.8078 15.4969 22.7109 18 21 19.9406L18.8719 17.8125ZM4.05937 21L6.1875 18.8719C7.575 20.0484 9.32812 20.8125 11.25 20.9672V23.9766C8.49844 23.8078 6 22.7109 4.05937 21ZM3 19.9406C1.28906 18 0.192187 15.4969 0.0234375 12.75H3.03281C3.19219 14.6719 3.95156 16.4203 5.12813 17.8125L3 19.9406ZM19.9406 3L17.8125 5.12813C16.4203 3.95156 14.6719 3.1875 12.75 3.03281V0.0234375C15.4969 0.192187 18 1.28906 19.9406 3ZM21 4.05937C22.7109 6 23.8078 8.49844 23.9766 11.25H20.9672C20.8078 9.32812 20.0484 7.57969 18.8719 6.1875L21 4.05937Z" fill="url(#paint0_linear_2949_251)"/>
              <defs>
              <linearGradient id="paint0_linear_2949_251" x1="0.0234377" y1="10.8772" x2="23.9766" y2="10.8772" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8B6DDF"/>
              <stop offset="1" stop-color="#E177A4"/>
              </linearGradient>
              </defs>
            </svg>
            <span>Circles for Focused Connections</span>
          </h3>
          <p>Create private groups of up to 64 members for sharing specific content and building deeper, more personalized connections.</p>
        </li>
        <li class="space-y-4">
          <h3 class="flex gap-2 font-display font-bold dark:text-white items-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_5560_481)">
              <path d="M14.934 0.44043C14.348 -0.145508 13.3965 -0.145508 12.8105 0.44043L7.18555 6.06543C6.59961 6.65137 6.59961 7.60293 7.18555 8.18887L7.93555 8.93887C8.52148 9.5248 9.47305 9.5248 10.059 8.93887L10.2465 8.75137L15.2527 13.7529L15.0652 13.9404C14.4793 14.5264 14.4793 15.4779 15.0652 16.0639L15.8152 16.8139C16.4012 17.3998 17.3527 17.3998 17.9387 16.8139L23.5637 11.1889C24.1496 10.6029 24.1496 9.65137 23.5637 9.06543L22.8137 8.31543C22.2277 7.72949 21.2762 7.72949 20.6902 8.31543L20.5027 8.50293L15.4965 3.49668L15.684 3.30918C16.2699 2.72324 16.2699 1.77168 15.684 1.18574L14.934 0.435742V0.44043ZM7.80898 13.9404C7.22305 13.3545 6.27148 13.3545 5.68555 13.9404L0.435547 19.1904C-0.150391 19.7764 -0.150391 20.7279 0.435547 21.3139L2.68555 23.5639C3.27148 24.1498 4.22305 24.1498 4.80898 23.5639L10.059 18.3139C10.6449 17.7279 10.6449 16.7764 10.059 16.1904L9.99336 16.1248L12.7496 13.3732L10.6262 11.2498L7.87461 14.0014L7.80898 13.9357V13.9404Z" fill="url(#paint0_linear_5560_481)"/>
              </g>
              <defs>
              <linearGradient id="paint0_linear_5560_481" x1="-0.00390609" y1="10.877" x2="24.0031" y2="10.877" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8B6DDF"/>
              <stop offset="1" stop-color="#E177A4"/>
              </linearGradient>
              <clipPath id="clip0_5560_481">
              <rect width="24" height="24" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            <span>Empowerment Through Self-Moderation</span>
          </h3>
          <p>Shape your interactions by choosing who can engage with you and how. Our tools put you in control of your environment.</p>
        </li>
        <li class="space-y-4">
          <h3 class="flex gap-2 font-display font-bold dark:text-white items-center">
            <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.4932 1.5C3.4932 0.670312 2.8244 0 1.9966 0C1.16879 0 0.5 0.670312 0.5 1.5V3V17.25V22.5C0.5 23.3297 1.16879 24 1.9966 24C2.8244 24 3.4932 23.3297 3.4932 22.5V16.5L6.50043 15.7453C8.42262 15.2625 10.4571 15.4875 12.2296 16.3734C14.2968 17.4094 16.696 17.5359 18.8567 16.7203L20.4796 16.1109C21.0642 15.8906 21.4524 15.3328 21.4524 14.7047V3.09844C21.4524 2.02031 20.3206 1.31719 19.3571 1.8L18.9082 2.025C16.7428 3.1125 14.1939 3.1125 12.0285 2.025C10.3869 1.2 8.50213 0.99375 6.72024 1.43906L3.4932 2.25V1.5Z" fill="url(#paint0_linear_2949_253)"/>
              <defs>
              <linearGradient id="paint0_linear_2949_253" x1="0.5" y1="10.875" x2="21.4524" y2="10.875" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8B6DDF"/>
              <stop offset="1" stop-color="#E177A4"/>
              </linearGradient>
              </defs>
            </svg>
            <span>Comprehensive Reporting Tools</span>
          </h3>
          <p>Report harmful or inappropriate behavior with ease. Our streamlined process helps us maintain a safe and respectful community.</p>
        </li>
        <li class="space-y-4">
          <h3 class="flex gap-2 font-display font-bold dark:text-white items-center">
            <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_5560_479)">
              <path d="M9.75051 16.5C15.1365 16.5 19.5005 12.8062 19.5005 8.25C19.5005 3.69375 15.1365 0 9.75051 0C4.36458 0 0.000513021 3.69375 0.000513021 8.25C0.000513021 10.0594 0.689575 11.7328 1.85676 13.0969C1.6927 13.5375 1.44895 13.9266 1.19114 14.2547C0.966138 14.5453 0.736451 14.7703 0.567701 14.925C0.483326 15 0.413013 15.0609 0.366138 15.0984C0.342701 15.1172 0.323951 15.1312 0.314576 15.1359L0.305201 15.1453C0.047388 15.3375 -0.065112 15.675 0.038013 15.9797C0.141138 16.2844 0.427076 16.5 0.750513 16.5C1.77239 16.5 2.80364 16.2375 3.66145 15.9141C4.0927 15.75 4.49583 15.5672 4.8427 15.3797C6.28645 16.0922 7.95989 16.5 9.75051 16.5ZM21.0005 8.25C21.0005 13.5141 16.3552 17.4797 10.8521 17.9531C11.9911 21.4406 15.7693 24 20.2505 24C22.0411 24 23.7146 23.5922 25.1583 22.8797C25.5099 23.0672 25.9083 23.25 26.3396 23.4141C27.1974 23.7375 28.2286 24 29.2505 24C29.5739 24 29.8646 23.7891 29.963 23.4797C30.0615 23.1703 29.9536 22.8328 29.6911 22.6406L29.6818 22.6312C29.6724 22.6219 29.6536 22.6125 29.6302 22.5938C29.5833 22.5562 29.513 22.5 29.4286 22.4203C29.2599 22.2656 29.0302 22.0406 28.8052 21.75C28.5474 21.4219 28.3036 21.0281 28.1396 20.5922C29.3068 19.2328 29.9958 17.5594 29.9958 15.7453C29.9958 11.3953 26.0161 7.82812 20.9677 7.51875C20.9865 7.75781 20.9958 8.00156 20.9958 8.24531L21.0005 8.25Z" fill="url(#paint0_linear_5560_479)"/>
              </g>
              <defs>
              <linearGradient id="paint0_linear_5560_479" x1="2.02556e-07" y1="10.875" x2="29.999" y2="10.875" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8B6DDF"/>
              <stop offset="1" stop-color="#E177A4"/>
              </linearGradient>
              <clipPath id="clip0_5560_479">
              <rect width="30" height="24" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            <span>Community-Driven Development</span>
          </h3>
          <p>We listen to your feedback and prioritize features that matter most to our community, ensuring Submit evolves with your needs at heart.</p>
        </li>
      </ul>
    </section>
    <section class="py-20 container mx-auto max-w-[1080px] px-4 grid lg:grid-cols-2 gap-y-8 gap-x-20 items-center">
      <div>
        <h2 class="font-display text-3xl lg:text-5xl font-bold dark:text-gold-500 text-gold-700 break-modern mb-4">From our Community</h2>
        <p class="mb-8">Real words from real members of the Submit community. Privacy is our priority, so on this page, you'll only see non-identifying quotes. Sign in and join the Submit Development group to see more!</p>
        <div class="flex gap-4">
          <button
            @click="showPrevious"
            class="flex items-center justify-center px-4 py-3 rounded-full dark:bg-submit-900 bg-white hover:opacity-75 transition-opacity"
          >
            &larr;
          </button>
          <button
            @click="showNext"
            class="flex items-center justify-center px-4 py-3 rounded-full dark:bg-submit-900 bg-white hover:opacity-75 transition-opacity"
          >
            &rarr;
          </button>
        </div>
      </div>
      <div
        ref="testimonialContainer"
        class="overflow-x-auto snap-x snap-mandatory scrollbar-hide"
        style="scroll-behavior: smooth;"
      >
        <div class="flex w-full">
          <div
            v-for="(testimonial, index) in testimonials"
            :key="testimonial.author"
            class="w-full flex-shrink-0 snap-start"
            :class="{
              'pl-4': index > 0,
              'pr-4': index < testimonials.length - 1
            }"
          >
            <div>
              <p class="mb-4 text-lg">{{ testimonial.text }}</p>
              <p class="text-gray-500">- {{ testimonial.author }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="w-full dark:bg-submit-925 bg-white py-20">
      <div class="container mx-auto max-w-[1080px] px-2">
        <h2 class="font-display text-3xl lg:text-5xl font-bold dark:text-gold-500 text-gold-700 text-center">The latest from our Blog</h2>
        <ul class="my-20 grid lg:grid-cols-3 gap-x-10 gap-y-10 px-2">
          <li class="space-y-6">
            <header>
              <h3 class="text-2xl font-display font-bold dark:text-white">Help Us Hit Our Goal!</h3>
              <time class="text-sm text-gray-500">November 19, 2024</time>
            </header>
            <p class="text-sm">Submit'ss End-of-Year Backer Sale makes it easier than ever to support the platform. Lifetime Backing is now $150, with boosted durations for multi-month plans. Your support keeps Submit ad-free and focused on privacy and inclusivity. Join by December 31st or when the goal is met to help shape the future!</p>
            <a class="text-sm inline-block dark:text-gold-500 text-gold-700" href="https://blog.submit.gg/backer-sale-help-us-hit-our-goal">Read the Post &rarr;</a>
          </li>
          <li class="space-y-6">
            <header>
              <h3 class="text-2xl font-display font-bold dark:text-white">Our Latest Developments</h3>
              <time class="text-sm text-gray-500">November 17, 2024</time>
            </header>
            <p class="text-sm">Submit has grown significantly in 2024 with features like Circles, Relationships, Events, and enhanced reactions driving engagement. Active users are steadily increasing, and efforts to re-engage inactive accounts will begin soon. Privacy and safety remain core priorities, with more tools and updates on the way.</p>
            <a class="text-sm inline-block dark:text-gold-500 text-gold-700" href="https://blog.submit.gg/our-latest-developments">Read the Post &rarr;</a>
          </li>
          <li class="space-y-6">
            <header>
              <h3 class="text-2xl font-display font-bold dark:text-white">A Big 2024 Update</h3>
              <time class="text-sm text-gray-500">June 25, 2024</time>
            </header>
            <p class="text-sm">2024 has been transformative for Submit, with major updates like a frontend rebuild, Groups, Explore, and steady user growth. Development remains the focus as we prepare to roll out features like Events and Organizations. We’re grateful for our community’s support as we continue to grow and improve.</p>
            <a class="text-sm inline-block dark:text-gold-500 text-gold-700" href="https://blog.submit.gg/a-big-2024-update">Read the Post &rarr;</a>
          </li>
        </ul>
      </div>
    </section>
    <section class="container mx-auto max-w-[1080px] py-20 grid gap-y-20 px-2">
      <h2 class="font-display text-3xl lg:text-5xl font-bold dark:text-gold-500 text-gold-700 text-center">Learn More</h2>
      <ul class="grid xs:grid-cols-2 md:grid-cols-4 gap-6 md:gap-8 lg:gap-10 px-2">
        <router-link to="/about">
          <li class="dark:bg-submit-900 bg-white py-10 rounded-lg text-center">
            About Submit
          </li>
        </router-link>
        <router-link to="/transparency">
          <li class="dark:bg-submit-900 bg-white py-10 rounded-lg text-center">
            Transparency
          </li>
        </router-link>
        <a href="https://docs.submit.gg">
          <li class="dark:bg-submit-900 bg-white py-10 rounded-lg text-center">
            Guidelines
          </li>
        </a>
        <router-link to="/backer">
          <li class="dark:bg-submit-900 bg-white py-10 rounded-lg text-center">
            Become a Backer
          </li>
        </router-link>
        <a href="https://blog.submit.gg">
          <li class="dark:bg-submit-900 bg-white py-10 rounded-lg text-center">
            Blog
          </li>
        </a>
        <a href="https://careers.submit.gg">
          <li class="dark:bg-submit-900 bg-white py-10 rounded-lg text-center">
            Careers
          </li>
        </a>
        <router-link to="/contact">
          <li class="dark:bg-submit-900 bg-white py-10 rounded-lg text-center">
            Contact
          </li>
        </router-link>
      </ul>
    </section>
    <section class="container mx-auto max-w-[1080px] space-y-6 text-center py-20 px-2">
      <h2 class="text-gold-700 dark:text-gold-500 font-display text-3xl lg:text-5xl font-bold">
        Submit is opening to the public now!
      </h2>
      <p class="max-w-xl mx-auto">Still on the waitlist? Invites are sent out daily, it shouldn't be too long!</p>
      <p class="font-display"><strong>Get Started Today:</strong> Reserve your username and join the waitlist!</p>
      <div class="group inline-flex rounded-md bg-gradient-to-r from-[#8B6CDF] to-[#E176A3] p-0.5">
        <router-link class="font-semibold rounded-md bg-neutral-125 dark:bg-submit-950 group-hover:bg-transparent text-xs px-10 py-3" to="/signup">
          <span class="text-transparent bg-clip-text bg-gradient-to-r from-[#8B6CDF] to-[#E176A3] group-hover:text-white">Join the Waitlist</span>
        </router-link>
      </div>
    </section>
  </main>
</template>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.slide-enter-from {
  opacity: 0;
  transform: translateX(100%); /* Only move horizontally */
}

.slide-leave-to {
  opacity: 0;
  transform: translateX(-100%); /* Only move horizontally */
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateX(0);
}

/* Ensure transitions happen in the same space */
.slide-move {
  transition: transform 0.5s ease;
}
</style>
