<script setup lang="ts">
import NotSeeking from '@/data/NotSeeking'
import Seeking from '@/data/Seeking'
import { ref, onMounted } from 'vue'
import API from '@/api/api'

interface Props {
  profileData: any
}

const { profileData } = defineProps<Props>()

const trophyLoading = ref(true)
const trophyCase = ref<any[]>([])

onMounted(async () => {
  await fetchTrophyCase()
})

async function fetchTrophyCase() {
  try {
    const res = await API().get(`/users/${profileData._id}/trophycase`)
    trophyCase.value = res.data.data
  } catch (err) {
    console.error(err)
  } finally {
    trophyLoading.value = false
  }
}

function getSeeking(seeking: string) {
  return Seeking.find((seek) => seek.value === seeking)?.label
}

function getNotSeeking(notSeeking: string) {
  return NotSeeking.find((notSeek) => notSeek.value === notSeeking)?.label
}
</script>

<template>
  <div class="flex flex-col row-start-1 row-end-1 mb-6 md:mb-0 md:row-start-auto md:row-end-auto md:col-span-3 lg:col-span-4 gap-y-3">
    <details class="flex flex-col md:hidden group">
      <summary class="flex flex-row">
        <svg class="w-4 mr-2 dark:fill-white group-open:rotate-90 transition-all" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM294.6 135.1l99.9 107.1c3.5 3.8 5.5 8.7 5.5 13.8s-2 10.1-5.5 13.8L294.6 376.9c-4.2 4.5-10.1 7.1-16.3 7.1C266 384 256 374 256 361.7l0-57.7-96 0c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32l96 0 0-57.7c0-12.3 10-22.3 22.3-22.3c6.2 0 12.1 2.6 16.3 7.1z"/></svg>
        <span class="text-sm font-semibold dark:text-white">Details</span>
      </summary>
      <div class="grid md:hidden grid-cols-2 gap-2 text-sm mt-4 dark:text-gray-400 text-gray-500">
        <div v-if="profileData.meta.roles" class="font-semibold">
          Roles:
        </div>
        <ul v-if="profileData.meta.roles" class="space-y-1">
          <li v-for="role in profileData.meta.roles">{{role}}</li>
        </ul>
        <div v-if="profileData.meta.gender" class="font-semibold">
          Gender:
        </div>
        <ul v-if="profileData.meta.gender" class="space-y-1">
          <li v-for="gender in profileData.meta.gender">{{gender}}</li>
        </ul>
        <div v-if="( (profileData.meta.sexual && profileData.meta.sexual.length > 0) || (profileData.meta.romantic && profileData.meta.romantic.length > 0) )" class="font-semibold">
          Orientations:
        </div>
        <ul v-if="( (profileData.meta.sexual && profileData.meta.sexual.length > 0) || (profileData.meta.romantic && profileData.meta.romantic.length > 0) )" class="space-y-1">
          <li v-if="(profileData.meta.sexual && profileData.meta.sexual.length > 0)" v-for="sexual in profileData.meta.sexual">{{sexual}}</li>
          <li v-if="(profileData.meta.romantic && profileData.meta.romantic.length > 0)" v-for="romantic in profileData.meta.romantic">{{romantic}}</li>
        </ul>
        <div v-if="(profileData.meta.seeking && profileData.meta.seeking.length > 0)" class="font-semibold">
          Seeking:
        </div>
        <ul v-if="(profileData.meta.seeking && profileData.meta.seeking.length > 0)" class="capitalize space-y-1">
          <li v-for="seek in profileData.meta.seeking">{{getSeeking(seek)}}</li>
        </ul>
        <div v-if="(profileData.meta.notSeeking && profileData.meta.notSeeking.length > 0)" class="font-semibold">
          Not Seeking:
        </div>
        <ul v-if="(profileData.meta.notSeeking && profileData.meta.notSeeking.length > 0)" class="capitalize space-y-1">
          <li v-for="seek in profileData.meta.notSeeking">{{getNotSeeking(seek)}}</li>
        </ul>
        <div class="font-semibold">
          Joined:
        </div>
        <div>
          {{ profileData.createdAt }}
        </div>
      </div>
    </details>
    <details class="flex flex-col md:hidden group" v-if="profileData.relationships && profileData.relationships.length > 0">
      <summary class="flex flex-row">
        <svg class="w-4 mr-2 dark:fill-white group-open:rotate-90 transition-all" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM294.6 135.1l99.9 107.1c3.5 3.8 5.5 8.7 5.5 13.8s-2 10.1-5.5 13.8L294.6 376.9c-4.2 4.5-10.1 7.1-16.3 7.1C266 384 256 374 256 361.7l0-57.7-96 0c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32l96 0 0-57.7c0-12.3 10-22.3 22.3-22.3c6.2 0 12.1 2.6 16.3 7.1z"/></svg>
        <span class="text-sm font-semibold dark:text-white">Relationships</span>
      </summary>
      <div class="grid md:hidden grid-cols-2 gap-2 text-sm mt-4 dark:text-gray-400 text-gray-500" v-for="relationship in profileData.relationships">
        <template v-if="relationship.mono && relationship.topUser.user === profileData._id && relationship.bottomUsers && relationship.bottomUsers.length === 1">
          {{ relationship.topDisplayString }}: <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${relationship.bottomUsers[0].username}`">{{ relationship.bottomUsers[0].username }}</router-link>
        </template>
        <template v-if="relationship.mono && relationship.topUser.user === profileData._id && relationship.bottomUsers && relationship.bottomUsers.length > 1">
          <div>{{ relationship.topDisplayString }}:</div>
          <ul>
            <li v-for="user in relationship.bottomUsers">
              <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${user.username}`">{{ user.username }}</router-link>
            </li>
          </ul>
        </template>
        <template v-if="relationship.mono && relationship.topUser.user === profileData._id && relationship.bottomUserNotOnSubmit">
          <div class="col-span-full">{{ relationship.topDisplayString }} someone not on Submit</div>
        </template>
        <template v-if="relationship.mono && relationship.bottomUsers && relationship.bottomUsers.length === 1 && relationship.bottomUsers.some((user: any) => user.user === profileData._id)">
          {{ relationship.bottomDisplayString }}: <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${relationship.topUser.username}`">{{ relationship.topUser.username }}</router-link>
        </template>
        <template v-if="relationship.solo">
          {{ relationship.topDisplayString }}
        </template>
      </div>
    </details>
    <router-link :to="`/${profileData.username}/achievements`" class="flex text-sm md:hidden dark:text-white font-semibold">
      <svg class="w-4 mr-2 dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M400 0L176 0c-26.5 0-48.1 21.8-47.1 48.2c.2 5.3 .4 10.6 .7 15.8L24 64C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7c44.3 43.1 98.3 64.8 138.1 75.8c23.4 6.5 39.4 26 39.4 45.6c0 20.9-17 37.9-37.9 37.9L192 448c-17.7 0-32 14.3-32 32s14.3 32 32 32l192 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-26.1 0C337 448 320 431 320 410.1c0-19.6 15.9-39.2 39.4-45.6c39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24L446.4 64c.3-5.2 .5-10.4 .7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112l84.4 0c9.1 90.1 29.2 150.3 51.9 190.6c-24.9-11-50.8-26.5-73.2-48.3c-32-31.1-58-76-63-142.3zM464.1 254.3c-22.4 21.8-48.3 37.3-73.2 48.3c22.7-40.3 42.8-100.5 51.9-190.6l84.4 0c-5.1 66.3-31.1 111.2-63 142.3z"/></svg>
      Achievements
    </router-link>
    <h3 class="hidden md:block text-sm font-bold dark:text-white">Details</h3>
    <div class="hidden md:grid lg:grid-cols-3 lg:gap-2 text-sm dark:text-indigo-325 mb-6">
      <div v-if="profileData.meta.roles" class="font-semibold">
        Roles:
      </div>
      <ul v-if="profileData.meta.roles" class="mb-2 lg:mb-0 col-span-2 lg:space-y-1">
        <li v-for="role in profileData.meta.roles">{{role}}</li>
      </ul>
      <div v-if="profileData.meta.gender" class="font-semibold">
        Gender:
      </div>
      <ul v-if="profileData.meta.gender" class="mb-2 lg:mb-0 col-span-2 lg:space-y-1">
        <li v-for="gender in profileData.meta.gender">{{gender}}</li>
      </ul>
      <div v-if="( (profileData.meta.sexual && profileData.meta.sexual.length > 0) || (profileData.meta.romantic && profileData.meta.romantic.length > 0) )" class="font-semibold">
        Orientations:
      </div>
      <ul v-if="( (profileData.meta.sexual && profileData.meta.sexual.length > 0) || (profileData.meta.romantic && profileData.meta.romantic.length > 0) )" class="mb-2 lg:mb-0 col-span-2 lg:space-y-1">
        <li v-if="profileData.meta.sexual && profileData.meta.sexual.length > 0" v-for="sexual in profileData.meta.sexual">{{sexual}}</li>
        <li v-if="profileData.meta.romantic && profileData.meta.romantic.length > 0" v-for="romantic in profileData.meta.romantic">{{romantic}}</li>
      </ul>
      <div v-if="(profileData.meta.seeking && profileData.meta.seeking.length > 0)" class="font-semibold">
        Seeking:
      </div>
      <ul v-if="(profileData.meta.seeking && profileData.meta.seeking.length > 0)" class="mb-2 lg:mb-0 col-span-2 capitalize lg:space-y-1">
        <li v-for="seek in profileData.meta.seeking">{{getSeeking(seek)}}</li>
      </ul>
      <div v-if="(profileData.meta.notSeeking && profileData.meta.notSeeking.length > 0)" class="font-semibold">
        Not Seeking:
      </div>
      <ul v-if="(profileData.meta.notSeeking && profileData.meta.notSeeking.length > 0)" class="mb-2 lg:mb-0 col-span-2 capitalize lg:space-y-1">
        <li v-for="seek in profileData.meta.notSeeking">{{getNotSeeking(seek)}}</li>
      </ul>
      <div class="font-semibold">
        Joined:
      </div>
      <div class="mb-2 lg:mb-0 col-span-2">
        {{ profileData.createdAt }}
      </div>
    </div>
    <h3 class="hidden lg:block text-sm font-bold dark:text-white" v-if="profileData.relationships && profileData.relationships.length > 0">Relationships</h3>
    <div class="hidden md:grid lg:grid-cols-1 lg:gap-2 text-sm dark:text-indigo-325 mb-6" v-if="profileData.relationships && profileData.relationships.length > 0">
      <div class="grid lg:grid-cols-3 lg:gap-2" v-for="relationship in profileData.relationships">
        <template v-if="relationship.mono && relationship.topUser.user === profileData._id && relationship.bottomUsers && relationship.bottomUsers.length === 1">
          {{ relationship.topDisplayString }}: <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${relationship.bottomUsers[0].username}`">{{ relationship.bottomUsers[0].username }}</router-link>
        </template>
        <template v-if="relationship.mono && relationship.topUser.user === profileData._id && relationship.bottomUsers && relationship.bottomUsers.length > 1">
          <div>{{ relationship.topDisplayString }}:</div>
          <ul>
            <li v-for="user in relationship.bottomUsers">
              <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${user.username}`">{{ user.username }}</router-link>
            </li>
          </ul>
        </template>
        <template v-if="relationship.mono && relationship.topUser.user === profileData._id && relationship.bottomUserNotOnSubmit">
          <div class="col-span-full">{{ relationship.topDisplayString }} someone not on Submit</div>
        </template>
        <template v-if="relationship.mono && relationship.bottomUsers && relationship.bottomUsers.length === 1 && relationship.bottomUsers.some((user: any) => user.user === profileData._id)">
          {{ relationship.bottomDisplayString }}: <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${relationship.topUser.username}`">{{ relationship.topUser.username }}</router-link>
        </template>
        <template v-if="relationship.solo">
          {{ relationship.topDisplayString }}
        </template>
      </div>
    </div>
    <h3 class="hidden lg:block text-sm font-bold dark:text-white">Trophy Case</h3>
    <ul class="hidden lg:grid gap-2">
      <li class="flex items-center gap-2" v-if="!trophyLoading && trophyCase.length > 0" v-for="(trophy) in trophyCase">
        <img :src="trophy.badgeUrl" class="h-16" v-if="trophy.badgeUrl" />
        <img src="https://assets.submit.gg/achievements/NoBadge.png" class="h-16" v-else />
        <div class="text-sm">
          <h4 class="text-sm font-bold">{{ trophy.name }}</h4>
          <p class="dark:text-gray-500">{{ trophy.description }}</p>
        </div>
      </li>
      <li v-if="!trophyLoading && trophyCase.length === 0" class="text-sm dark:text-gray-500">No trophies configured.</li>
      <li v-if="trophyLoading" class="text-sm dark:text-gray-500">Loading...</li>
      <li class="dark:text-gray-500 text-sm mt-2">
        <router-link :to="`/${profileData.username}/achievements`" class="underline decoration-dotted hover:decoration-solid">All Achievements</router-link>
      </li>
    </ul>
  </div>
</template>
