export default [
  {
    path: '/groups',
    name: 'Groups',
    component: () => import('@/views/Groups/Index.vue'),
    meta: {
      title: "Groups - Submit"
    },
    children: [
      {
        path: "",
        name: "Groups.All",
        component: () => import('@/views/Groups/All.vue')
      },
      {
        path: "yours",
        name: "Groups.Yours",
        component: () => import('@/views/Groups/Yours.vue'),
        meta: {
          title: "Your Groups - Submit"
        }
      },
      {
        path: "create",
        name: "Groups.Create",
        component: () => import('@/views/Groups/Create.vue'),
        meta: {
          title: "Create Group - Submit"
        }
      },
      {
        path: "reservations",
        name: "Groups.Reservations",
        component: () => import('@/views/Groups/Reservations.vue'),
        meta: {
          title: "Reservations - Submit",
          admin: true
        }
      }
    ]
  },
  {
    path: '/groups/:slug',
    name: 'Group',
    component: () => import('@/views/Group/Index.vue'),
    children: [
      {
        path: "",
        name: "Group.Posts",
        component: () => import('@/views/Group/Posts.vue'),
        props: (route: any) => ({ board: undefined })
      },
      {
        path: "b/:board",
        name: "Group.Board",
        component: () => import('@/views/Group/Posts.vue'),
        props: (route: any) => ({ board: route.params.board })
      },
      {
        path: "about",
        name: "Group.About",
        component: () => import('@/views/Group/About.vue')
      },
      {
        path: "personals",
        name: "Group.Personals",
        component: () => import('@/views/Group/Personals.vue')
      },
      {
        path: "members",
        name: "Group.Members",
        component: () => import('@/views/Group/Members.vue')
      },
      {
        path: "create",
        name: "Group.Create",
        component: () => import('@/views/Group/Create.vue')
      },
      {
        path: "moderation/activity",
        name: "Group.Moderation.Activity",
        component: () => import('@/views/Group/Moderation/Activity.vue')
      },
      {
        path: "moderation/reports",
        name: "Group.Moderation.Reports",
        component: () => import('@/views/Group/Moderation/Reports.vue')
      },
      {
        path: "moderation/reports/:id",
        name: "Group.Moderation.Report",
        component: () => import('@/views/Group/Moderation/Report.vue')
      },
      {
        path: "moderation/messages",
        name: "Group.Moderation.Messages",
        component: () => import('@/views/Group/Moderation/Messages.vue')
      },
      {
        path: "moderation/messages/archives",
        name: "Group.Moderation.Messages.Archives",
        component: () => import('@/views/Group/Moderation/Archives.vue')
      },
      {
        path: "moderation/messages/sent",
        name: "Group.Moderation.Messages.Sent",
        component: () => import('@/views/Group/Moderation/Sent.vue')
      },
      {
        path: "moderation/messages/:id",
        name: "Group.Moderation.Message",
        component: () => import('@/views/Group/Moderation/Message.vue')
      },
      {
        path: "moderation/members",
        name: "Group.Moderation.Members",
        component: () => import('@/views/Group/Moderation/Members.vue')
      },
      {
        path: "moderation/members/:id",
        name: "Group.Moderation.Member",
        component: () => import('@/views/Group/Moderation/Member.vue')
      },
      {
        path: "moderation/memberqueue",
        name: "Group.Moderation.MemberQueue",
        component: () => import('@/views/Group/Moderation/MemberQueue.vue')
      },
      {
        path: "moderation/postqueue",
        name: "Group.Moderation.PostQueue",
        component: () => import('@/views/Group/Moderation/PostQueue.vue')
      },
      {
        path: "settings/group",
        name: "Group.Settings.Group",
        component: () => import('@/views/Group/Settings/Group.vue')
      },
      {
        path: "settings/moderation",
        name: "Group.Settings.Moderation",
        component: () => import('@/views/Group/Settings/Moderation.vue')
      },
      {
        path: "settings/notifications",
        name: "Group.Settings.Notifications",
        component: () => import('@/views/Group/Settings/Notifications.vue')
      },
      {
        path: "settings/profile",
        name: "Group.Settings.Profile",
        component: () => import('@/views/Group/Settings/Profile.vue')
      },
      {
        path: ":short",
        name: "Group.Post",
        component: () => import('@/views/Group/Post.vue')
      },
      {
        path: ":short/edit",
        name: "Group.Post.Edit",
        component: () => import('@/views/Group/EditPost.vue')
      }
    ]
  }
]
