<script setup lang="ts">
import { ref, computed, onBeforeMount, onMounted, onBeforeUnmount, inject } from "vue"
import StatusEditor from "@/components/status/StatusEditor.vue"
import Reactions from "@/components/reactions/Reactions.vue"
import ReactV2 from "@/components/reactions/ReactV2.vue"
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import { format, parseISO } from "date-fns"
import D from "@/composables/TimeDisplay"
import { useRouter } from "vue-router"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue"

interface Props {
  item: any,
  imageErrorHandler?: (item: string) => void
}

const props = defineProps<Props>()
const emit = defineEmits(["delete", "update", "hide", "mute"])

const Platform = usePlatformStore()
const User = useUserStore()

const feedContext = inject('feedContext')

const Router = useRouter()

const { roles, id } = storeToRefs(User)

const working = ref(false)

const contentText = ref<string>(props.item.content ? props.item.content.text : "")
const origText = ref<String>(props.item.content ? props.item.content.text : "")
const origCW = ref<String>(props.item.content ? (props.item.content.tw ? props.item.content.tw : "") : "")
const cwEnabled = ref<Boolean>(props.item.content ? (props.item.content.tw ? true : false) : false)
const cwVisible = ref<Boolean>(props.item.content ? (props.item.content.tw ? true : false) : false)
const cwText = ref<String>(props.item.content ? props.item.content.tw : "" || "")

const processedContent = computed(() => {
  if (!contentText.value) return ""

  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = contentText.value

  // Find all mention spans
  const mentions = tempDiv.querySelectorAll('span[data-type="mention"]')

  // Replace each mention span with a special anchor tag
  mentions.forEach(mention => {
    const username = mention.getAttribute('data-label')
    if (username) {
      const anchor = document.createElement('a')
      // Set actual URL in href
      anchor.setAttribute('href', `/${username}`)
      anchor.setAttribute('class', 'mention')
      // Add click prevention
      anchor.setAttribute('onclick', 'event.preventDefault()')
      anchor.setAttribute('data-username', username)
      anchor.textContent = `@${username}`
      mention.parentNode?.replaceChild(anchor, mention)
    }
  })

  return tempDiv.innerHTML
})

// Add click handler function
function handleMentionClick(event: MouseEvent) {
  const target = event.target as HTMLElement
  if (target.classList.contains('mention')) {
    const username = target.getAttribute('data-username')
    if (username) {
      Router.push(`/${username}`)
    }
  }
}

const edit = ref<Boolean>(false)
const edited = ref<Boolean>(props.item.content && props.item.content.edited ? true : false)
const isAdmin = computed(() => {
  return roles.value.includes("admin")
})

const originalArray = ref<any[]>([])
const mediaArray = ref<any[]>([])
const actionsArray = ref<any[]>([])
const actionsOriginalArray = ref<any[]>([])

const reacted = ref(props.item.activityType === 'media' ? props.item.content[0].hasReacted : (props.item.content ? props.item.content.hasReacted : null))
const reactionsCount = ref(props.item.activityType === 'media' ? props.item.content[0].reactionsCount : (props.item.content ? props.item.content.reactionsCount : null))
const reactionData = ref(props.item.activityType === 'media' ? props.item.content[0].reactionData || undefined : (props.item.content ? props.item.content.reactionData : undefined) || undefined)

const confirmMuteModal = ref(false)

function toggleMuteModal() {
  confirmMuteModal.value = !confirmMuteModal.value
}

function getReactionCount(reactions: any) {
  let count = 0
  for (const key in reactions) {
    count += reactions[key]
  }
  return count
}

const content = computed(() => {
  return {
    contentAuthor: props.item.author._id,
    contentId: props.item.activityType === 'media' ? props.item.content[0]._id : props.item.content._id,
    contentType: props.item.activityType,
    contentAudience: props.item.audience
  }
})

const showCW = () => {
  return cwVisible.value && !props.item.self
}

const sameActionAuthor = computed(() => {
  // check if all the action authors are the same
  if (actionsOriginalArray.value[0].actionTakenOnType === 'announcement') return false
  if (actionsOriginalArray.value.length === 0) return false
  const firstAuthor = actionsOriginalArray.value[0].actionTakenOnAuthor._id
  for (let i = 0; i < actionsOriginalArray.value.length; i++) {
    if (actionsOriginalArray.value[i].actionTakenOnAuthor._id !== firstAuthor) return false
  }
  return true
})

const now = ref(Date.now())
const timeAgo = computed(() => {
  now.value
  return D(props.item.createdAt).locale('submit').fromNow()
})

onMounted(() => {
  const interval = setInterval(() => {
    now.value = Date.now()
  }, 60000) // Update every minute

  onBeforeUnmount(() => {
    clearInterval(interval)
  })
})

function actionLabel(action: any) {
  if (action.action === "comment") {
    return "Commented"
  }

  if (action.action === "reaction") {
    if (action.actionSubType === "love") {
      return "Loved"
    }
    return "Reacted"
  }
}

const shouldLoadMore = computed(() => {
  return mediaArray.value.length < originalArray.value.length
})

const shouldLoadMoreActions = computed(() => {
  return actionsArray.value.length < actionsOriginalArray.value.length
})

function loadMore() {
  let currentLength = mediaArray.value.length;
  let targetLength = currentLength + 8;
  for (let i = currentLength; i < targetLength; i++) {
    if (originalArray.value[i]) {
      mediaArray.value.push(originalArray.value[i])
    }
  }
}

function loadMoreActions() {
  let currentLength = actionsArray.value.length;
  let targetLength = currentLength + 8;
  for (let i = currentLength; i < targetLength; i++) {
    if (actionsOriginalArray.value[i]) {
      actionsArray.value.push(actionsOriginalArray.value[i])
    }
  }
}

const followType = computed(() => {
  let hasOrganization = false
  let hasUser = false

  for (const action of actionsOriginalArray.value) {
    if (action.actionTakenOnType === 'organization') {
      hasOrganization = true
    } else if (action.actionTakenOnType === 'user') {
      hasUser = true
    }

    // If both types are found, return 'mixed' immediately
    if (hasOrganization && hasUser) {
      return 'mixed'
    }
  }

  if (hasOrganization) return 'organization'
  if (hasUser) return 'user'
  return 'none'
})

const actionString = computed(() => {
  let typeSingle = ""
  let typeMultiple = ""
  let contentType = ""
  let string = ""
  switch (props.item.activitySubType) {
    case "use_starterpack":
      typeSingle = "used a"
      typeMultiple = "used"
      break
    case "reaction":
      typeSingle = props.item.contentType === 'event' || props.item.contentType === 'announcement' ? "reacted to an" : "reacted to a"
      typeMultiple = "reacted to"
      break
    case "comment":
      typeSingle = props.item.contentType === 'event' || props.item.contentType === 'announcement' ? "commented on an" : "commented on a"
      typeMultiple = "commented on"
      break
    case "follow":
      if (followType.value === 'user') {
        typeSingle = "followed a"
        typeMultiple = "followed"
        break
      }
      if (followType.value === 'organization') {
        typeSingle = "followed an"
        typeMultiple = "followed"
        break
      }
      typeSingle = "followed a"
      typeMultiple = "followed"
      break
    case "rsvp":
      typeSingle = "RSVP'd to an"
      typeMultiple = "RSVP'd to"
      break
    case "considering":
      typeSingle = "is considering an"
      typeMultiple = "is considering"
      break
  }

  if (props.item.activitySubType === 'follow') {
    if (followType.value === 'user') {
      actionsOriginalArray.value.length === 1 ?
      string = ` ${ typeSingle } human` :
      string = ` ${ typeMultiple } ${ actionsOriginalArray.value.length } humans`
    }
    if (followType.value === 'organization') {
      actionsOriginalArray.value.length === 1 ?
      string = ` ${ typeSingle } organization` :
      string = ` ${ typeMultiple } ${ actionsOriginalArray.value.length } organizations`
    }
    if (followType.value === 'mixed') {
      actionsOriginalArray.value.length === 1 ?
      string = ` ${ typeSingle } human or organization` :
      string = ` ${ typeMultiple } ${ actionsOriginalArray.value.length } humans and organizations`
    }
  } else {
    switch (props.item.contentType) {
      case "writing":
        contentType = "writing"
        break
      case "media":
        contentType = "photo"
        break
      case "status":
        contentType = "status update"
        break
      case "post":
        contentType = "group post"
        break
      case "announcement":
        contentType = "announcement"
        break
      case "event":
        contentType = "event"
        break
      case "starterpack":
        contentType = "starter pack"
        break
    }

    actionsOriginalArray.value.length === 1 ?
      string = ` ${ typeSingle } ${ contentType }` :
      string = ` ${ typeMultiple } ${ actionsOriginalArray.value.length } ${ contentType }s`
  }

  return string
})

function audienceLabel(audience: string) {
  switch (audience) {
    case "public":
      return "Public"
    case "followers":
      return "Followers"
    case "relationships":
      return "Relationships"
    default:
      return "Circle"
  }
}

onBeforeMount(() => {
  if (props.item.activityType === "media") {
    originalArray.value = props.item.content.reverse()
    for (let i = 0; i < 8; i++) {
      if (originalArray.value[i]) {
        mediaArray.value.push(originalArray.value[i])
      }
    }
  }
  if (props.item.activityType === "action") {
    actionsOriginalArray.value = props.item.actions
    let limit = 3
    if (props.item.contentType === 'media') limit = 15
    if (actionsOriginalArray.value.length > limit) {
      for (let i = 0; i < limit - 1; i++) {
        if (actionsOriginalArray.value[i]) {
          actionsArray.value.push(actionsOriginalArray.value[i])
        }
      }
    } else {
      actionsArray.value = actionsOriginalArray.value
    }
  }
})

function closeEdit(newData: any = undefined) {
  if (newData) {
    // Update content text with new content
    if (newData.text) {
      contentText.value = newData.text
      origText.value = newData.text // Keep original text in sync
    }

    // Update content warning if present
    if (newData.tw) {
      cwText.value = newData.tw
      cwEnabled.value = true
      cwVisible.value = true
    } else {
      cwText.value = ""
      cwEnabled.value = false
      cwVisible.value = false
    }
  }
  edit.value = false
}

function openReportDialog() {
  let contentId = props.item.content._id
  let contentType = props.item.activityType
  if (props.item.activityType === "media" && mediaArray.value.length === 1) contentId = mediaArray.value[0]._id
  if (props.item.activityType === "media" && mediaArray.value.length > 1) {
    contentId = props.item.activity,
    contentType = "activity"
  }
  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: props.item.author._id,
    contentId: contentId,
    contentType: contentType
  })
}

async function hideItem(itemId: string) {
  if (feedContext !== 'everyone') return
  try {
    await API().post(`/feed/everyone/hide`, {
      item: itemId
    })
    emit("hide", itemId)
  } catch (err) {
    console.error(err)
  }
}

async function muteUser(userId: string) {
  if (feedContext !== 'everyone') return
  try {
    await API().post(`/feed/everyone/mute`, {
      mute: userId
    })
    emit("mute", userId)
  } catch (err) {
    console.error(err)
  }
}

async function confirmDelete(id: string, feedItem: string) {
  if (confirm("Are you sure you want to delete this status update?")) {
    await deleteItem(id, feedItem)
  }
}

async function deleteItem(id: string, feedItem: string) {
  try {
    await API().delete(`/status/${id}`)
    emit("delete", feedItem)
  } catch (err) {
    console.error(err)
    alert("There was an error deleting this status update.")
  }
}

function getEmojiForType(type: string) {
  return type === "like" ? "👍" : type === "dislike" ? "👎" : type === "love" ? "❤️" : type === "hot" ? "🥵" : type === "laugh" ? "😂" : type === "cry" ? "😢" : type === "angry" ? "😠" : type === "celebrate" ? "🥳" : ""
}

function adjustToUSA(location: string) {
  if (location.includes("United States")) {
    return location.replace("United States", "USA")
  }
  return location
}

function getEventCategory(category: string) {
  switch (category) {
    case "submit-event":
      return "Submit Event"
    case "educational":
      return "Educational"
    case "social":
      return "Social"
    case "bdsm":
      return "BDSM Party"
    case "kink":
      return "Kink Party"
    case "sex":
      return "Sex Party"
    case "conference":
      return "Conference or Festival"
  }
  return category
}

function getEventType(type: string) {
  switch (type) {
    case "in-person":
      return "In-Person"
    case "virtual":
    case "virtual-limited":
    case "virtual-global":
      return "Virtual"
  }
  return type
}
</script>

<template>
  <li v-if="item && (item.activityType !== 'action' || (item.activityType === 'action' && item.actions.length > 0))" :data-feed-type="item.activityType">
    <article class="FeedItem" :class="item.activityType === 'action' ? 'Action' : 'dark:bg-submit-900 bg-white'">
      <router-link
        class="PFP w-10 h-10"
        :class="item.activityType === 'action' ? 'dark:bg-submit-900 bg-white' : 'dark:bg-submit-950 bg-gray-200'"
        :to="`/${item.author.username}`"
        :style="item.author.pfp ? `background-image: url('${item.author.pfp}')` : ''"
        @error="imageErrorHandler ? imageErrorHandler(item._id) : null"
        v-if="item.activityType !== 'announcement'"
      >
        <span class="flex items-center justify-center w-full h-full text-2xl font-semibold text-gray-800 dark:text-gray-500" v-if="!item.author.pfp">{{ item.author.firstLetter }}</span>
      </router-link>
      <div v-else
        class="PFP w-10 h-10 dark:bg-submit-950 bg-gray-200 flex items-center justify-center"
      >
        <svg class="dark:fill-gold-500 fill-gold-700 h-6 w-6 origin-center" width="24" height="28" viewBox="-20 -20 158 183" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z"></path>
          <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z"></path>
          <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z"></path>
        </svg>
      </div>
      <template v-if="(item.activityType !== 'action' && item.activityType !== 'announcement') || (item.activityType === 'action' && item.version === 1)">
        <header class="inline-wrap">
          <div class="flex items-center text-[15px] sm:text-base gap-x-1.5">
            <router-link :to="`/${item.author.username}`" class="truncate font-semibold">
              {{ item.author.username }}
            </router-link>
            <span class="inline" v-if="item.author.verified">
              <svg class="h-[.80rem] fill-[#00C2FF]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
            </span>
            <span v-if="(item.author.backer)" class="inline">
              <svg
                :class="[
                  item.author.backerBadge === 'default' ? 'dark:fill-gold-500 fill-gold-700' : '',
                  item.author.backerBadge === 'green' ? 'fill-green-500' : '',
                  item.author.backerBadge === 'pink' ? 'fill-pink-450' : '',
                  'h-[.75rem]'
                ]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M160 0c17.7 0 32 14.3 32 32V191.9l0 .1v96c0 35.3 28.7 64 64 64c19.1 0 36.3-8.4 48-21.7c11.7 13.3 28.9 21.7 48 21.7c35.3 0 64-28.7 64-64V224.1l0-.1V96c0-17.7 14.3-32 32-32s32 14.3 32 32V336c0 97.2-78.8 176-176 176H284.8c-59.6 0-116.9-22.9-160-64L12.4 341c-16-15.2-16.6-40.6-1.4-56.6s40.6-16.6 56.6-1.4l60.5 57.6c0-1.5-.1-3.1-.1-4.6V32c0-17.7 14.3-32 32-32zm64 192c0-17.7 14.3-32 32-32s32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V192zm128 0c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"/>
              </svg>
            </span>
            <span class="inline" v-if="(item.author.staff || item.author.volunteer)">
              <svg class="fill-gold-500" height="14" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
                <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
                <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
              </svg>
            </span>
          </div>
          <div class="flex relative items-center gap-x-4 dark:text-gray-400">
            <time class="text-sm" :title="D(item.createdAt).format('LLLL')">{{ timeAgo }}</time>
            <Menu as="template" v-if="item.activityType !== 'action'">
              <MenuButton>
                <svg class="flex-none h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                  <title>Menu</title>
                  <path d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"/>
                </svg>
              </MenuButton>
              <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <MenuItems class="origin-top-right divide-y divide-indigo-350 absolute top-6 -right-1 w-36 rounded-md shadow-lg bg-neutral-125 dark:bg-submit-950 ring-1 ring-transparent ring-opacity-5 focus:outline-none z-50">
                  <div class="p-1">
                    <MenuItem v-slot="{ active }" v-if="!item.self && feedContext === 'everyone'">
                      <button
                        type="button"
                        @click="hideItem(item._id)"
                        :class="[
                          active ? 'bg-yellow-400/10' : '',
                          'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                        ]"
                      >
                      <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"/></svg>
                        Hide
                      </button>
                    </MenuItem>
                    <MenuItem v-slot="{ active }" v-if="!item.self && feedContext === 'everyone'">
                      <button
                        type="button"
                        @click="toggleMuteModal"
                        :class="[
                          active ? 'bg-yellow-400/10' : '',
                          'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                        ]"
                      >
                      <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7l388.6 0c3.9 0 7.6-.7 11-2.1l-261-205.6z"/></svg>
                        Mute User
                      </button>
                    </MenuItem>
                    <MenuItem v-slot="{ active }" v-if="item.self && item.activityType === 'status' && item.content.type === 'text'">
                      <button
                      type="button"
                      @click="edit = true"
                      :class="[
                        active ? 'bg-yellow-400/10' : '',
                        'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                      ]"
                      >
                      <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
                        Edit
                      </button>
                    </MenuItem>
                    <MenuItem v-slot="{ active }" v-if="item.self && item.activityType === 'status'">
                      <button
                      type="button"
                      @click="confirmDelete(item.content._id, item._id)"
                      :class="[
                        active ? 'bg-yellow-400/10' : '',
                        'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                      ]"
                      >
                      <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
                        Delete
                      </button>
                    </MenuItem>
                    <MenuItem v-slot="{ active }" v-if="!item.self">
                      <button
                        type="button"
                        @click="openReportDialog"
                        :class="[
                          active ? 'bg-yellow-400/10' : '',
                          'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                        ]"
                      >
                      <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
                        Report
                      </button>
                    </MenuItem>
                    <MenuItem v-slot="{ active }" v-if="isAdmin && imageErrorHandler">
                      <button
                        type="button"
                        @click="imageErrorHandler(item._id)"
                        :class="[
                          active ? 'bg-yellow-400/10' : '',
                          'flex items-center rounded-md w-full px-4 py-2 text-sm text-neutral-500 dark:text-neutral-300'
                        ]"
                      >
                      <svg class="w-3 mr-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160 352 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l111.5 0c0 0 0 0 0 0l.4 0c17.7 0 32-14.3 32-32l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1L16 432c0 17.7 14.3 32 32 32s32-14.3 32-32l0-35.1 17.6 17.5c0 0 0 0 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.8c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352l34.4 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L48.4 288c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/></svg>
                        Refresh
                      </button>
                    </MenuItem>
                    <MenuItem v-slot="{ active }" v-if="(item.self && item.activityType !== 'status' && !isAdmin)">
                      <button type="button" class="text-xs px-4 py-2 w-full text-center">
                        No Actions
                      </button>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
            <TransitionRoot appear :show="confirmMuteModal" as="template">
              <Dialog as="div" @close="toggleMuteModal" class="relative z-[100]">
                <TransitionChild
                  as="template"
                  enter="duration-300 ease-out"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="duration-200 ease-in"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div class="fixed inset-0 bg-black bg-opacity-25" />
                </TransitionChild>
                <div class="fixed inset-0 overflow-y-auto">
                  <div
                    class="flex min-h-full items-center justify-center p-4 text-center"
                  >
                    <TransitionChild
                      as="template"
                      enter="duration-300 ease-out"
                      enter-from="opacity-0 scale-95"
                      enter-to="opacity-100 scale-100"
                      leave="duration-200 ease-in"
                      leave-from="opacity-100 scale-100"
                      leave-to="opacity-0 scale-95"
                    >
                      <DialogPanel
                        class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                      >
                        <DialogTitle
                          as="h3"
                          class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                        >
                          Mute {{ item.author.username }}?
                        </DialogTitle>
                        <div class="flex flex-col mt-2 space-y-4">
                          <p class="text-sm">Are you sure you want to mute {{ item.author.username }}? Once muted, all of their current and future content will be hidden from the Everyone feed for you. You can unmute them at any time.</p>
                          <div class="flex self-end">
                            <button
                              type="button"
                              @click="toggleMuteModal"
                              class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              @click="muteUser(item.author._id)"
                              class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              :disabled="working"
                            >
                              {{ working ? "Muting..." : "Mute" }}
                            </button>
                          </div>
                        </div>
                      </DialogPanel>
                    </TransitionChild>
                  </div>
                </div>
              </Dialog>
            </TransitionRoot>
          </div>
        </header>
      </template>
      <template v-if="item.activityType === 'announcement'">
        <header class="inline-wrap">
          <div class="flex items-center text-[15px] sm:text-base gap-x-1.5">
            <span class="font-semibold">Submit</span>
            <span class="inline">
              <svg class="fill-gold-500" height="14" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
                <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
                <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
              </svg>
            </span>
          </div>
          <div class="flex relative items-center gap-x-4 dark:text-gray-400">
            <time class="text-sm" :title="D(item.createdAt).format('LLLL')">{{ timeAgo }}</time>
          </div>
        </header>
      </template>
      <template v-if="item.activityType === 'action' && item.version === 2">
        <header class="V2">
          <template v-if="['reaction', 'comment', 'follow', 'rsvp', 'considering', 'use_starterpack'].some(word => item.activitySubType.includes(word))">
            <div class="text-[15px] sm:text-base">
              <router-link :to="`/${item.author.username}`" class="truncate font-semibold">
                {{ item.author.username }}
              </router-link>
              <span>{{ actionString }}</span>
              <span v-if="sameActionAuthor && item.activitySubType !== 'follow' && item.activitySubType !== 'rsvp' && item.activitySubType !== 'considering'"> from <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${item.actions[0].actionTakenOnAuthor.username}`">{{ item.actions[0].actionTakenOnAuthor.username }}</router-link></span>
              <span v-if="sameActionAuthor && (item.activitySubType === 'rsvp' || item.activitySubType === 'considering')"> organized by <router-link class="underline decoration-dotted hover:decoration-solid" :to="`/${item.actions[0].actionTakenOnAuthor.username}`">{{ item.actions[0].actionTakenOnAuthor.username }}</router-link></span>
            </div>
          </template>
          <time class="text-sm dark:text-gray-400 break-keep" :title="D(item.createdAt).format('LLLL')">{{ timeAgo }}</time>
        </header>
      </template>
      <div class="text-xs dark:text-gray-400" v-if="item.activityType !== 'action' && item.activityType !== 'post'">
        <span class="capitalize">
          <router-link v-if="(item.activityType === 'status' || item.activityType === 'writing') || (item.activityType === 'media' && mediaArray.length === 1)" :to="`/${item.author.username}/${item.activityType}/${item.activityType === 'media' ? mediaArray[0]._id : (item.activityType === 'writing' ? item.content.slug : (props.item.activityType === 'media' ? props.item.content[0]._id : props.item.content._id))}`">{{ item.activityType }}</router-link>
          <span v-else>{{ item.activityType === 'event' ? item.content.status === 'cancelled' ? 'Cancelled Event' : item.content.type !== 'in-person' ? 'Virtual Event' : 'In-Person Event' : item.activityType }}</span>
          <span v-if="!item.collection" class="dark:text-submit-899 text-[#FEFEFF] select-none pointer-events-none overflow-hidden">{{ id }}</span>
        </span>
        <span v-if="item.collection"> in <a class="underline" :href="`/${item.author.username}/${item.activityType}/collections/${item.collection.slug}`">{{ item.collection.title }}</a></span>
      </div>
      <div class="text-xs dark:text-gray-400" v-if="item.activityType === 'post'">
        <span>
          {{ item.content.type !== 'personal' ? 'Post in ' : 'Personals Post in ' }}{{ item.content.groupName }}
        </span>
      </div>
      <div class="text-xs dark:text-gray-400" v-if="item.activityType === 'action' && item.version === 1">
        <div v-if="item.activitySubType === 'comment'">
          <span>Commented on some stuff</span>
        </div>
        <div v-if="item.activitySubType === 'reaction'">
          <span>Reacted to some stuff</span>
        </div>
        <div v-if="item.activitySubType === 'follow'">
          <span v-if="item.actions.length === 1">Followed a human</span>
          <span v-else>Followed some humans</span>
        </div>
      </div>
      <ul class="ActionList" v-if="item.activityType === 'action' && item.version === 1">
        <li class="text-sm dark:text-gray-400" v-for="action in actionsArray">
          <figure v-if="action.action !== 'follow'" class="flex flex-none items-center justify-center text-sm h-8 w-8 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
            <svg class="h-3.5 dark:fill-gray-400" v-if="action.actionTakenOnType === 'writing'" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M368.4 18.3c21.9-21.9 57.3-21.9 79.2 0l46.1 46.1c21.9 21.9 21.9 57.3 0 79.2l-71 71L412.2 225 371.6 374.1c-4.8 17.8-18.1 32-35.5 38.1L72 505c-18.5 6.5-39.1 1.8-52.9-12S.5 458.5 7 440L99.8 175.9c6.1-17.4 20.3-30.6 38.1-35.5L287 99.8l10.4-10.4 71-71zM296.9 146.8L150.5 186.7c-2.5 .7-4.6 2.6-5.4 5.1L62.5 426.9 164.7 324.7c-3-6.3-4.7-13.3-4.7-20.7c0-26.5 21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48c-7.4 0-14.4-1.7-20.7-4.7L85.1 449.5l235.1-82.6c2.5-.9 4.4-2.9 5.1-5.4l39.9-146.4-68.3-68.3z"/></svg>
            <svg class="h-3.5 dark:fill-gray-400" v-if="action.actionTakenOnType === 'status'" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm340.8 59c-14.7-14.7-38.6-14.7-53.3 0L272.6 138l53.3 53.3 14.9-14.9c14.7-14.7 14.7-38.6 0-53.3zM178.1 232.5c-4.1 4.1-7 9.2-8.4 14.9L160.5 284c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l36.6-9.2c5.6-1.4 10.8-4.3 14.9-8.4l71.9-71.9L250 160.6l-71.9 71.9z"/></svg>
          </figure>
          <figure v-if="action.action === 'follow'" class="flex flex-none items-center justify-center text-sm h-8 w-8 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold" :style="action.actionTakenOnAuthor.pfp ? `background-image: url('${action.actionTakenOnAuthor.pfp}')` : ''">
            <span v-if="!action.actionTakenOnAuthor.pfp">{{ action.actionTakenOnAuthor.firstLetter }}</span>
          </figure>
          <p v-if="item.author._id !== action.actionTakenOnAuthor._id && action.action !== 'follow'"><a :href="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}${action.action === 'comment' ? '#'+action.actionRefId : ''}`">{{ actionLabel(action) }} {{ action.action === 'reaction' ? '' : 'on ' }}{{ action.actionTakenOnType !== 'media' ?  ' a ' : ''}}{{ action.actionTakenOnType }} from {{ action.actionTakenOnAuthor.username }}{{ action.count > 1 ? ` (x${action.count})` : '' }}</a></p>
          <p v-if="item.author._id === action.actionTakenOnAuthor._id && action.action !== 'follow'"><a :href="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}${action.action === 'comment' ? '#'+action.actionRefId : ''}`">{{ actionLabel(action) }} on their own {{ action.actionTakenOnType }}{{ action.count > 1 ? ` (x${action.count})` : '' }}</a></p>
          <p v-if="action.action === 'follow'"><a class="underline" :href="`/${action.actionTakenOnAuthor.username}`">{{ action.actionTakenOnAuthor.username }}</a></p>
        </li>
        <li class="text-sm dark:text-gray-400 hover:cursor-pointer" @click="loadMoreActions" v-if="shouldLoadMoreActions">
          <figure class="flex flex-none items-center justify-center text-sm h-8 w-8 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold">
            <svg class="h-3.5 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M256 80V48H192V80 224H48 16v64H48 192V432v32h64V432 288H400h32V224H400 256V80z"/></svg>
          </figure>
          <span>Load more</span>
        </li>
      </ul>
      <ul
        :class="[
          item.contentType === 'media' && item.activitySubType === 'reaction' ? 'MediaList' : '',
          item.contentType === 'media' && item.activitySubType === 'comment' ? 'MediaCommentList' : '',
          item.contentType === 'writing' ? 'WritingList' : '',
          item.contentType === 'status' ? 'StatusList' : '',
          item.contentType === 'post' ? 'PostList' : '',
          'ActionList'
        ]"
        v-if="item.activityType === 'action' && item.version === 2"
      >
        <li v-for="action in actionsArray" v-if="item.activitySubType === 'follow'">
          <template v-if="action.actionTakenOnType === 'user'">
            <router-link :to="`/${action.actionTakenOnAuthor.username}`">
              <figure class="flex flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold bg-cover capitalize rounded-full" :style="action.actionTakenOnAuthor.pfp ? `background-image: url('${action.actionTakenOnAuthor.pfp}')` : ''">
                <span v-if="!action.actionTakenOnAuthor.pfp">{{ action.actionTakenOnAuthor.firstLetter }}</span>
              </figure>
            </router-link>
            <router-link class="grid" :to="`/${action.actionTakenOnAuthor.username}`">
              <div>{{ action.actionTakenOnAuthor.username }}</div>
              <div class="text-sm dark:text-gray-400">{{ action.actionTakenOnAuthor.meta.metaLabel }}</div>
            </router-link>
          </template>
          <template v-if="action.actionTakenOnType === 'organization'">
            <router-link :to="`/${action.actionTakenOnAuthor.handle}`">
              <figure class="flex flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold bg-cover rounded-full capitalize" :style="action.actionTakenOnAuthor.pfp ? `background-image: url('${action.actionTakenOnAuthor.pfp}')` : ''">
                <span v-if="!action.actionTakenOnAuthor.pfp">{{ action.actionTakenOnAuthor.firstLetter }}</span>
              </figure>
            </router-link>
            <router-link class="grid" :to="`/${action.actionTakenOnAuthor.handle}`">
              <div>{{ action.actionTakenOnAuthor.name }}</div>
              <div class="text-sm dark:text-gray-400">{{ action.actionTakenOnAuthor.type === 'socialnetwork' ? 'Social Network' : 'Unknown Type' }}</div>
            </router-link>
          </template>
        </li>
        <li v-for="action in actionsArray" v-if="item.contentType === 'status' && item.activitySubType === 'reaction'">
          <router-link :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}`">
            <figure class="flex relative flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold bg-cover rounded-md">
              <svg class="h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M168.2 384.9c-15-5.4-31.7-3.1-44.6 6.4c-8.2 6-22.3 14.8-39.4 22.7c5.6-14.7 9.9-31.3 11.3-49.4c1-12.9-3.3-25.7-11.8-35.5C60.4 302.8 48 272 48 240c0-79.5 83.3-160 208-160s208 80.5 208 160s-83.3 160-208 160c-31.6 0-61.3-5.5-87.8-15.1zM26.3 423.8c-1.6 2.7-3.3 5.4-5.1 8.1l-.3 .5c-1.6 2.3-3.2 4.6-4.8 6.9c-3.5 4.7-7.3 9.3-11.3 13.5c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c5.1 0 10.2-.3 15.3-.8l.7-.1c4.4-.5 8.8-1.1 13.2-1.9c.8-.1 1.6-.3 2.4-.5c17.8-3.5 34.9-9.5 50.1-16.1c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9zM152 176c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z"/></svg>
              <div class="absolute -bottom-1.5 -right-1.5 text-lg">
                {{ getEmojiForType(action.actionSubType) }}
              </div>
            </figure>
          </router-link>
          <router-link class="grid" :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}`">
            <div class="relative text-sm sm:text-base whitespace-nowrap overflow-hidden overflow-ellipsis mr-4">{{ action.actionContent ? action.actionContent : 'Preview not available.' }}</div>
            <ul class="flex gap-x-4 text-xs dark:text-gray-400">
              <li v-if="!sameActionAuthor">{{ action.actionTakenOnAuthor.username }}</li>
              <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
              <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
            </ul>
          </router-link>
        </li>
        <li v-for="action in actionsArray" v-if="item.contentType === 'status' && item.activitySubType === 'comment'">
          <router-link :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}${action.actionRefId ? '#'+action.actionRefId : ''}`">
            <figure class="flex flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold bg-cover rounded-md">
              <svg class="h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M168.2 384.9c-15-5.4-31.7-3.1-44.6 6.4c-8.2 6-22.3 14.8-39.4 22.7c5.6-14.7 9.9-31.3 11.3-49.4c1-12.9-3.3-25.7-11.8-35.5C60.4 302.8 48 272 48 240c0-79.5 83.3-160 208-160s208 80.5 208 160s-83.3 160-208 160c-31.6 0-61.3-5.5-87.8-15.1zM26.3 423.8c-1.6 2.7-3.3 5.4-5.1 8.1l-.3 .5c-1.6 2.3-3.2 4.6-4.8 6.9c-3.5 4.7-7.3 9.3-11.3 13.5c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c5.1 0 10.2-.3 15.3-.8l.7-.1c4.4-.5 8.8-1.1 13.2-1.9c.8-.1 1.6-.3 2.4-.5c17.8-3.5 34.9-9.5 50.1-16.1c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9zM152 176c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z"/></svg>
            </figure>
          </router-link>
          <router-link class="grid" :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}${action.actionRefId ? '#'+action.actionRefId : ''}`">
            <div :title="action.actionContent" class="relative text-sm sm:text-base whitespace-nowrap overflow-hidden overflow-ellipsis mr-4">{{ action.actionContent }}</div>
            <ul class="flex gap-x-4 text-xs dark:text-gray-400">
              <li v-if="!sameActionAuthor">{{ action.actionTakenOnAuthor.username }}</li>
              <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
              <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
            </ul>
          </router-link>
        </li>
        <li v-for="action in actionsArray" v-if="item.contentType === 'media' && item.activitySubType === 'comment'">
          <router-link class="flex flex-none" :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}${action.actionRefId ? '#'+action.actionRefId : ''}`">
            <img class="pointer-events-none" :src="action.actionThumbnail" @error="imageErrorHandler ? imageErrorHandler(item._id) : null" />
          </router-link>
          <router-link class="grid" :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}${action.actionRefId ? '#'+action.actionRefId : ''}`">
            <div :title="action.actionContent" class="relative text-sm sm:text-base whitespace-nowrap overflow-hidden overflow-ellipsis mr-4">{{ action.actionContent }}</div>
            <ul class="flex gap-x-4 text-xs dark:text-gray-400">
              <li v-if="!sameActionAuthor">{{ action.actionTakenOnAuthor.username }}</li>
              <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
              <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
            </ul>
          </router-link>
        </li>
        <li v-for="action in actionsArray" v-if="item.contentType === 'media' && item.activitySubType === 'reaction'">
          <router-link class="flex flex-none relative group" :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnId}`">
            <img class="pointer-events-none flex-none" :src="action.actionThumbnail" />
            <div class="hidden group-hover:block absolute -bottom-1.5 -right-1.5 text-lg">
              {{ getEmojiForType(action.actionSubType) }}
            </div>
          </router-link>
        </li>
        <li v-for="action in actionsArray" v-if="item.contentType === 'writing' && item.activitySubType === 'reaction'">
          <router-link :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnContent.slug}`">
            <figure class="flex relative flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
              <svg class="h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M368.4 18.3c21.9-21.9 57.3-21.9 79.2 0l46.1 46.1c21.9 21.9 21.9 57.3 0 79.2l-71 71L412.2 225 371.6 374.1c-4.8 17.8-18.1 32-35.5 38.1L72 505c-18.5 6.5-39.1 1.8-52.9-12S.5 458.5 7 440L99.8 175.9c6.1-17.4 20.3-30.6 38.1-35.5L287 99.8l10.4-10.4 71-71zM296.9 146.8L150.5 186.7c-2.5 .7-4.6 2.6-5.4 5.1L62.5 426.9 164.7 324.7c-3-6.3-4.7-13.3-4.7-20.7c0-26.5 21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48c-7.4 0-14.4-1.7-20.7-4.7L85.1 449.5l235.1-82.6c2.5-.9 4.4-2.9 5.1-5.4l39.9-146.4-68.3-68.3z"/></svg>
              <div class="absolute -bottom-1.5 -right-1.5 text-base">
                {{ getEmojiForType(action.actionSubType) }}
              </div>
            </figure>
          </router-link>
          <router-link class="break-words text-sm sm:text-base" :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnContent.slug}`">
            {{ action.actionTakenOnContent.title }}
            <ul class="flex gap-x-4 text-xs dark:text-gray-400">
              <li v-if="!sameActionAuthor">{{ action.actionTakenOnAuthor.username }}</li>
              <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
              <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
            </ul>
          </router-link>
        </li>
        <li v-for="action in actionsArray" v-if="item.contentType === 'writing' && item.activitySubType === 'comment'">
          <router-link :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnContent.slug}${action.actionRefId ? '#'+action.actionRefId : ''}`">
            <figure class="flex flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
              <svg class="h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M368.4 18.3c21.9-21.9 57.3-21.9 79.2 0l46.1 46.1c21.9 21.9 21.9 57.3 0 79.2l-71 71L412.2 225 371.6 374.1c-4.8 17.8-18.1 32-35.5 38.1L72 505c-18.5 6.5-39.1 1.8-52.9-12S.5 458.5 7 440L99.8 175.9c6.1-17.4 20.3-30.6 38.1-35.5L287 99.8l10.4-10.4 71-71zM296.9 146.8L150.5 186.7c-2.5 .7-4.6 2.6-5.4 5.1L62.5 426.9 164.7 324.7c-3-6.3-4.7-13.3-4.7-20.7c0-26.5 21.5-48 48-48s48 21.5 48 48s-21.5 48-48 48c-7.4 0-14.4-1.7-20.7-4.7L85.1 449.5l235.1-82.6c2.5-.9 4.4-2.9 5.1-5.4l39.9-146.4-68.3-68.3z"/></svg>
            </figure>
          </router-link>
          <router-link class="grid" :to="`/${action.actionTakenOnAuthor.username}/${action.actionTakenOnType}/${action.actionTakenOnContent.slug}${action.actionRefId ? '#'+action.actionRefId : ''}`">
            <div :title="action.actionContent" class="relative text-sm sm:text-base whitespace-nowrap overflow-hidden overflow-ellipsis mr-4">{{ action.actionContent }}</div>
            <ul class="flex gap-x-4 text-xs dark:text-gray-400">
              <li v-if="!sameActionAuthor">{{ action.actionTakenOnAuthor.username }}</li>
              <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
              <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
            </ul>
          </router-link>
        </li>
        <li v-for="action in actionsArray" v-if="item.contentType === 'post' && item.activitySubType === 'reaction'">
          <router-link :to="`/groups/${action.actionTakenOnContent.groupSlug}/${action.actionTakenOnContent.short}`">
            <figure class="flex relative flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
              <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm152 80c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z"/></svg>
              <div class="absolute -bottom-1.5 -right-1.5 text-lg">
                {{ getEmojiForType(action.actionSubType) }}
              </div>
            </figure>
          </router-link>
          <router-link class="grid break-words text-sm sm:text-base" :to="`/groups/${action.actionTakenOnContent.groupSlug}/${action.actionTakenOnContent.short}`">
            {{ action.actionTakenOnContent.title }}
            <ul class="flex gap-x-4 text-xs dark:text-gray-400">
              <li>{{ action.actionTakenOnContent.groupName }}</li>
              <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
              <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
            </ul>
          </router-link>
        </li>
        <li v-for="action in actionsArray" v-if="item.contentType === 'starterpack'">
          <router-link :to="`/starter-packs/${action.actionTakenOnContent.short}`">
            <figure class="flex relative flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold rounded-md">
              <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M51.8 160.4c-3.7 2.1-4.9 6.8-2.8 10.5l167 289.3c2.1 3.7 6.8 4.9 10.5 2.8L419.4 351.6c3.7-2.1 4.9-6.8 2.8-10.5L255.2 51.8c-2.1-3.7-6.8-4.9-10.5-2.8L51.8 160.4zM7.5 194.9c-15.4-26.6-6.3-60.7 20.4-76.1L220.7 7.5c26.6-15.4 60.7-6.3 76.1 20.4l167 289.3c15.4 26.6 6.2 60.7-20.4 76.1L250.5 504.5c-26.6 15.4-60.7 6.2-76.1-20.4L7.5 194.9zm451.9 226c41.9-24.2 56.3-77.8 32.1-119.8L354.7 64.2c1.7-.2 3.5-.2 5.3-.2l224 0c30.9 0 56 25.1 56 56l0 336c0 30.9-25.1 56-56 56l-224 0c-13.7 0-26.2-4.9-35.9-13l135.3-78.1z"/></svg>
              <div class="absolute -bottom-1.5 -right-1.5 text-lg" v-if="item.activitySubType === 'reaction'">
                {{ getEmojiForType(action.actionSubType) }}
              </div>
            </figure>
          </router-link>
          <router-link class="grid break-words text-sm sm:text-base" :to="`/starter-packs/${action.actionTakenOnContent.short}`">
            {{ action.actionTakenOnContent.name }}
            <ul class="flex gap-x-4 text-xs dark:text-gray-400">
              <li>{{ action.actionTakenOnContent.userCount }} User{{ action.actionTakenOnContent.userCount === 1 ? '' : 's' }}</li>
              <li>{{ action.actionTakenOnContent.groupCount }} Group{{ action.actionTakenOnContent.groupCount === 1 ? '' : 's' }}</li>
            </ul>
          </router-link>
        </li>
        <li v-for="action in actionsArray" v-if="item.contentType === 'event' && item.activitySubType === 'reaction'">
          <router-link :to="`/e/${action.actionTakenOnContent.short}`">
            <figure class="flex relative flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
              <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"/></svg>
              <div class="absolute -bottom-1.5 -right-1.5 text-lg">
                {{ getEmojiForType(action.actionSubType) }}
              </div>
            </figure>
          </router-link>
          <router-link class="grid break-words text-sm sm:text-base" :to="`/e/${action.actionTakenOnContent.short}`">
            {{ action.actionTakenOnContent.name }}
            <ul class="flex gap-x-4 text-xs dark:text-gray-400">
              <li>{{ getEventType(action.actionTakenOnContent.type) }}</li>
              <li :class="action.actionTakenOnContent.category === 'submit-event' ? 'text-gold-700 dark:text-gold-500' : ''">{{ getEventCategory(action.actionTakenOnContent.category) }}</li>
              <li>
                <span :class="action.actionTakenOnContent.status === 'cancelled' ? 'line-through' : ''">{{ format(parseISO(new Date(action.actionTakenOnContent.actualStartDateTime).toISOString()), "LLL d, yyyy") }}</span> <span v-if="action.actionTakenOnContent.status === 'cancelled'"> (Cancelled)</span>
              </li>
            </ul>
          </router-link>
        </li>
        <li v-for="action in actionsArray" v-if="item.contentType === 'event' && item.activitySubType === 'rsvp' || item.activitySubType === 'considering'">
          <router-link :to="`/e/${action.actionTakenOnContent.short}`">
            <figure class="flex relative flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
              <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"/></svg>
            </figure>
          </router-link>
          <router-link class="grid break-words text-sm sm:text-base" :to="`/e/${action.actionTakenOnContent.short}`">
            {{ action.actionTakenOnContent.name }}
            <ul class="flex gap-x-4 text-xs dark:text-gray-400">
              <li>{{ getEventType(action.actionTakenOnContent.type) }}</li>
              <li :class="action.actionTakenOnContent.category === 'submit-event' ? 'text-gold-700 dark:text-gold-500' : ''">{{ getEventCategory(action.actionTakenOnContent.category) }}</li>
              <li>
                <span :class="action.actionTakenOnContent.status === 'cancelled' ? 'line-through' : ''">{{ format(parseISO(new Date(action.actionTakenOnContent.actualStartDateTime).toISOString()), "LLL d, yyyy") }}</span> <span v-if="action.actionTakenOnContent.status === 'cancelled'"> (Cancelled)</span>
              </li>
            </ul>
          </router-link>
        </li>
        <li v-for="action in actionsArray" v-if="item.contentType === 'post' && item.activitySubType === 'comment'">
          <router-link :to="`/groups/${action.actionTakenOnContent.groupSlug}/${action.actionTakenOnContent.short}${action.actionRefId ? '#'+action.actionRefId : ''}`">
            <figure class="flex flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
              <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm152 80c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z"/></svg>
            </figure>
          </router-link>
          <router-link class="grid break-words text-sm sm:text-base" :to="`/groups/${action.actionTakenOnContent.groupSlug}/${action.actionTakenOnContent.short}${action.actionRefId ? '#'+action.actionRefId : ''}`">
            <div :title="action.actionContent" class="relative whitespace-nowrap overflow-hidden overflow-ellipsis mr-4">{{ action.actionContent }}</div>
            <ul class="flex gap-x-4 text-xs dark:text-gray-400">
              <li>{{ action.actionTakenOnContent.title }} in {{ action.actionTakenOnContent.groupName }}</li>
            </ul>
          </router-link>
        </li>
        <li v-for="action in actionsArray" v-if="item.contentType === 'event' && (item.activitySubType === 'comment')">
          <router-link :to="`/e/${action.actionTakenOnContent.short}`">
            <figure class="flex relative flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
              <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm152 80c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z"/></svg>
            </figure>
          </router-link>
          <router-link class="grid break-words text-sm sm:text-base" :to="`/e/${action.actionTakenOnContent.short}`">
            <div :title="action.actionContent" class="relative text-sm sm:text-base whitespace-nowrap overflow-hidden overflow-ellipsis mr-4">{{ action.actionContent }}</div>
            <ul class="flex gap-x-4 text-xs dark:text-gray-400">
              <li>{{ action.actionTakenOnContent.name }}</li>
              <li>
                <span :class="action.actionTakenOnContent.status === 'cancelled' ? 'line-through' : ''">{{ format(parseISO(new Date(action.actionTakenOnContent.actualStartDateTime).toISOString()), "LLL d, yyyy") }}</span> <span v-if="action.actionTakenOnContent.status === 'cancelled'"> (Cancelled)</span>
              </li>
            </ul>
          </router-link>
        </li>
        <li v-for="action in actionsArray" v-if="item.contentType === 'announcement' && item.activitySubType === 'reaction'">
          <router-link :to="`/announcements/${action.actionTakenOnContent.slug}`">
            <figure class="flex flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
              <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="fa-secondary" opacity=".4" d="M192 128l0 32 0 32 0 96 0 32 0 32 8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6l0-147.6 0-120.7L480 32c0-12.9-7.8-24.6-19.8-29.6C456.3 .8 452.1 0 448 0c-8.3 0-16.5 3.3-22.6 9.4L381.7 53c-48 48-113.1 75-181 75c-2.9 0-5.8 0-8.7 0z"/><path class="fa-primary" d="M0 192c0-35.3 28.7-64 64-64l128 0 0 192 0 32 0 128c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-128c-35.3 0-64-28.7-64-64l0-96zm512 48c0 27.9-13.4 51.6-32 60.4l0-120.7c18.6 8.8 32 32.5 32 60.4z"/></svg>
            </figure>
          </router-link>
          <router-link class="break-words text-sm sm:text-base" :to="`/announcements/${action.actionTakenOnContent.slug}`">
            {{ action.actionTakenOnContent.title }}
            <ul class="flex gap-x-4 text-xs dark:text-gray-400">
              <li>{{ action.actionTakenOnContent.title }}</li>
              <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
              <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
            </ul>
          </router-link>
        </li>
        <li v-for="action in actionsArray" v-if="item.contentType === 'announcement' && item.activitySubType === 'comment'">
          <router-link :to="`/announcements/${action.actionTakenOnContent.slug}${action.actionRefId ? '#'+action.actionRefId : ''}`">
            <figure class="flex flex-none items-center justify-center text-sm h-10 w-10 sm:h-12 sm:w-12 text-gray-800 dark:text-gray-500 bg-gray-200 dark:bg-submit-900 font-bold rounded-md" :style="action.actionThumbnail ? `background-image: url('${action.actionThumbnail}')` : ''">
              <svg class="dark:fill-gray-400 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="fa-secondary" opacity=".4" d="M192 128l0 32 0 32 0 96 0 32 0 32 8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6l0-147.6 0-120.7L480 32c0-12.9-7.8-24.6-19.8-29.6C456.3 .8 452.1 0 448 0c-8.3 0-16.5 3.3-22.6 9.4L381.7 53c-48 48-113.1 75-181 75c-2.9 0-5.8 0-8.7 0z"/><path class="fa-primary" d="M0 192c0-35.3 28.7-64 64-64l128 0 0 192 0 32 0 128c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-128c-35.3 0-64-28.7-64-64l0-96zm512 48c0 27.9-13.4 51.6-32 60.4l0-120.7c18.6 8.8 32 32.5 32 60.4z"/></svg>
            </figure>
          </router-link>
          <router-link class="grid" :to="`/announcements/${action.actionTakenOnContent.slug}${action.actionRefId ? '#'+action.actionRefId : ''}`">
            <div :title="action.actionContent" class="relative text-sm sm:text-base whitespace-nowrap overflow-hidden overflow-ellipsis mr-4">{{ action.actionContent }}</div>
            <ul class="flex gap-x-4 text-xs dark:text-gray-400">
              <li>{{ action.actionTakenOnContent.title }}</li>
              <li>{{ getReactionCount(action.actionTakenOnContent.reactionsCount) }} reaction{{ getReactionCount(action.actionTakenOnContent.reactionsCount) === 1 ? '' : 's' }}</li>
              <li>{{ action.actionTakenOnContent.commentCount }} comment{{ action.actionTakenOnContent.commentCount === 1 ? '' : 's' }}</li>
            </ul>
          </router-link>
        </li>
        <li
          class="hover:cursor-pointer"
          v-if="shouldLoadMoreActions"
          @click="loadMoreActions"
        >
          <div
            v-if="item.contentType === 'media' && item.activitySubType === 'reaction'"
            :class="[
              item.contentType === 'media' ? 'flex flex-none items-center justify-center' : '',
              'text-[8px] w-[3.5rem] h-[3.5rem] font-bold text-center dark:text-gray-400'
            ]"
          >
            SHOW<br/>MORE
          </div>
          <div class="dark:text-gray-500 text-sm" v-else>
            Show More
          </div>
        </li>
      </ul>
      <section v-if="item.activityType === 'event'">
        <router-link :to="`/e/${item.content.short}`" class="dark:text-gold-500 text-gold-700 text-xl font-semibold font-display">
          {{ item.content.name }}
        </router-link>
        <div class="text-sm" v-if="item.content.location && item.content.location.place_name_en">
          {{ adjustToUSA(item.content.location.place_name_en) }}
        </div>
        <ul class="grid gap-2 mt-2 text-xs dark:text-gray-300 text-gray-700">
          <li class="flex gap-2 items-center">
            <svg class="w-3.5 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M512 384c35.3 0 64-28.7 64-64l0-160c0-35.3-28.7-64-64-64L394.5 96c-17 0-33.3-6.7-45.3-18.7L322.7 50.7c-12-12-28.3-18.7-45.3-18.7L160 32c-35.3 0-64 28.7-64 64l0 224c0 35.3 28.7 64 64 64l352 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120L0 344c0 75.1 60.9 136 136 136l320 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-320 0c-48.6 0-88-39.4-88-88l0-224z"/></svg>
            <span :class="item.content.category === 'submit-event' ? 'dark:text-gold-500 text-gold-700' : ''">{{ getEventCategory(item.content.category) }}</span>
          </li>
          <li class="flex gap-2 items-center">
            <svg class="w-3.5 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z"/></svg>
            <div v-if="!item.content.multiDay">
              <span :class="item.content.status === 'cancelled' ? 'line-through' : ''">{{ format(parseISO(new Date(item.content.actualStartDateTime).toISOString()), "LLL d, yyyy") }}</span> <span v-if="item.content.status === 'cancelled'"> (Cancelled)</span>
            </div>
            <div v-if="item.content.multiDay">
              <span :class="item.content.status === 'cancelled' ? 'line-through' : ''">{{ format(parseISO(new Date(item.content.actualStartDateTime).toISOString()), "LLL d, yyyy") }}</span>
              <span :class="item.content.status === 'cancelled' ? 'line-through' : ''"> to </span>
              <span :class="item.content.status === 'cancelled' ? 'line-through' : ''">{{ format(parseISO(new Date(item.content.actualEndDateTime).toISOString()), "LLL d, yyyy") }}</span> <span v-if="item.content.status === 'cancelled'"> (Cancelled)</span>
            </div>
          </li>
          <li class="flex gap-2 items-center">
            <svg class="w-3.5 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M64 64C28.7 64 0 92.7 0 128l0 64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320l0 64c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-64c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6l0-64c0-35.3-28.7-64-64-64L64 64zm64 112l0 160c0 8.8 7.2 16 16 16l288 0c8.8 0 16-7.2 16-16l0-160c0-8.8-7.2-16-16-16l-288 0c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32l320 0c17.7 0 32 14.3 32 32l0 192c0 17.7-14.3 32-32 32l-320 0c-17.7 0-32-14.3-32-32l0-192z"/></svg>
            <div>{{ item.content.rsvpCount }} Attendee{{ item.content.rsvpCount === 1 ? '' : 's' }}</div>
          </li>
          <li class="flex gap-2 items-center" v-if="item.content.tags.length > 0">
            <svg class="w-3.5 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 80L0 229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7L48 32C21.5 32 0 53.5 0 80zm112 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>
            <div>{{ item.content.tags.map((tag: any) => tag).join(", ") }}</div>
          </li>
        </ul>
      </section>
      <figure class="Photo Status" v-if="item.activityType === 'status' && item.content.type === 'image'">
        <router-link :to="`/${item.author.username}/status/${item.content._id}`">
          <img loading="lazy" class="pointer-events-none" :width="item.content.mediaWidth" :height="item.content.mediaHeight" :src="item.content.mediaUrl" @error="imageErrorHandler ? imageErrorHandler(item._id) : null" />
        </router-link>
      </figure>
      <section
        class="CommonBody"
        v-if="item.activityType === 'status' && contentText !== '<p></p>' && !edit && !showCW()"
        v-html="processedContent"
        @click="handleMentionClick"
      >
      </section>
      <section class="cursor-pointer" @click="cwVisible = false" v-if="item.activityType === 'status' && !edit && showCW()">
        <h3 class="flex items-center gap-x-3">
          <svg
            class="h-5 fill-yellow-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <title>Content Warning</title>
            <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/>
          </svg>
          <span class="font-bold">Content Warning!</span>
        </h3>
        <p class="text-base">{{ cwText }}</p>
        <small class="text-sm text-gray-500 dark:text-gray-400">Click/tap to view content</small>
      </section>
      <section v-if="edit">
        <StatusEditor :status="item" @close="closeEdit" />
      </section>
      <section class="Writing" v-if="item.activityType === 'writing'">
        <h2 class="text-xl font-semibold dark:text-gold-500 text-gold-700">
          <router-link :to="`/${item.author.username}/writing/${item.content.slug}`">{{ item.content.title }}</router-link>
        </h2>
        <p v-if="item.content.description">{{ item.content.description }}</p>
      </section>
      <section class="Announcement" v-if="item.activityType === 'announcement'">
        <router-link :to="`/announcements/${item.content.slug}`">
          <h2 class="text-xl font-semibold dark:text-gold-500 text-gold-700">{{ item.content.title }}</h2>
          <p v-if="item.content.summary">{{ item.content.summary }}</p>
        </router-link>
      </section>
      <section class="Post" v-if="item.activityType === 'post'">
        <h2 class="bg-gold-500/30 inline-block px-4 p-0.5 rounded-xl font-semibold">
          <router-link :to="`/groups/${item.content.groupSlug}/${item.content.short}`">{{ item.content.title }}</router-link>
        </h2>
        <p v-if="item.content.raw">{{ item.content.raw }}<span v-if="item.content.hasMore">&hellip;</span></p>
      </section>
      <figure
        v-if="item.activityType === 'media'"
        class="overflow-hidden"
        :class="{
          'Photo': item.activityType === 'media' && mediaArray.length <= 4,
          'Single': mediaArray.length === 1,
          'Double': mediaArray.length === 2,
          'Triple': mediaArray.length === 3,
          'Quad': mediaArray.length === 4,
          'Thumbs': mediaArray.length > 4
        }"
      >
        <router-link :to="`/${item.author.username}/media/${media._id}`" v-for="media in mediaArray" :key="media._id" v-if="mediaArray.length <= 4">
          <img loading="lazy" class="pointer-events-none" :width="media.width" :height="media.height" :src="media.src" @error="imageErrorHandler ? imageErrorHandler(item._id) : null" />
        </router-link>
        <ul class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <li v-for="media in mediaArray" :key="media._id" v-if="mediaArray.length > 4">
            <router-link :to="`/${item.author.username}/media/${media._id}`">
              <img class="aspect-[4/3] object-cover rounded-lg pointer-events-none" loading="lazy" :width="media.width" :height="media.height" :src="media.src" @error="imageErrorHandler ? imageErrorHandler(item._id) : null" />
            </router-link>
          </li>
          <li v-if="shouldLoadMore" class="col-span-2 md:col-span-3 lg:col-span-4 text-center text-sm bg-neutral-125 dark:bg-submit-950 py-2 hover:cursor-pointer rounded-md" @click="loadMore">Load More</li>
        </ul>
      </figure>
      <footer v-if="!edit && item.activityType !== 'action'" :class="item.activityType === 'status' && item.content.type === 'image' && contentText === '<p></p>' ? 'pt-4' : ''">
        <ul class="Reactions text-xs dark:text-gray-400 text-gray-500 w-full" v-if="!edit">
          <li v-if="item.activityType === 'post' && item.content.type !== 'personal' ">
            <Reactions :path="`/groups/${item.content.groupSlug}/${item.content.short}`" :reactions="reactionsCount" :reacted="reacted" :reactionData="reactionData" small />
          </li>
          <li
            v-if="(item.activityType === 'status' || item.activityType === 'writing' || item.activityType === 'announcement') || (item.activityType === 'media' && mediaArray.length === 1) || item.activityType === 'event'">
            <ReactV2 :reacted="reacted" :reactionData="reactionData" :self="item.self" :reactions="reactionsCount" :content="content" :disabled="item.activityType === 'event' && item.content.status === 'cancelled'" small :lm="true" />
          </li>
          <li class="dark:text-gray-400 text-gray-500" v-if="item.activityType === 'status' || (item.activityType === 'media' && mediaArray.length === 1) || item.activityType === 'writing' || item.activityType === 'event'">
            <router-link class="group flex gap-x-2 items-center" v-if="item.activityType !== 'event'" :to="`/${item.author.username}/${item.activityType}/${item.activityType === 'media' ? mediaArray[0]._id : (item.activityType === 'writing' ? item.content.slug : (props.item.activityType === 'media' ? props.item.content[0]._id : props.item.content._id))}`">
              <svg class="flex-none w-4 h-4 dark:fill-gray-400 group-hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z"/></svg>
              <span class="group-hover:dark:text-white">{{ item.activityType === 'media' ? mediaArray[0].commentCount : item.content.commentCount }} comment{{ (item.activityType === 'media' ? mediaArray[0].commentCount : item.content.commentCount) === 1 ? '' : 's' }}</span>
            </router-link>
            <router-link class="group flex gap-x-2 items-center" v-else :to="`/e/${item.content.short}`">
              <svg class="flex-none w-4 h-4 dark:fill-gray-400 group-hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z"/></svg>
              <span class="group-hover:dark:text-white">{{ item.activityType === 'media' ? mediaArray[0].commentCount : item.content.commentCount }} comment{{ (item.activityType === 'media' ? mediaArray[0].commentCount : item.content.commentCount) === 1 ? '' : 's' }}</span>
            </router-link>
          </li>
          <li v-if="item.activityType === 'announcement'">
            <router-link class="group flex gap-x-2 items-center" :to="`/announcements/${item.content.slug}`">
              <svg class="flex-none w-4 h-4 dark:fill-gray-400 group-hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z"/></svg>
              <span class="group-hover:dark:text-white">{{ item.content.commentCount }} comment{{ item.content.commentCount === 1 ? '' : 's' }}</span>
            </router-link>
          </li>
          <li v-if="item.activityType === 'post' && item.content.type !== 'personal'">
            <router-link class="group flex gap-x-2 items-center" :to="`/groups/${item.content.groupSlug}/${item.content.short}`">
              <svg class="flex-none w-4 h-4 dark:fill-gray-400 group-hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9c.1-.2 .2-.3 .3-.5z"/></svg>
              <span class="group-hover:dark:text-white">{{ item.content.commentCount }} comment{{ item.content.commentCount === 1 ? '' : 's' }}</span>
            </router-link>
          </li>
          <!-- <li class="group flex gap-x-2 items-center cursor-not-allowed" v-if="item.activityType !== 'post'">
            <svg class="flex-none w-4 h-4 dark:fill-gray-400 group-hover:dark:fill-white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM135.1 217.4c-4.5 4.2-7.1 10.1-7.1 16.3c0 12.3 10 22.3 22.3 22.3H208v96c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V256h57.7c12.3 0 22.3-10 22.3-22.3c0-6.2-2.6-12.1-7.1-16.3L269.8 117.5c-3.8-3.5-8.7-5.5-13.8-5.5s-10.1 2-13.8 5.5L135.1 217.4z"/></svg>
            <span class="group-hover:dark:text-white">{{ item.content.boostCount ? item.content.boostCount : 0 }} Boosts</span>
          </li> -->
          <li class="text-xs flex items-center gap-x-2 capitalize" v-if="item.audience !== 'public'">
            <svg class="flex-none w-f h-4 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
            <span>{{audienceLabel(item.audience)}}</span>
          </li>
        </ul>
      </footer>
    </article>
  </li>
</template>
