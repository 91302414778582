<script setup lang="ts">
import { onMounted, onUnmounted, inject, ref, nextTick, watch, computed } from "vue"
import NewCollection from "@/components/collections/New.vue"
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { useRouter } from "vue-router"
import { storeToRefs } from "pinia"

import API from "@/api/api"

interface Props {
  collection: string
}

const Router = useRouter()
const Platform = usePlatformStore()
const User = useUserStore()
const { id } = storeToRefs(User)
const { theme } = storeToRefs(Platform)

const props = defineProps<Props>()

const profileData: any = inject("profileData")

const initialLoadComplete = ref(false)
const working = ref(false)
const totalItems = ref(0)
const collectionData = ref<any>({})

const viewType = ref<'grid' | 'list'>('grid')

const writingsList = ref<any[]>([])
const pageInfo = ref<any>({
  endCursor: undefined
})

const previewGenerating = ref<Set<string>>(new Set())
const previewQueue = ref<any[]>([])
const processingPreviews = ref(false)

const isOwner = computed(() => collectionData.value.user === id.value)

onMounted(async () => {
  await fetchCollection()
  initialLoadComplete.value = true
  if (props.collection === 'default') {
    document.title = `${profileData.value.username}'s Writing - Submit`
  } else {
    document.title = `${profileData.value.username}'s Writing Collection - Submit`
  }
  await nextTick()
  if (writingsList.value.length > 0) {
    let target = document.querySelector('#loadMore')
    observer.observe(target as any)
  }
})

watch(() => props.collection, async (newVal) => {
  initialLoadComplete.value = false
  writingsList.value = []
  pageInfo.value = {
    endCursor: undefined
  }
  observer.disconnect()
  await fetchCollection()
  initialLoadComplete.value = true
  if (newVal === 'default') {
    document.title = `${profileData.value.username}'s Writing - Submit`
  } else {
    document.title = `${profileData.value.username}'s Writing Collection - Submit`
  }
  await nextTick()
  if (writingsList.value.length > 0) {
    let target = document.querySelector('#loadMore')
    observer.observe(target as any)
  }
})

onUnmounted(() => {
  if (writingsList.value.length > 0) {
    observer.disconnect()
  }
})

async function generatePreview(item: any) {
  if (previewGenerating.value.has(item._id)) return

  previewGenerating.value.add(item._id)
  try {
    const response = await API().get(`/writing/${item._id}/preview`)
    const index = writingsList.value.findIndex(w => w._id === item._id)
    if (index !== -1) {
      writingsList.value[index] = {
        ...writingsList.value[index],
        previewCDNDark: response.data.data.previewCDNDark,
        previewCDN: response.data.data.previewCDN
      }
    }
  } catch (err: any) {
    console.error('Failed to generate preview:', err)
  } finally {
    previewGenerating.value.delete(item._id)
  }
}

async function processPreviewQueue() {
  if (processingPreviews.value || previewQueue.value.length === 0) return

  processingPreviews.value = true
  while (previewQueue.value.length > 0) {
    const item = previewQueue.value.shift()
    await generatePreview(item)
  }
  processingPreviews.value = false
}

async function fetchCollection() {
  if (working.value) return
  working.value = true
  try {
    let path = `/users/${profileData.value._id}/writing/`
    if (props.collection === "drafts") {
      path += "drafts"
    } else {
      path += `collections/${props.collection}`
    }
    if (pageInfo.value.endCursor) {
      path += `?${new URLSearchParams({
        cursor: pageInfo.value.endCursor
      })}`
    }
    const response = await API().get(path)
    writingsList.value.push(...response.data.data)

    // Add all items without previews to the queue
    const itemsWithoutPreviews = response.data.data.filter((item: any) => !item.previewCDN)
    previewQueue.value.push(...itemsWithoutPreviews)
    processPreviewQueue()

    if (props.collection === "drafts") totalItems.value = response.data.data.length
    if (!pageInfo.value.endCursor && props.collection !== "drafts") totalItems.value = response.data.pageInfo.totalItems
    if (props.collection !== "drafts") pageInfo.value = response.data.pageInfo
    if (props.collection !== "drafts" && props.collection !== "default") collectionData.value = response.data.collection
    working.value = false
  } catch (err: any) {
    console.error(err)
  }
}

async function updateCollection(data: any) {
  collectionData.value = {
    title: data.title,
    type: data.type,
    visibility: data.visibility,
    tags: data.tags,
    collectionOnly: data.collectionOnly
  }
  if (data.description && data.description.length > 0) {
    collectionData.value.description = data.description
  }
}

async function deleteCollection() {
  if (working.value) return
  working.value = true
  if (confirm("Are you sure you want to delete this collection?")) {
    try {
      await API().delete(`/collections/${collectionData.value._id}`)
      Router.push(`/${profileData.value.username}/writing/collections`)
    } catch (err: any) {
      console.error(err)
      working.value = false
      alert("There was an error deleting this collection, please try again.")
    }
  } else {
    working.value = false
    return
  }
}

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        fetchCollection()
      }
    }
  })
}, options)

function openReportDialog() {
  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: collectionData.value.user,
    contentId: collectionData.value._id,
    contentType: "collection"
  })
}

function getEmojiForType(type: string) {
  return type === "like" ? "👍" : type === "dislike" ? "👎" : type === "love" ? "❤️" : type === "hot" ? "🥵" : type === "laugh" ? "😂" : type === "cry" ? "😢" : type === "angry" ? "😠" : type === "celebrate" ? "🥳" : ""
}

function getMostPopularReaction(reactions: Record<string, number>) {
  const sorted = Object.entries(reactions)
    .filter(([_, count]) => count > 0)
    .sort(([_, a], [__, b]) => b - a)

  if (sorted.length === 0) return ''
  return getEmojiForType(sorted[0][0])
}

function getTotalReactions(reactions: Record<string, number>) {
  return Object.values(reactions).reduce((sum, count) => sum + count, 0)
}

function hasActivity(item: any): boolean {
  return getTotalReactions(item.reactionsCount) > 0 || item.commentCount > 0
}
</script>

<template>
  <section class="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6" v-if="initialLoadComplete">
    <div v-if="props.collection !== 'drafts' && props.collection !== 'default'" class="CollectionDetail gap-x-4 col-span-full">
      <div class="flex justify-center flex-grow aspect-[5/3] bg-white dark:bg-submit-925/50 rounded-md">
        <svg class="w-8 fill-gray-400 dark:fill-gray-500 group-hover:fill-gold-700 dark:group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg>
      </div>
      <div class="py-2">
        <h2 class="text-2xl">{{ collectionData.title }}</h2>
        <p class="text-sm">{{ collectionData.description && collectionData.description.length > 0 ? collectionData.description : 'No description found, how sad.' }}</p>
        <ul class="flex gap-4 mt-6 text-sm">
          <li v-if="isOwner">
            <NewCollection @updated="updateCollection" :type="collectionData.type" :collection="collectionData">Edit</NewCollection>
          </li>
          <li v-if="isOwner">
            <button @click="deleteCollection" type="button">Delete</button>
          </li>
          <li v-if="!isOwner">
            <button @click="openReportDialog" type="button">Report</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-span-full flex justify-between items-center text-sm font-bold">
      <span>{{ totalItems }} {{ collection === 'drafts' ? 'draft' : 'writing' }}{{ writingsList.length === 1 ? '' : 's' }}</span>
      <div class="flex gap-4 items-center">
        <router-link :to="`/${profileData.username}/writing/new`" v-if="profileData.self">Add Writing</router-link>
        <div
          :class="[
            profileData.self ? 'border-l dark:border-gray-500 pl-4' : '',
            'flex gap-4'
          ]"
        >
          <svg
            @click="viewType = 'grid'"
            :class="[
              viewType === 'grid' ? 'dark:fill-gray-300 fill-black' : 'dark:fill-gray-500 fill-gray-400',
              'h-4 cursor-pointer'
            ]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M224 80c0-26.5-21.5-48-48-48L80 32C53.5 32 32 53.5 32 80l0 96c0 26.5 21.5 48 48 48l96 0c26.5 0 48-21.5 48-48l0-96zm0 256c0-26.5-21.5-48-48-48l-96 0c-26.5 0-48 21.5-48 48l0 96c0 26.5 21.5 48 48 48l96 0c26.5 0 48-21.5 48-48l0-96zM288 80l0 96c0 26.5 21.5 48 48 48l96 0c26.5 0 48-21.5 48-48l0-96c0-26.5-21.5-48-48-48l-96 0c-26.5 0-48 21.5-48 48zM480 336c0-26.5-21.5-48-48-48l-96 0c-26.5 0-48 21.5-48 48l0 96c0 26.5 21.5 48 48 48l96 0c26.5 0 48-21.5 48-48l0-96z"/>
          </svg>
          <svg
            @click="viewType = 'list'"
            :class="[
              viewType === 'list' ? 'dark:fill-gray-300 fill-black' : 'dark:fill-gray-500 fill-gray-400',
              'h-4 cursor-pointer'
            ]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"/>
          </svg>
        </div>
      </div>
    </div>
    <div
      :class="[
        viewType === 'grid'
          ? 'overflow-hidden relative rounded-md flex flex-col bg-white dark:bg-submit-925 dark:hover:bg-gold-500/25 hover:bg-gold-700/25'
          : 'col-span-full',
        'group'
      ]"
      v-for="item in writingsList"
      :key="item._id"
    >
      <router-link :to="`/${profileData.username}/writing/${item.slug}${collection === 'drafts' ? '/edit' : ''}`" class="flex">
        <template v-if="viewType === 'grid'">
          <div class="pb-[140%]"></div>
          <div class="absolute flex flex-col inset-0 p-2">
            <ul class="absolute hidden group-hover:block rounded-md left-0 bottom-0 bg-submit-600/80 w-full max-w-[calc(100%-32px)] z-10 m-4 p-2" v-if="item.status === 'published'">
              <li class="text-xs font-semibold">
                <template v-if="hasActivity(item)">
                  <template v-if="getTotalReactions(item.reactionsCount) > 0">
                    {{ getMostPopularReaction(item.reactionsCount) }}
                    {{ getTotalReactions(item.reactionsCount) }} reaction{{ getTotalReactions(item.reactionsCount) > 1 ? 's' : '' }}
                  </template>
                  <template v-if="getTotalReactions(item.reactionsCount) > 0 && item.commentCount > 0">
                    <span class="mx-1">and</span>
                  </template>
                  <template v-if="item.commentCount > 0">
                    {{ item.commentCount }} comment{{ item.commentCount > 1 ? 's' : '' }}
                  </template>
                </template>
                <template v-else>
                  No activity, yet.
                </template>
              </li>
            </ul>
            <div class="relative dark:bg-submit-925 bg-white p-2 rounded-t-md">
              <div>
                <h3 :title="item.title" class="text-sm sm:text-base lg:text-lg font-bold line-clamp-2">
                  {{ item.title }}
                </h3>
                <div class="flex justify-between items-center">
                  <time class="block text-xs">{{ item.status === 'draft' ? D(item.createdAt).format('LL') : D(item.publishedAt).format('LL') }}</time>
                  <svg
                    v-if="(props.collection === 'drafts' || props.collection === 'default') && item.collection"
                    class="h-3 fill-gray-400 dark:fill-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M96 0C43 0 0 43 0 96L0 416c0 53 43 96 96 96l288 0 32 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l0-64c17.7 0 32-14.3 32-32l0-320c0-17.7-14.3-32-32-32L384 0 96 0zm0 384l256 0 0 64L96 448c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16l192 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-192 0c-8.8 0-16-7.2-16-16zm16 48l192 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-192 0c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/>
                  </svg>
                </div>
                <div class="w-full h-[1px] dark:bg-gray-700 bg-gray-200 my-2"></div>
              </div>
              <div class="overflow-hidden">
                <div class="min-h-[364px] overflow-hidden relative dark:bg-submit-925 bg-white">
                  <img draggable="false" :src="theme === 'dark' ? item.previewCDNDark : item.previewCDN" :alt="item.title" class="w-[258px]" v-if="item.previewCDN">
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="viewType === 'list'">
          <div class="p-2 bg-white dark:bg-submit-925 hover:bg-gold-700/25 dark:hover:bg-gold-500/25 rounded-md w-full group">
            <div class="p-2 dark:bg-submit-925 bg-white rounded-md flex justify-between items-center flex-wrap gap-3">
              <div>
                <h3 :title="item.title" class="text-sm sm:text-base lg:text-lg font-bold line-clamp-2">{{ item.title }}</h3>
                <time class="block text-xs">{{ item.status === 'draft' ? D(item.createdAt).format('LL') : D(item.publishedAt).format('LL') }}</time>
              </div>
              <div class="text-sm">
                <template v-if="hasActivity(item)">
                  <template v-if="getTotalReactions(item.reactionsCount) > 0">
                    {{ getMostPopularReaction(item.reactionsCount) }}
                    {{ getTotalReactions(item.reactionsCount) }} reaction{{ getTotalReactions(item.reactionsCount) > 1 ? 's' : '' }}
                  </template>
                  <template v-if="getTotalReactions(item.reactionsCount) > 0 && item.commentCount > 0">
                    <span class="mx-1">and</span>
                  </template>
                  <template v-if="item.commentCount > 0">
                    {{ item.commentCount }} comment{{ item.commentCount > 1 ? 's' : '' }}
                  </template>
                </template>
                <template v-else>
                  No activity, yet.
                </template>
              </div>
            </div>
          </div>
        </template>
      </router-link>
    </div>
    <div v-if="writingsList.length > 0" id="loadMore"></div>
  </section>
  <section class="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6" v-else>
    <div class="flex justify-between col-span-full">
      <span class="bg-neutral-50 dark:bg-indigo-925/25 w-24 h-4 animate-pulse"></span>
    </div>
    <div v-for="i in 4" :key="i">
      <div class="block w-full h-[361px] bg-neutral-50 dark:bg-indigo-925/25 rounded-md animate-pulse"></div>
    </div>
  </section>
</template>
