<script setup lang="ts">
import { useUserStore } from "@/stores/User"
import { useRouter } from "vue-router"
import { ref, onMounted } from "vue"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const ExploreAPI = import.meta.env.VITE_EXPLORE_API

const Router = useRouter()
const UserStore = useUserStore()
const { roles } = storeToRefs(UserStore)

const initialLoadComplete = ref(false)
const working = ref(false)
const isAdmin = ref(roles.value.includes("admin"))
const feedData = ref<any>(undefined)
const error = ref(false)

onMounted(async () => {
  await fetchExploreFeed()
  initialLoadComplete.value = true
})

async function fetchExploreFeed() {
  try {
    const response = await fetch(`${ExploreAPI}/writing`, {
      method: 'GET',
      credentials: "include",
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await response.json()
    feedData.value = data.map((item: any) => ({ ...item, loading: true }))
  } catch (err) {
    console.error(err)
    error.value = true
  }
}

async function replaceFeedItem(id: string) {
  working.value = true
  try {
    const response = await fetch(`${ExploreAPI}/w/replace`, {
      method: 'POST',
      credentials: "include",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ OldItem: id })
    })
    const data = await response.json()
    feedData.value = feedData.value.map((item: any) => {
      if (item._id === id) {
        return { ...data, loading: true }
      }
      return item
    })
  } catch (err) {
    console.error(err)
    error.value = true
  }
  working.value = false
}

async function markItemAsIneligible(id: string) {
  working.value = true
  try {
    await API().patch(`/writing/${id}/ineligibility`, {
      type: "explore",
      bool: true
    })
  } catch (err) {
    console.error(err)
    error.value = true
  } finally {
    working.value = false
  }
}

function getEmojiForType(type: string) {
  return type === "like" ? "👍" : type === "dislike" ? "👎" : type === "love" ? "❤️" : type === "hot" ? "🥵" : type === "laugh" ? "😂" : type === "cry" ? "😢" : type === "angry" ? "😠" : type === "celebrate" ? "🥳" : ""
}

function getMostPopularReaction(reactions: Record<string, number>) {
  const sorted = Object.entries(reactions)
    .filter(([_, count]) => count > 0)
    .sort(([_, a], [__, b]) => b - a)

  if (sorted.length === 0) return '👍'
  return getEmojiForType(sorted[0][0])
}

function getTotalReactions(reactions: Record<string, number>) {
  return Object.values(reactions).reduce((sum, count) => sum + count, 0)
}
</script>

<template>
  <masonry-wall :items="feedData" :column-width="224" :min-columns="2" :max-columns="6" :gap="8" v-if="initialLoadComplete">
    <template #default="{ item, index }: { item: any, index: number }" v-if="feedData">
      <div class="bg-white dark:bg-submit-900 rounded-lg p-4">
        <router-link :to="`/${item.username}/writing/${item._id}`">
          <h3 class="text-lg font-bold line-clamp-2">{{ item.title }}</h3>
          <p class="text-sm text-gray-500 dark:text-gray-400">{{ item.description || 'No description provided.' }}</p>
        </router-link>
        <div class="w-full h-[1px] dark:bg-gray-700 bg-gray-200 my-2"></div>
        <div class="text-xs">
          <ul class="grid grid-cols-2 gap-1 dark:text-gray-400 items-center">
            <li>
              <router-link :to="`/${item.username}`" class="dark:text-gold-500 text-gold-700">@{{ item.username }}</router-link>
              <div class="flex items-center gap-3">
                <div>{{ getMostPopularReaction(item.reactionsCount) }} {{ getTotalReactions(item.reactionsCount) }}</div>
                <div class="flex items-center gap-1">
                  <svg class="h-3 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9l.3-.5z"/></svg>{{ item.commentCount }}
                </div>
              </div>
            </li>
            <!-- <li class="flex items-center justify-end">
              <button type="button">
                <svg class="h-4 fill-gray-400 dark:hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M544 0c13.3 0 24 10.7 24 24l0 48 48 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-48 0 0 48c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-48-48 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l48 0 0-48c0-13.3 10.7-24 24-24zM400 288A176 176 0 1 0 48 288a176 176 0 1 0 352 0zM0 288a224 224 0 1 1 448 0A224 224 0 1 1 0 288zm144.4-80a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm128 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM146 332.8c19.1 21.6 46.9 35.2 78 35.2s58.9-13.6 78-35.2c8.8-9.9 23.9-10.9 33.9-2.1s10.9 23.9 2.1 33.9C310.2 396.1 269.4 416 224 416s-86.2-19.9-114-51.5c-8.8-9.9-7.8-25.1 2.1-33.9s25.1-7.8 33.9 2.1z"/></svg>
              </button>
            </li> -->
            <li class="flex col-span-full items-between" v-if="isAdmin">
              <div class="flex">#{{ index + 1 }}</div>
              <ul class="flex flex-1 justify-end gap-2">
                <li class="flex">
                  <button class="flex group" type="button">
                    <svg class="w-4 fill-gray-400 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7l388.6 0c3.9 0 7.6-.7 11-2.1l-261-205.6z"/></svg>
                  </button>
                </li>
                <li class="flex group">
                  <button @click="markItemAsIneligible(item._id)" class="flex group" type="button">
                    <svg class="w-4 fill-gray-400 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L512 376l0-221.5c0-17-6.7-33.3-18.7-45.3L402.7 18.7C390.7 6.7 374.5 0 357.5 0L192 0c-35.3 0-64 28.7-64 64l0 11L38.8 5.1zM176 112.6L176 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 178.4L176 112.6zM448 464l-256 0c-8.8 0-16-7.2-16-16l0-213.3-48-37.8L128 448c0 35.3 28.7 64 64 64l256 0c20.1 0 38.1-9.3 49.8-23.8l-37.7-29.7c-2.9 3.4-7.3 5.5-12.1 5.5z"/></svg>
                  </button>
                </li>
                <li class="flex group">
                  <button @click="replaceFeedItem(item._id)" type="button">
                    <svg class="w-4 fill-gray-400 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="fa-secondary" opacity=".4" d="M480 256c17.7 0 32 14.3 32 32c0 88.4-71.6 160-160 160H192l0 32c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-64-64c-12.5-12.5-12.5-32.8 0-45.3l64-64c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6v32H352c53 0 96-43 96-96c0-17.7 14.3-32 32-32z"/><path class="fa-primary" d="M32 256c-17.7 0-32-14.3-32-32C0 135.6 71.6 64 160 64H320V32c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l64 64c12.5 12.5 12.5 32.8 0 45.3l-64 64c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V128H160c-53 0-96 43-96 96c0 17.7-14.3 32-32 32z"/></svg>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </masonry-wall>
  <p v-if="error">There was an error fetching the explore feed.</p>
  <p v-if="!initialLoadComplete">Loading...</p>
</template>
