interface AnalyticsConfig {
  endpoint?: string
  batchSize?: number
  flushInterval?: number
  userId?: string | null
}

interface DeviceInfo {
  user_agent: string
  ip_address: string
  device_type: 'mobile' | 'desktop'
  browser: string
  os: string
}

interface BaseEvent {
  type: 'pageview' | 'session'
  timestamp: string
  session_id: string
  anonymous_id: string
  user_id?: string | null
  device_info: DeviceInfo
  properties: Record<string, unknown>
}

interface PageViewEvent extends BaseEvent {
  type: 'pageview'
  properties: {
    path: string;
    title: string;
    referrer: string | null;
  }
}

interface SessionEvent extends BaseEvent {
  type: 'session'
  properties: {
    duration: number
  }
}

type AnalyticsEvent = PageViewEvent | SessionEvent

export class AnalyticsClient {
  private endpoint: string
  private batchSize: number
  private flushInterval: number
  private events: AnalyticsEvent[]
  private sessionId: string
  private anonymousId: string
  private userId: string | null
  private flushIntervalId: number
  private baseUrl: string = import.meta.env.VITE_PUBLIC_API

  constructor(config: AnalyticsConfig = {}) {
    this.endpoint = `${this.baseUrl}/udc`
    this.batchSize = config.batchSize ?? 10
    this.flushInterval = config.flushInterval ?? 30000
    this.events = []
    this.sessionId = this.generateSessionId()
    this.anonymousId = this.getAnonymousId()
    this.userId = config.userId ?? null
    this.flushIntervalId = window.setInterval(() => this.flush(), this.flushInterval)
  }

  private getAnonymousId(): string {
    let id = localStorage.getItem('submit_audc_id')
    if (!id) {
      id = crypto.randomUUID()
      localStorage.setItem('submit_audc_id', id)
    }
    return id
  }

  private generateSessionId(): string {
    return crypto.randomUUID()
  }

  private getDeviceInfo(): DeviceInfo {
    const userAgent = navigator.userAgent
    return {
      user_agent: userAgent,
      ip_address: "", // Will be set by server
      device_type: /Mobile|Android|iPhone/i.test(userAgent) ? 'mobile' : 'desktop',
      browser: this.getBrowserInfo(userAgent),
      os: this.getOSInfo(userAgent)
    }
  }

  private getBrowserInfo(userAgent: string): string {
    if (userAgent.includes('Firefox')) return 'Firefox'
    if (userAgent.includes('Chrome')) return 'Chrome'
    if (userAgent.includes('Safari')) return 'Safari'
    if (userAgent.includes('Edge')) return 'Edge'
    return 'Other'
  }

  private getOSInfo(userAgent: string): string {
    if (userAgent.includes('Windows')) return 'Windows'
    if (userAgent.includes('Mac')) return 'MacOS'
    if (userAgent.includes('Linux')) return 'Linux'
    if (userAgent.includes('Android')) return 'Android'
    if (userAgent.includes('iOS')) return 'iOS'
    return 'Other'
  }

  public setUserId(userId: string | null): void {
    this.userId = userId
  }

  public trackPageView(path: string, title: string, referrer: string | null = null): void {
    const event: PageViewEvent = {
      type: 'pageview',
      timestamp: new Date().toISOString(),
      session_id: this.sessionId,
      anonymous_id: this.anonymousId,
      user_id: this.userId,
      device_info: this.getDeviceInfo(),
      properties: {
        path,
        title,
        referrer
      }
    }

    this.addEvent(event)
  }

  public trackSessionEnd(duration: number): void {
    const event: SessionEvent = {
      type: 'session',
      timestamp: new Date().toISOString(),
      session_id: this.sessionId,
      anonymous_id: this.anonymousId,
      user_id: this.userId,
      device_info: this.getDeviceInfo(),
      properties: {
        duration
      }
    }

    this.addEvent(event)
  }

  private addEvent(event: AnalyticsEvent): void {
    this.events.push(event)

    if (this.events.length >= this.batchSize) {
      void this.flush()
    }
  }

  public async flush(isUnload = false): Promise<void> {
    if (this.events.length === 0) return

    const eventsToSend = [...this.events]
    this.events = []

    try {
      if (isUnload && navigator.sendBeacon) {
        // Use sendBeacon for unload events
        const blob = new Blob([JSON.stringify({ events: eventsToSend })], {
          type: 'application/json'
        })
        navigator.sendBeacon(`${this.endpoint}/events`, blob)
      } else {
        const response = await fetch(`${this.endpoint}/events`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            events: eventsToSend
          }),
          credentials: 'include'
        })

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
      }
    } catch (error) {
      console.error('Failed to send UDC events:', error)
      this.events = [...eventsToSend, ...this.events]
    }
  }

  public destroy(): void {
    if (this.flushIntervalId) {
      clearInterval(this.flushIntervalId)
    }
    void this.flush()
  }
}

export default AnalyticsClient
