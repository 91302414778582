<script setup lang="ts">
import StatusEditor from "@/components/status/StatusEditor.vue"
import { ref, onBeforeMount, reactive, inject, computed } from "vue"
import Comments from "@/components/comments/Comments.vue"
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import React from "@/components/reactions/React.vue"
import ReactV2 from "@/components/reactions/ReactV2.vue"
import { useRoute, useRouter } from "vue-router"
import D from "@/composables/TimeDisplay"
import API from "@/api/api"

const Platform = usePlatformStore()
const User = useUserStore()
const { id } = storeToRefs(User)

const Router = useRouter()
const route = useRoute()
const profileData: any = inject("profileData")
const initialLoadComplete = ref(false)
const status = ref<any>(undefined)
const error = ref(false)
const edit = ref(false)
const content = reactive({
  contentAuthor: "",
  contentId: "",
  contentType: "",
  contentAudience: ""
})

async function getStatus() {
  try {
    document.title = `Status Update from ${profileData.value.username} - Submit`
    const response = await API().get(`/users/${profileData.value._id}/status/${route.params.id}`)
    status.value = response.data.data
    content.contentAuthor = status.value.user
    content.contentId = status.value._id
    content.contentType = "status"
    content.contentAudience = status.value.audience
    initialLoadComplete.value = true
  } catch (err: any) {
    if (err.response && err.response.status === 403) {
      return Router.push(`/${profileData.value.username}`)
    } else {
      console.error(err)
    }
    error.value = true
    initialLoadComplete.value = true
  }
}

const processedContent = computed(() => {
  if (!status.value.text) return ""

  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = status.value.text

  // Find all mention spans
  const mentions = tempDiv.querySelectorAll('span[data-type="mention"]')

  // Replace each mention span with a special anchor tag
  mentions.forEach(mention => {
    const username = mention.getAttribute('data-label')
    if (username) {
      const anchor = document.createElement('a')
      // Set actual URL in href
      anchor.setAttribute('href', `/${username}`)
      anchor.setAttribute('class', 'mention')
      // Add click prevention
      anchor.setAttribute('onclick', 'event.preventDefault()')
      anchor.setAttribute('data-username', username)
      anchor.textContent = `@${username}`
      mention.parentNode?.replaceChild(anchor, mention)
    }
  })

  return tempDiv.innerHTML
})

// Add click handler function
function handleMentionClick(event: MouseEvent) {
  const target = event.target as HTMLElement
  if (target.classList.contains('mention')) {
    const username = target.getAttribute('data-username')
    if (username) {
      Router.push(`/${username}`)
    }
  }
}

onBeforeMount(async () => {
  await getStatus()
})

async function confirmDelete(id: string) {
  if (confirm("Are you sure you want to delete this status update?")) {
    await deleteItem(id)
  }
}

async function deleteItem(id: string) {
  try {
    await API().delete(`/status/${id}`)
    Router.push(`/home`)
  } catch (err) {
    console.error(err)
    alert("There was an error deleting this status update.")
  }
}

function closeEdit(newData: any = undefined) {
  if (newData) {
    status.value.text = newData.text ? newData.text : status.value.text
    status.value.tw = newData.tw ? newData.tw : status.value.tw ? status.value.tw : undefined
  }
  edit.value = false
}

function openReportDialog() {
  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: status.value.user,
    contentId: status.value._id,
    contentType: "status"
  })
}
</script>

<template>
  <section class="px-2">
    <div class="container mx-auto Profile Status break-modern" v-if="initialLoadComplete && status">
      <div>
        <header class="text-sm font-bold mb-2 ml-4">Status Update from <time :title="D(status.createdAt).format('LLLL')">{{ D(status.createdAt).fromNow() }}</time></header>
        <figure v-if="status.type === 'image'" class="Status overflow-hidden rounded-t-2xl">
          <img loading="lazy" :width="status.mediaWidth" :height="status.mediaHeight" :src="status.mediaUrl" />
        </figure>
        <div class="space-y-3 bg-white dark:bg-submit-900 p-4" :class="status.type === 'image' ? 'rounded-b-2xl' : 'rounded-2xl'">
          <article class="CommonBody" v-html="processedContent" @click="handleMentionClick" v-if="!edit && status.text !== '<p></p>'"></article>
          <StatusEditor v-if="edit" :status="status" @close="closeEdit" />
          <footer class="text-sm">
            <div>
              <ul>
                <li class="font-semibold" v-if="status.tags && status.tags.length > 0">Tags</li>
                <li class="font-semibold" v-else>No Tags<span class="dark:text-submit-899 text-[#FEFEFF] select-none pointer-events-none overflow-hidden">{{ id }}</span></li>
              </ul>
            </div>
          </footer>
        </div>
        <div class="flex justify-between items-center mt-1.5 mx-4">
          <ReactV2  :self="status.self" :reactions="status.reactionsCount" :reacted="status.hasReacted ? status.hasReacted : false" :reactionData="status.reactionData ? status.reactionData : undefined" :content="content" />
          <ul class="flex gap-4 justify-end text-sm font-bold">
            <li v-if="status.self && status.type === 'text'">
              <button type="button" @click="edit = true" >Edit</button>
            </li>
            <li v-if="status.self">
              <button type="button" @click="confirmDelete(status._id)">Delete</button>
            </li>
            <li v-if="!status.self">
              <button type="button" @click="openReportDialog">Report</button>
            </li>
          </ul>
        </div>
      </div>
      <aside></aside>
      <div class="mt-6">
        <Comments type="status" :forAuthor="status.user" :forAudience="status.audience" :content="status._id" :locked="status.commentsLocked ? status.commentsLocked : false" />
      </div>
    </div>
    <div class="container mx-auto Profile Status" v-else-if="!initialLoadComplete">
      Loading
    </div>
    <div class="container mx-auto Profile Status" v-else-if="initialLoadComplete && error">
      There was an error loading this status update, please try again.
    </div>
  </section>
</template>
