<script setup lang="ts">
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue"
import { inject, onBeforeMount, ref, computed } from "vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const User = useUserStore()
const { backer, roles } = storeToRefs(User)

const initialLoadComplete = ref(false)
const setTitle = inject("setTitle") as (title: string) => void
const working = ref(false)
const month = new Date().getMonth()
const goalCount = ref(0)

const isAdmin = computed(() => roles.value.includes("admin"))
const monthName = computed(() => {
  return new Date().toLocaleString("default", { month: "long" })
})

onBeforeMount(async () => {
  setTitle("Goals")
  await getGoalCount()
  initialLoadComplete.value = true
})

function getPercentage (current: number, goal: number) {
  return Math.round((current / goal) * 100)
}

const getGoalCount = async () => {
  if (working.value) return
  working.value = true

  try {
    const response = await API().post(`/goals/default`, {
      month
    })
    goalCount.value = response.data.data
  } catch (err) {
    console.error(err)
  } finally {
    working.value = false
  }
}

const addGoal = async () => {
  if (working.value) return
  working.value = true

  try {
    const response = await API().get(`/goals/default/add`)
    goalCount.value++
  } catch (err) {
    console.error(err)
  } finally {
    working.value = false
  }
}
</script>

<template>
  <div class="grid grid-cols-1 content-start">
    <ul class="w-full space-y-4 md:space-y-6">
      <li class="space-y-3" v-if="backer">
        <h1 class="text-2xl dark:text-gold-500 text-gold-700 font-bold">Thank You for Standing with Us.</h1>
        <p>Thanks to your generous support, Submit remains a platform where you and others can be yourself, connect freely, and explore openly—all without fear of intrusion or oppression.</p>
        <p>Your backing is essential in helping us maintain a space that prioritizes privacy, respect, and freedom from hate, fostering a safer and more inclusive future for the BDSM, Fetish, and Kinky communities. We’re deeply grateful for your contributions, which allow us to focus on your rights, your safety, and your freedom to express yourself without judgment.</p>
        <p>If you're looking for other ways to support our community, here are a few ideas:</p>
        <ul class="list-disc list-inside">
          <li><strong>Spread the word</strong>: Let others know about Submit and why it's important to you. Building awareness strengthens our mission and brings more voices into the community.</li>
          <li><strong>Be an active member</strong>: Engage, share, and support others. Your involvement helps create the vibrant, supportive environment we envision.</li>
          <li><strong>Gift backing</strong>: You can share this mission by gifting a backing to someone who could benefit from being part of our safe and welcoming space.</li>
        </ul>
        <p>Here's our progress for this month:</p>
      </li>
      <li class="space-y-3" v-if="!backer">
        <h1 class="text-2xl dark:text-gold-500 text-gold-700 font-bold">Stand with Us to Build a Safe, Inclusive Future for the BDSM, Fetish, & Kinky Community</h1>
        <p>Submit is dedicated to creating a safe space where everyone in the BDSM, Fetish, and Kinky communities can freely be themselves, express openly, and connect without fear of judgment, intrusion, or oppression. Our mission is rooted in protecting your privacy, defending your rights, and fostering a truly inclusive environment that respects and celebrates who you are. <router-link class="underline" to="/announcements/a-place-to-be-yourself-protecting-privacy-and-rights-in-uncertain-times">Read more about our commitment to privacy and freedom here</router-link>.</p>
        <p>By becoming a backer, you're helping us build and protect this space, ensuring Submit remains a platform where privacy, freedom, and respect are paramount. To reach our goal of 50 lifetime backers each month, your support is vital—every contribution helps us continue to stand with you and grow this community.</p>
        <p>We’d love to have you join us in supporting this mission. Here's our progress for this month:</p>
      </li>
      <li>
        <div class="dark:bg-submit-900 bg-white p-4 rounded-md space-y-2">
          <h3 class="font-bold">{{ monthName }} Lifetime Backer Goal</h3>
          <figure :class="[
            initialLoadComplete ? '' : 'animate-pulse',
            'dark:bg-submit-950 bg-neutral-125 w-full h-8'
          ]">
            <div class="bg-[#E177A4] h-full" :style="`width: ${ getPercentage(Number(goalCount), 50) }%`"></div>
          </figure>
          <div class="flex justify-between">
            <div class="text-sm font-bold" v-if="initialLoadComplete">{{ goalCount }}/50 lifetime backer sold</div>
            <div class="text-sm font-bold" v-else>Loading...</div>
            <button class="text-sm font-bold text-gold-700 dark:text-gold-500 hover:underline" v-if="isAdmin" @click="addGoal">Add</button>
          </div>
        </div>
      </li>
      <li class="space-y-3 pt-6" v-if="!backer">
        <h1 class="text-2xl dark:text-gold-500 text-gold-700 font-bold">Become a Backer!</h1>
        <p>If you'd like to become a backer and help support Submit and the future of BDSM, Fetish, & Kinky community, you can learn more here: <router-link class="underline" to="/backer">Backing</router-link>. If you'd like to get started now, here's the pricing details!</p>

        <p>You can choose a plan that works best for you: monthly packages or lifetime. Due to varying percentages, certain payment methods have lower price points:</p>

        <TabGroup>
          <TabList class="flex max-w-md mx-auto justify-center space-x-1 p-1">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                  'ring-gold-500/50 ring-offset-2 ring-offset-transparent focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-gold-700 dark:bg-gold-500 text-submit-950 shadow'
                    : 'dark:text-gray-300 hover:bg-gray-200/[0.5] dark:hover:bg-gray-500/[0.12] dark:hover:text-white'
                ]"
              >
                Venmo/Cashapp
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                  'ring-gold-500/50 ring-offset-2 ring-offset-transparent focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-gold-700 dark:bg-gold-500 text-submit-950 shadow'
                    : 'dark:text-gray-300 hover:bg-gray-200/[0.5] dark:hover:bg-gray-500/[0.12] dark:hover:text-white'
                ]"
              >
                Crypto
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                  'ring-gold-500/50 ring-offset-2 ring-offset-transparent focus:outline-none focus:ring-2',
                  selected
                    ? 'bg-gold-700 dark:bg-gold-500 text-submit-950 shadow'
                    : 'dark:text-gray-300 hover:bg-gray-200/[0.5] dark:hover:bg-gray-500/[0.12] dark:hover:text-white'
                ]"
              >
                Patreon
              </button>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div class="grid grid-cols-4 gap-2 dark:text-white">
                <div class="bg-white dark:bg-submit-900 text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6 col-span-4">
                  <span class="line-through"><strong>$6 USD</strong>&nbsp;/month</span> (Not Supported)
                </div>
                <div class="flex flex-col items-center justify-center bg-white dark:bg-submit-900 lg:min-h-[12rem] col-span-2 lg:col-auto text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6">
                  <h3 class="font-bold">$15 USD</h3>
                  <span>/3 months</span>
                </div>
                <div class="flex flex-col items-center justify-center bg-white dark:bg-submit-900 lg:min-h-[12rem] col-span-2 lg:col-auto text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6">
                  <h3 class="font-bold">$32 USD</h3>
                  <span>/6 months</span>
                </div>
                <div class="flex flex-col items-center justify-center bg-white dark:bg-submit-900 lg:min-h-[12rem] col-span-2 lg:col-auto text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6">
                  <h3 class="font-bold">$48 USD</h3>
                  <span>/9 months</span>
                </div>
                <div class="flex flex-col items-center justify-center bg-white dark:bg-submit-900 lg:min-h-[12rem] col-span-2 lg:col-auto text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6">
                  <h3 class="font-bold">$64 USD</h3>
                  <span>/1 year</span>
                </div>
                <div class="bg-white dark:bg-submit-900 text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6 col-span-4">
                  <h3>
                    <span class="font-bold dark:text-gold-500 text-gold-700">$200< USD</span> for Lifetime
                  </h3>
                </div>
              </div>
              <p class="text-center mt-6">
                Venmo (<span class="dark:text-gold-500 text-gold-700 font-bold">@submitgg</span>) and Cashapp (<span class="dark:text-gold-500 text-gold-700 font-bold">$submitgg</span>) are preferred due to lower fees. Simply include your Submit account (or waitlist) email address in the payment comment.
              </p>
            </TabPanel>
            <TabPanel>
              <div class="grid grid-cols-4 gap-2 dark:text-white">
                <div class="bg-white dark:bg-submit-900 text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6 col-span-4">
                  <span class="line-through"><strong>$6 USD</strong>&nbsp;/month</span> (Not Supported)
                </div>
                <div class="flex flex-col items-center justify-center bg-white dark:bg-submit-900 lg:min-h-[12rem] col-span-2 lg:col-auto text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6">
                  <h3 class="font-bold">$18 USD</h3>
                  <span>/3 months</span>
                </div>
                <div class="flex flex-col items-center justify-center bg-white dark:bg-submit-900 lg:min-h-[12rem] col-span-2 lg:col-auto text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6">
                  <h3 class="font-bold">$36 USD</h3>
                  <span>/6 months</span>
                </div>
                <div class="flex flex-col items-center justify-center bg-white dark:bg-submit-900 lg:min-h-[12rem] col-span-2 lg:col-auto text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6">
                  <h3 class="font-bold">$54 USD</h3>
                  <span>/9 months</span>
                </div>
                <div class="flex flex-col items-center justify-center bg-white dark:bg-submit-900 lg:min-h-[12rem] col-span-2 lg:col-auto text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6">
                  <h3 class="font-bold">$72 USD</h3>
                  <span>/1 year</span>
                </div>
                <div class="bg-white dark:bg-submit-900 text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6 col-span-4">
                  <h3>
                    <span class="font-bold dark:text-gold-500 text-gold-700">$240 USD</span> for Lifetime
                  </h3>
                </div>
              </div>
              <p class="text-center mt-6">
                For Bitcoin, send to <code class="break-all">bc1q75erwn3j0xa0gtlc4ekfdj4k6xtzgp2sr4hvl2</code>. For Monero, send to <code class="break-all">4645XosZzqH2vvvcrVwX2PY5YNaRkLB3pawaLK64xkj9dCV1c6NorVdaGLpg8VMEHv6ss8spgUvRRAzp2eThuGYPNjrBbqy</code>. Please email <a class="dark:text-gold-500 text-gold-700 hover:underline" href="mailto:support@submit.gg">support@submit.gg</a> with your transaction ID/details.
              </p>
            </TabPanel>
            <TabPanel>
              <div class="grid grid-cols-4 gap-2 dark:text-white">
                <div class="bg-white dark:bg-submit-900 text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6 col-span-4">
                  <strong>$6 USD</strong>&nbsp;/month
                </div>
                <div class="flex flex-col items-center justify-center bg-white dark:bg-submit-900 lg:min-h-[12rem] col-span-2 lg:col-auto text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6">
                  <h3 class="font-bold">$18 USD</h3>
                  <span>/3 months</span>
                </div>
                <div class="flex flex-col items-center justify-center bg-white dark:bg-submit-900 lg:min-h-[12rem] col-span-2 lg:col-auto text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6">
                  <h3 class="font-bold">$36 USD</h3>
                  <span>/6 months</span>
                </div>
                <div class="flex flex-col items-center justify-center bg-white dark:bg-submit-900 lg:min-h-[12rem] col-span-2 lg:col-auto text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6">
                  <h3 class="font-bold">$54 USD</h3>
                  <span>/9 months</span>
                </div>
                <div class="flex flex-col items-center justify-center bg-white dark:bg-submit-900 lg:min-h-[12rem] col-span-2 lg:col-auto text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6">
                  <h3 class="font-bold">$72 USD</h3>
                  <span>/1 year</span>
                </div>
                <div class="bg-white dark:bg-submit-900 text-center shadow-[0_1px_25px_10px_rgba(0,0,0,0.15)] py-6 col-span-4">
                  <h3>
                    <span class="font-bold dark:text-gold-500 text-gold-700">$240 USD</span> for Lifetime
                  </h3>
                </div>
              </div>
              <p class="text-center font-bold dark:text-gold-500 text-gold-700 hover:underline mt-6">
                <a target="_blank" href="https://patreon.com/submitgg">Become a Backer Today! &rarr;</a>
              </p>
            </TabPanel>
          </TabPanels>
        </TabGroup>

        <p class="text-center text-sm font-semibold">Note: Your backer code will be e-mailed within 24 hours of you backing, usually much faster!</p>
      </li>
    </ul>
  </div>
</template>
