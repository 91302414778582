<script setup lang="ts">
import { reactive, ref, onBeforeMount, onUnmounted, computed } from "vue"
import Editor from "@/components/input/Editor.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import Tags from "@/components/input/Tags.vue"
import { useUserStore } from "@/stores/User"
import { useRouter } from "vue-router"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"

const Router = useRouter()
const UserStore = useUserStore()
const { username, circles } = storeToRefs(UserStore)

interface Props {
  writing?: any
}

const schema = zod.object({
  title: zod.string()
    .min(3, { message: "3 characters is the required minimum for a writing title." }),
  summary: zod.string()
    .min(12, { message: "12 characters is the required minimum for a writing summary." })
    .max(512, { message: "512 characters is the maximum allowed for a writing summary." })
    .optional()
    .or(zod.literal("")),
  collection: zod.string()
    .min(1, { message: "This is required." }),
  visibility: zod.string()
    .min(1, { message: "This is required." }),
  tags: zod.array(zod.string())
    .max(6, { message: "6 tags is the maximum allowed." })
    .optional(),
  html: zod.string()
    .min(50, { message: "50 characters is the required minimum for writing content." })
})

const props = withDefaults(defineProps<Props>(), {
  writing: undefined
})

const initialLoadComplete = ref(false)
const debug = ref(false)
const working = ref(false)
const collections = ref<any[]>([])
const saveType = ref("published")
const published = ref(props.writing ? true : false)
const dontDeleteAttachments = ref(false)
const attachments = ref<string[]>(props.writing ? props.writing.attachments || [] : [])
const error = ref(false)
const errorMessage = ref("")
const initialValues = reactive({
  title: props.writing ? props.writing.title : "",
  summary: props.writing ? props.writing.description : "",
  collection: props.writing && props.writing.collection ? props.writing.collection._id : "none",
  visibility: props.writing ? props.writing.visibility : "public",
  tags: props.writing ? (props.writing.tags ? props.writing.tags : []) : [],
  html: props.writing ? props.writing.html : ""
})

const editorOptions = {
  all: true,
  attachments: true
}

const header = computed(() => {
  if (props.writing) {
    if (props.writing.status === "draft") {
      return "Edit Draft"
    } else {
      return "Edit Writing"
    }
  } else {
    return "New Writing"
  }
})

async function fetchCollections() {
  try {
    const response = await API().get("/collections?type=writing")
    collections.value = response.data.data
  } catch (err) {
    console.error(err)
  }
}

const shouldLockCollection = () => {
  return props.writing ? true : false
}

const shouldLockVisibility = (tools: any) => {
  if (props.writing) return true
  const field = tools.getFieldValue("collection")
  return field !== "none"
}

function audienceDisplay(audience: string) {
  if (audience === "public") return "Everyone"
  if (audience === "followers") return "Followers"
  if (audience === "relationships") return "Relationships"
  // if audience isn't any of the above, check circles and return the matching name
  const circle = circles.value.find((circle: any) => circle.code === audience)
  if (circle) return circle.name
}

const lockedVisibilityValue = (tools: any) => {
  if (props.writing) return props.writing.visibility
  const field = tools.getFieldValue("collection")
  if (!field || field === 'none') return ""
  const collection = collections.value.find(obj => obj._id === field)
  return collection.visibility
}

onBeforeMount(async () => {
  await fetchCollections()
  initialLoadComplete.value = true
})

onUnmounted(() => {
  if (!published.value && attachments.value.length > 0 && !dontDeleteAttachments.value) {
    attachments.value.forEach((attachment) => {
      try {
        API().delete(`/media/attachment/${attachment}`)
      } catch (err) {
        console.error(err)
      }
    })
  }
})

function updateAttachments(newAttachments: string[]) {
  attachments.value = newAttachments
}

async function handleSubmit(values: any) {
  if (working.value) return
  working.value = true

  let data: any = { writing: {
    title: values.title,
    tags: values.tags || [],
    html: values.html,
    visibility: values.visibility,
    status: saveType.value
  }}

  if (attachments.value.length > 0) {
    data.writing.attachments = attachments.value
  }

  if (values.summary && values.summary.length >= 12) data.writing.description = values.summary
  if (values.collection && values.collection !== "none") data.writing.collection = values.collection

  try {
    if (props.writing) {
      const response = await API().put(`/writing/${props.writing._id}`, data)
      dontDeleteAttachments.value = true
      if (saveType.value === 'draft') {
        Router.push(`/${username.value}/writing/drafts`)
      } else {
        const data = response.data.data
        Router.push(`/${username.value}/writing/${props.writing.slug}`)
      }
    } else {
      const response = await API().post("/writing", data)
      dontDeleteAttachments.value = true
      if (saveType.value === 'draft') {
        Router.push(`/${username.value}/writing/drafts`)
      } else {
        const data = response.data.data
        Router.push(`/${username.value}/writing/${data.slug}`)
      }
    }
  } catch (err) {
    console.error(err)
    error.value = true
    working.value = false
    errorMessage.value = "Your writing failed validation."
  }
}

</script>

<template>
  <div>
    <header class="mb-3">
      <h2 class="font-display font-bold text-xl">{{ header }}</h2>
    </header>
    <Form @on-submit="handleSubmit" class="grid grid-cols-2 gap-4" :initial-values="initialValues" :schema="schema" v-slot="{ fields, errors, tools }" v-if="initialLoadComplete">
      <div class="col-span-2">
        <Label field="title">Title</Label>
        <Field name="title" />
      </div>
      <div class="col-span-2">
        <Label field="summary">Summary</Label>
        <Field name="summary" type="textarea" rows="3" />
      </div>
      <div>
        <Label field="collection">Collection</Label>
        <Field name="collection" type="select" :locked="shouldLockCollection()" :locked-value="writing && writing.collection ? writing.collection._id : 'none'" :locked-display="writing && writing.collection ? writing.collection.title : 'None'">
          <optgroup label="No Collection">
            <option value="none">None</option>
          </optgroup>
          <optgroup label="Your Collections" v-if="collections.length > 0">
            <option :value="obj._id" v-for="obj in collections" :key="obj._id">{{obj.title}}</option>
          </optgroup>
        </Field>
      </div>
      <div>
        <Label field="visibility">Audience</Label>
        <Field name="visibility" type="select" :locked="shouldLockVisibility(tools)" :locked-value="lockedVisibilityValue(tools)" :locked-display="audienceDisplay(lockedVisibilityValue(tools))">
          <option value="public">Everyone</option>
          <option value="followers">Followers</option>
          <option value="relationships">Relationships</option>
          <optgroup label="Circles" v-if="circles.length > 0">
            <option v-for="circle in circles" :key="circle._id" :value="circle.code">{{ circle.name }}</option>
          </optgroup>
        </Field>
      </div>
      <div class="col-span-2">
        <Label field="tags">Tags</Label>
        <Tags name="tags" :limit="6" />
      </div>
      <div class="col-span-2">
        <Label field="html">Content</Label>
        <Editor name="html" @attachments="updateAttachments" :attachments="attachments" :options="editorOptions" />
      </div>
      <div class="col-span-2 flex justify-end">
        <button @click="saveType = 'draft'" type="submit" class="text-sm font-bold px-4 py-1.5 dark:text-gray-400 text-gray-500">Save as Draft</button>
        <button type="submit" class="bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 text-sm font-bold" :disabled="working">{{ working ? "Publishing..." : "Publish" }}</button>
      </div>
      <div class="col-span-2 p-4 bg-red-600 rounded-md text-red-950" v-if="error">
        <span class="font-bold block">There was an error saving your writing:</span>
        {{ errorMessage }}
      </div>
      <div class=col-span-2 v-if="debug">
        <pre>
{{ fields }}
        </pre>
        <pre>
{{ errors }}
        </pre>
      </div>
    </Form>
  </div>
</template>
