<script setup lang="ts">
import { inject, onBeforeMount, onMounted, ref } from "vue"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const UserStore = useUserStore()
const { id } = storeToRefs(UserStore)

const profileData: any = inject("profileData")

const trophyCase = ref<any[]>(profileData.value.achievements ?? [])
const achievements = ref<any[]>([])
const achievementsLoading = ref(true)

const selectedLevels = ref<Record<string, number>>({})

onBeforeMount(() => {
  document.title = `${profileData.value.username}'s Achievements - Submit`
})

onMounted(async () => {
  try {
    const res = await API().get(`/users/${profileData.value._id}/achievements`)
    achievements.value = res.data.data
    achievements.value.forEach(achievement => {
      if (achievement.totalLevels?.length > 1) {
        // Find the highest earned level (if any)
        const highestEarnedLevel = Math.max(
          ...achievement.earnedLevels?.map((earned: any) => earned.level - 1) ?? [-1]
        )
        // Set to highest earned level, or 0 if none earned
        selectedLevels.value[achievement._id] = Math.max(0, highestEarnedLevel)
      }
    })
  } catch (err) {
    console.error(err)
  } finally {
    achievementsLoading.value = false
  }
})

function checkIfInTrophyCase(achievement: string) {
  return trophyCase.value.includes(achievement) ?? false
}

function addToTrophyCase(achievement: string) {
  trophyCase.value.push(achievement)
  if (!profileData.value.achievements) {
    profileData.value.achievements = []
  }
  profileData.value.achievements.push(achievement)
  API().post(`/users/${profileData.value._id}/trophycase`, { achievement })
    .catch((err) => {
      console.error(err)
      trophyCase.value = trophyCase.value.filter((a: string) => a !== achievement)
      profileData.value.achievements = profileData.value.achievements.filter((a: string) => a !== achievement)
    })
}

function removeFromTrophyCase(achievement: string) {
  trophyCase.value = trophyCase.value.filter((a: string) => a !== achievement)
  profileData.value.achievements = profileData.value.achievements.filter((a: string) => a !== achievement)
  API().delete(`/users/${profileData.value._id}/trophycase`, { data: { achievement } })
    .catch((err) => {
      console.error(err)
      trophyCase.value.push(achievement)
      profileData.value.achievements.push(achievement)
    })
}
</script>

<template>
  <section class="px-2">
    <div class="container mx-auto Achievements">
      <ul class="grid gap-4">
        <li class="p-4 dark:bg-submit-900 bg-white rounded-lg flex gap-4 items-center" v-for="achievement in achievements" :key="achievement._id" v-if="!achievementsLoading && achievements.length > 0">
          <figure class="flex-none">
            <img
              class="h-16"
              :class="{ 'grayscale': !achievement.earnedAt && (!achievement.totalLevels?.length || !achievement.earnedLevels?.length) }"
              :src="achievement.badgeUrl"
              :alt="achievement.name"
              v-if="achievement.badgeUrl"
            />
            <img
              class="h-16"
              :class="{ 'grayscale': !achievement.earnedAt && (!achievement.totalLevels?.length || !achievement.earnedLevels?.length) }"
              src="https://assets.submit.gg/achievements/NoBadge.png"
              alt="Default achievement badge"
              v-else
            />
          </figure>
          <div class="flex-1">
            <header class="flex w-full justify-between items-center">
              <h3 class="font-bold">
                {{ achievement.name }}
                <span class="dark:text-gray-400 font-normal text-sm">
                  ({{ achievement.points }}<template v-if="achievement.totalLevels?.length > 1">/{{ achievement.totalLevels.reduce((sum: number, level: any) => sum + level.points, 0) }}</template> points)
                </span>
              </h3>
              <button
                class="flex items-center gap-2 text-sm"
                v-if="id === profileData._id &&
                (achievement.earnedAt || (achievement.totalLevels?.length && achievement.earnedLevels?.length)) &&
                (checkIfInTrophyCase(achievement._id) || trophyCase.length < 4)"
                @click="checkIfInTrophyCase(achievement._id) ? removeFromTrophyCase(achievement._id) : addToTrophyCase(achievement._id)"
              >
                <svg
                  class="w-3.5 dark:fill-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                >
                  <path d="M400 0L176 0c-26.5 0-48.1 21.8-47.1 48.2c.2 5.3 .4 10.6 .7 15.8L24 64C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7c44.3 43.1 98.3 64.8 138.1 75.8c23.4 6.5 39.4 26 39.4 45.6c0 20.9-17 37.9-37.9 37.9L192 448c-17.7 0-32 14.3-32 32s14.3 32 32 32l192 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-26.1 0C337 448 320 431 320 410.1c0-19.6 15.9-39.2 39.4-45.6c39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24L446.4 64c.3-5.2 .5-10.4 .7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112l84.4 0c9.1 90.1 29.2 150.3 51.9 190.6c-24.9-11-50.8-26.5-73.2-48.3c-32-31.1-58-76-63-142.3zM464.1 254.3c-22.4 21.8-48.3 37.3-73.2 48.3c22.7-40.3 42.8-100.5 51.9-190.6l84.4 0c-5.1 66.3-31.1 111.2-63 142.3z"/>
                </svg>
                <span class="hidden md:inline">
                  {{ checkIfInTrophyCase(achievement._id) ? 'Remove from trophy case' : 'Add to trophy case' }}
                </span>
              </button>
            </header>
            <p>{{ achievement.description }}</p>
            <p class="text-xs dark:text-gray-500" v-if="!achievement.totalLevels || achievement.totalLevels.length === 0">
              Earned <time :title="D(achievement.earnedAt).format('LLLL')">{{ D(achievement.earnedAt).fromNow() }}</time>
            </p>
            <ul class="mt-2 text-sm" v-if="achievement.totalLevels && achievement.totalLevels.length > 1">
              <div class="flex flex-wrap gap-4 mb-2">
                <li
                  v-for="(level, index) in achievement.totalLevels"
                  :key="`title-${level.title}`"
                  :class="{
                    'opacity-100': achievement.earnedLevels?.some((earned: any) => earned.level === index + 1),
                    'opacity-50': !achievement.earnedLevels?.some((earned: any) => earned.level === index + 1),
                    'underline': selectedLevels[achievement._id] === index
                  }"
                  class="cursor-pointer"
                  @click="selectedLevels[achievement._id] = index"
                >
                  {{ level.title }}
                </li>
              </div>
              <div v-if="selectedLevels[achievement._id] !== undefined">
                <p>{{ achievement.totalLevels[selectedLevels[achievement._id]].description }}</p>
                <p class="text-xs dark:text-gray-500" v-if="achievement.earnedLevels?.some((earned: any) => earned.level === selectedLevels[achievement._id] + 1)">
                  Earned <time :title="D(achievement.earnedLevels?.find((earned: any) => earned.level === selectedLevels[achievement._id] + 1)?.earnedAt).format('LLLL')">{{ D(achievement.earnedLevels?.find((earned: any) => earned.level === selectedLevels[achievement._id] + 1)?.earnedAt).fromNow() }}</time>
                </p>
                <p class="text-xs dark:text-gray-500" v-else>Not earned, yet.</p>
              </div>
            </ul>
          </div>
        </li>
        <li v-if="!achievementsLoading && achievements.length === 0">
          No achievements earned, yet!
        </li>
        <li v-if="achievementsLoading">
          Loading achievements...
        </li>
      </ul>
    </div>
  </section>
</template>
