<script setup lang="ts">
import { inject, onBeforeMount, onMounted, ref, nextTick } from "vue"
import Editor from "@/components/input/Editor.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"

const schema = zod.object({
  title: zod.string()
    .min(3, { message: "Minimum 3 characters." }),
  focus: zod.string()
    .min(3, { message: "Minimum 3 characters." }),
  version: zod.string()
    .min(3, { message: "Minimum 3 characters." }),
  createdAt: zod.string()
    .optional(),
  html: zod.string()
    .min(3, { message: "Minimum 3 characters." })
})

const setTitle = inject("setTitle") as (title: string) => void

const User = useUserStore()
const { roles } = storeToRefs(User)

const initialLoadComplete = ref(false)
const working = ref(false)
const posts = ref<any[]>([])
const pageInfo = ref<any>({
  endCursor: undefined
})
const error = ref(false)
const editorOpen = ref(false)

function toggleEditor() {
  editorOpen.value = !editorOpen.value
}

onBeforeMount(async () => {
  setTitle("Changelog")
})

onMounted(async () => {
  await getChangelog()
  initialLoadComplete.value = true
  await nextTick()
  let target = document.querySelector('#loadMore')
  observer.observe(target as any)
})

async function getChangelog() {
  if (working.value) return
  working.value = true

  let url: string = `/changelog`

  if (pageInfo.value.endCursor) {
    url += `?${new URLSearchParams({ cursor: pageInfo.value.endCursor }).toString()}`
  }

  try {
    const response = await API().get(url)
    const data = response.data
    posts.value.push(...data.data)
    pageInfo.value = data.pageInfo
    working.value = false
  } catch (err) {
    error.value = true
    working.value = false
  }
}

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
}

async function addPost(values: any) {
  if (working.value) return
  working.value = true

  const payload: any = {
    title: values.title,
    focus: values.focus,
    version: values.version,
    html: values.html
  }

  if (values.createdAt) {
    payload.createdAt = values.createdAt
  }

  try {
    await API().post(`/changelog`, payload)
    working.value = false
    // add the new post to the top of the list
    posts.value.unshift(payload)
  } catch (err) {
    error.value = true
    working.value = false
  }
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        getChangelog()
      }
    }
  })
}, options)
</script>

<template>
  <div class="grid grid-cols-1 content-start">
    <header class="flex justify-between items-center text-sm font-bold">
      <div>Changelog Updates</div>
      <div class="flex gap-2">
        <button @click="toggleEditor" v-if="roles.includes('admin')" class="flex border hover:border-neutral-400 dark:border-gray-800 dark:hover:border-gray-700 rounded-md items-center bg-white dark:bg-submit-900 py-1.5 px-3 gap-2 text-sm group">
          <svg class="flex-none h-3 dark:fill-gray-400 dark:group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
        </button>
      </div>
    </header>
    <div class="mt-4" v-if="editorOpen">
      <Form @on-submit="addPost" class="grid grid-cols-2 gap-2" :schema="schema">
        <div>
          <Label field="title">Title</Label>
          <Field name="title" />
        </div>
        <div>
          <Label field="focus">Focus</Label>
          <Field name="focus" />
        </div>
        <div>
          <Label field="version">Version</Label>
          <Field name="version" />
        </div>
        <div>
          <Label field="createdAt">Created At</Label>
          <Field name="createdAt" subtype="datetime-local" />
        </div>
        <div class="col-span-2">
          <Label field="html">Body</Label>
          <Editor name="html" />
        </div>
        <div>
          <button type="submit" class="bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5">{{ working ? 'Adding...' : 'Add Post' }}</button>
        </div>
      </Form>
    </div>
    <main class="mt-4" v-if="initialLoadComplete">
      <ul class="space-y-6">
        <li v-for="post in posts" :key="post._id" :data-version="post.version">
          <header class="mb-4">
            <h1 class="font-display text-xl font-bold mb-0.5">{{ post.title }}</h1>
            <h2 class="text-sm dark:text-gray-500">Released {{ D(post.createdAt).format('LLLL') }}</h2>
          </header>
          <section class="CommonBody ChangeLog" v-html="post.html"></section>
        </li>
        <li class="text-sm font-bold" v-if="posts.length === 0">
          There are no changelog updates, yet.
        </li>
      </ul>
      <div id="loadMore"></div>
    </main>
    <main class="mt-4 text-sm font-bold" v-else>
      Loading...
    </main>
  </div>
</template>
