<script setup lang="ts">
import { ref, inject, onBeforeMount, computed, reactive, watchEffect, type Ref } from "vue"
import CustomArray from "@/components/input/CustomArray.vue"
import BoardObject from "@/components/input/BoardObject.vue"
import RuleObject from "@/components/input/RuleObject.vue"
import SelfSubmit from "@/components/input/SelfSubmit.vue"
import TypeAhead from "@/components/input/Typeahead.vue"
import Location from "@/components/input/Location.vue"
import Editor from "@/components/input/Editor.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { useRouter } from "vue-router"
import { v4 as uuid } from "uuid"
import API from "@/api/api"
import * as zod from "zod"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

const Router = useRouter()

const Group = inject("Group") as Ref<any>
const { hasPersonals, setHasPersonals } = inject("hasPersonals") as { hasPersonals: Ref<boolean>, setHasPersonals: (value: boolean) => void }
const { personalsOnly, setPersonalsOnly } = inject("personalsOnly") as { personalsOnly: Ref<boolean>, setPersonalsOnly: (value: boolean) => void }
const { multiBoard, setMultiBoard } = inject("multiBoard") as { multiBoard: Ref<boolean>, setMultiBoard: (value: boolean) => void }
const { boards, setBoards } = inject("boards") as { boards: Ref<any[]>, setBoards: (value: any[]) => void }

const baseRoute = `/groups/g/${Group.value._id}`

const detailsError = ref(false)
const detailsSuccess = ref(false)
const detailsButtonLabel = ref("Save Details")

const hasPersonalsField = ref(hasPersonals.value ? "true" : "false")
const personalsOnlyField = ref(personalsOnly.value ? "true" : "false")

const hasMultiBoardField = ref(multiBoard.value ? "true" : "false")

watchEffect(() => {
  setHasPersonals(hasPersonalsField.value === "true")
  setPersonalsOnly(personalsOnlyField.value === "true")
  setMultiBoard(hasMultiBoardField.value === "true")
})

const edOptions = [
  {text: "Enabled", value: "true"},
  {text: "Disabled", value: "false"}
]

const rulesError = ref(false)
const rulesSuccess = ref(false)
const rulesButtonLabel = ref("Save Group Rules")

const postRulesButtonLabel = ref("Save Post Rules")
const postRulesError = ref(false)
const postRulesSuccess = ref(false)

const personalsRulesButtonLabel = ref("Save Personals Rules")
const personalsRulesError = ref(false)
const personalsRulesSuccess = ref(false)

const rulesData = reactive({
  rules: []
})

const debug = ref(false)

const initialDetails = reactive({
	restrictions: "",
  summary: "",
  desc: ""
})

const initialInterest = reactive({
  interest: ""
})

const initialLocation = ref({
  location: {
    place_name_en: undefined
  }
})

const initialRules = reactive<{ rules: any[] }>({
  rules: []
})

const initialBoards = reactive<{ boards: any[] }>({
  boards: []
})

const initialPostRules = reactive({
  postingRules: "",
  editingRules: ""
})

const initialPersonalsRules = reactive({
  personalsRules: "",
  personalsEditingRules: ""
})

const locationSet = computed(() => {
  return initialLocation.value.location.place_name_en ? true : false
})

const modalOpen = ref(false)
const working = ref(false)

const error = ref(false)
const errorMessage = ref("There was an error performing this action, please try again.")
const errorCode = ref("NEC")

const buttonLabel = computed(() => {
  if (working.value) return "Processing..."
  return "Delete Group"
})

const interestError = ref(false)
const interestSuccess = ref(false)
const interestButtonLabel = ref("Save Interest")

const locationError = ref(false)
const locationSuccess = ref(false)
const locationButtonLabel = ref("Save Location")

const boardError = ref(false)
const boardSuccess = ref(false)
const boardButtonLabel = ref("Save Boards")

onBeforeMount(async () => {
  setupInitialValues(Group.value)
})

function setupInitialValues(group: any) {
  if (group.restrictions) {
    initialDetails.restrictions = group.restrictions
  }
  initialDetails.desc = group.desc
  initialDetails.summary = group.summary
  if (group.rules && group.rules.length > 0) {
    rulesData.rules = group.rules
    initialRules.rules = group.rules
    // add id to reach rule
    rulesData.rules.forEach((rule: any) => {
      rule.id = uuid()
    })
  }
  if (group.postingRules) {
    initialPostRules.postingRules = group.postingRules
  }
  if (group.editingRules) {
    initialPostRules.editingRules = group.editingRules
  }
  if (group.personalsRules) {
    initialPersonalsRules.personalsRules = group.personalsRules
  }
  if (group.personalsEditingRules) {
    initialPersonalsRules.personalsEditingRules = group.personalsEditingRules
  }
  if (group.location) {
    initialLocation.value.location.place_name_en = group.location.place_name_en
  }
  if (group.interest) {
    initialInterest.interest = group.interest
  }
  if (boards.value && boards.value.length > 0) {
    initialBoards.boards = boards.value
  }
}

const detailsSchema = zod.object({
  restrictions: zod.string().optional(),
  summary: zod.string().min(4, { message: 'Too short' }),
  desc: zod.string().min(10, { message: 'Too short' })
})

const interestSchema = zod.object({
  interest: zod.object({
    name: zod.string().optional()
  }, { invalid_type_error: ""}).nullable()
})

const locationSchema = zod.object({
  location: zod.object({
    place_name_en: zod.string()
      .min(1, { message: "This is required." }),
  })
})

const rulesSchema = zod.object({
  rules: zod.array(zod.object({
    title: zod.string().min(4, { message: 'Too short' }),
    desc: zod.string().min(10, { message: 'Too short' })
  }))
})

const boardSchema = zod.object({
  boards: zod.array(zod.object({
    name: zod.string().min(4, { message: 'Too short' }),
    slug: zod.string().min(4, { message: 'Too short' }),
    isDefault: zod.boolean(),
    canPost: zod.array(zod.string()),
    postQueue: zod.array(zod.string())
  }))
})

const postRulesSchema = zod.object({
  postingRules: zod.string().max(256, { message: 'Too long' }).optional(),
  editingRules: zod.string().max(256, { message: 'Too long' }).optional()
})

const personalsRulesSchema = zod.object({
  personalsRules: zod.string().max(256, { message: 'Too long' }).optional(),
  personalsEditingRules: zod.string().max(256, { message: 'Too long' }).optional()
})

function toggleModal() {
  modalOpen.value = !modalOpen.value
}

async function detailsSubmit(values: any) {
  if (working.value) return
  working.value = true
  detailsButtonLabel.value = "Saving..."
  try {
    const dataToSend: any = {
      summary: values.summary,
      desc: values.desc,
    }
    Group.value.summary = values.summary
    Group.value.desc = values.desc
    if (values.restrictions.length > 0) {
      dataToSend.restrictions = values.restrictions
      Group.value.restrictions = values.restrictions
    }

    await API().put(`${baseRoute}`, dataToSend)
    detailsButtonLabel.value = "Save Details"
    detailsSuccess.value = true
  } catch (err) {
    if (err) console.error(err)
    detailsError.value = true
    detailsButtonLabel.value = "Save Details"
    setTimeout(() => {
      detailsError.value = false
    }, 5000)
  }
}

async function interestSubmit(values: any) {
  interestButtonLabel.value = "Saving..."
  try {
    const dataToSend: any = {
      interest: values.interest ? values.interest._id : null
    }

    await API().put(`${baseRoute}`, dataToSend)
    interestButtonLabel.value = "Save Interest"
    interestSuccess.value = true
  } catch (err) {
    if (err) console.error(err)
    interestError.value = true
    interestButtonLabel.value = "Save Interest"
    setTimeout(() => {
      interestError.value = false
    }, 5000)
  }
}

async function locationSubmit(values: any) {
  if (working.value) return
  working.value = true
  locationButtonLabel.value = "Saving..."
  try {
    let dataToSend = <any>{
      location: values.location
    }

    await API().put(`${baseRoute}`, dataToSend)
    locationButtonLabel.value = "Save Location"
    locationSuccess.value = true
    initialLocation.value.location.place_name_en = values.location.place_name_en
    setTimeout(() => {
      locationSuccess.value = false
    }, 5000)
    working.value = false
  } catch (err) {
    if (err) console.error(err)
    locationError.value = true
    locationButtonLabel.value = "Save Location"
    working.value = false
    setTimeout(() => {
      locationError.value = false
    }, 5000)
  }
}

async function clearLocation() {
  if (working.value) return
  working.value = true
  try {
    await API().put(`${baseRoute}`, { location: null })
    initialLocation.value.location.place_name_en = undefined
    working.value = false
    locationSuccess.value = true
    setTimeout(() => {
      locationSuccess.value = false
    }, 5000)
  } catch (err) {
    if (err) console.error(err)
    locationError.value = true
    working.value = false
    setTimeout(() => {
      locationError.value = false
    }, 5000)
  }
}

async function rulesSubmit(values: any) {
  rulesButtonLabel.value = "Saving..."
  try {
    const dataToSend: any = {
      rules: values.rules
    }

    await API().put(`${baseRoute}`, dataToSend)
    rulesButtonLabel.value = "Save Rules"
    rulesSuccess.value = true
    Group.value.rules = values.rules
    setTimeout(() => {
      rulesSuccess.value = false
    }, 5000)
  } catch (err) {
    if (err) console.error(err)
    rulesError.value = true
    rulesButtonLabel.value = "Save Rules"
    setTimeout(() => {
      rulesError.value = false
    }, 5000)
  }
}

async function boardSubmit(values: any) {
  boardButtonLabel.value = "Saving..."
  try {
    const dataToSend: any = {
      boards: values.boards
    }

    await API().put(`${baseRoute}`, dataToSend)
    boardButtonLabel.value = "Save Boards"
    boardSuccess.value = true
    setBoards(values.boards)
    setTimeout(() => {
      boardSuccess.value = false
    }, 5000)
  } catch (err) {
    if (err) console.error(err)
    boardError.value = true
    boardButtonLabel.value = "Save Boards"
    setTimeout(() => {
      boardError.value = false
    }, 5000)
  }
}

async function postRulesSubmit(values: any) {
  postRulesButtonLabel.value = "Saving..."
  try {
    const dataToSend: any = {}
    if(values.postingRules.length > 0) {
      dataToSend.postingRules = values.postingRules
    }
    if(values.editingRules.length > 0) {
      dataToSend.editingRules = values.editingRules
    }

    await API().put(`${baseRoute}`, dataToSend)

    if (dataToSend.postingRules) {
      Group.value.postingRules = dataToSend.postingRules
    }
    if (dataToSend.editingRules) {
      Group.value.editingRules = dataToSend.editingRules
    }

    postRulesButtonLabel.value = "Save Post Rules"
    postRulesSuccess.value = true
  } catch (err) {
    if (err) console.error(err)
    postRulesError.value = true
    postRulesButtonLabel.value = "Save Post Rules"
    setTimeout(() => {
      postRulesError.value = false
    }, 5000)
  }
}

async function personalRulesSubmit(values: any) {
  personalsRulesButtonLabel.value = "Saving..."
  try {
    const dataToSend: any = {}
    if(values.personalsRules.length > 0) {
      dataToSend.personalsRules = values.personalsRules
    }
    if(values.personalsEditingRules.length > 0) {
      dataToSend.personalsEditingRules = values.personalsEditingRules
    }

    await API().put(`${baseRoute}`, dataToSend)

    if (dataToSend.personalsRules) {
      Group.value.personalsRules = dataToSend.personalsRules
    }
    if (dataToSend.personalsEditingRules) {
      Group.value.personalsEditingRules = dataToSend.personalsEditingRules
    }

    personalsRulesButtonLabel.value = "Save Personals Rules"
    personalsRulesSuccess.value = true
  } catch (err) {
    if (err) console.error(err)
    personalsRulesError.value = true
    personalsRulesButtonLabel.value = "Save Personals Rules"
    setTimeout(() => {
      personalsRulesError.value = false
    }, 5000)
  }
}

async function deleteGroup() {
  working.value = true
  try {
    await API().delete(`${baseRoute}`)
    Router.push("/groups")
  } catch (err) {
    if (err) console.error(err)
    error.value = true
    working.value = false
    setTimeout(() => {
      error.value = false
    }, 5000)
  }
}
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <section class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Group Details</h2>
        <p>Configure and update the core details for this group.</p>
      </section>

      <Form @on-submit="detailsSubmit" :initial-values="initialDetails" :schema="detailsSchema" class="space-y-3" v-slot="{ fields, errors }">
        <div>
          <div class="text-sm font-bold dark:text-white mb-1">Name</div>
          <div class="w-full text-sm rounded-md bg-white dark:bg-submit-900 outline-none py-4 px-4 border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30">{{ Group.name }}</div>
          <p class="mt-2 text-xs text-gray-500 dark:text-gray-400">
            Group names cannot be changed.
          </p>
        </div>

        <div>
          <Label field="restrictions">Group Restricted to</Label>
          <Field name="restrictions" help-text="Leave this blank if it's open to anyone/everyone." />
        </div>

        <div>
          <Label field="summary">Summary</Label>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Displayed in the public group listing. Limited to 256 characters, doesn't support formatting. Visible to all Submit users.</p>
          <Field name="summary" type="textarea" rows="4" />
        </div>

        <div>
          <Label field="desc">Description</Label>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Displayed in the About section of the Group. Visible to all Submit users.</p>
          <Editor name="desc"/>
        </div>

        <div>
          <div class="text-sm font-bold dark:text-white mb-1">Type</div>
          <div class="w-full text-sm rounded-md bg-white dark:bg-submit-900 outline-none py-4 px-4 border-0 focus:ring-0 focus:border-0 focus:outline-indigo-325/30 capitalize">{{ Group.type }}</div>
          <p class="mt-2 text-xs text-gray-500 dark:text-gray-400">
            Group type cannot be changed currently.
          </p>
        </div>

        <div class="pt-3">
          <button type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 font-semibold" :disabled="working">{{ detailsButtonLabel }}</button>
        </div>

        <div v-if="detailsError" class="text-red-500 font-bold">
          <p>There was an error saving your changes.</p>
        </div>
        <div v-if="detailsSuccess" class="text-green-500 font-bold">
          <p>Your changes were saved successfully.</p>
        </div>

        <section class="space-y-3 mt-6" v-if="debug">
          <h3 class="font-bold">Debug</h3>

          <div>
            <div class="block mb-1 text-sm font-bold">Registered Fields</div>
            <pre>
{{ fields }}
            </pre>
          </div>
          <div>
            <div class="block mb-1 text-sm font-bold">Errors</div>
            <pre>
{{ errors }}
            </pre>
          </div>
        </section>
      </Form>
    </section>

    <section class="space-y-6">
      <section class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Group Kink or Interest</h2>
        <p>If your group is focused on a specific kink or interest, you can configure that here so it'll show up in the kink or interest's groups listing.</p>
      </section>
      <Form @on-submit="interestSubmit" :initial-values="initialInterest" :schema="interestSchema" v-slot="{ fields }">
        <TypeAhead name="interest" help-text="Currently limited to 1 kink or interest." />
        <div class="pt-3">
          <button type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 font-semibold" :disabled="working">{{ interestButtonLabel }}</button>
        </div>
        <div v-if="interestError" class="text-red-500 font-bold">
          <p>There was an error saving your changes.</p>
        </div>
        <div v-if="interestSuccess" class="text-green-500 font-bold">
          <p>Your changes were saved successfully.</p>
        </div>
        <div v-if="debug">
          <pre class="whitespace-pre-wrap">
{{ fields }}
          </pre>
        </div>
      </Form>
    </section>

    <section class="space-y-6">
      <section class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Group Location</h2>
        <p>If your group is location-specifc, set a location so it can show up in location-based searches and recommendations.</p>
      </section>
      <Form @on-submit="locationSubmit" :initial-values="initialLocation" :schema="locationSchema" v-slot="{ fields, errors }" class="space-y-3">
        <Location name="location" :is-set="locationSet" help-text="The ability to choose a region, province, state, or country is coming soon!" />
        <div class="pt-3 flex gap-4">
          <button type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 font-semibold" :disabled="working">{{ locationButtonLabel }}</button>
          <button v-if="locationSet" @click="clearLocation" type="button" class="text-sm px-4 py-1.5 font-semibold">Clear Location</button>
        </div>
        <div v-if="locationError" class="text-red-500 font-bold">
          <p>There was an error saving your changes.</p>
        </div>
        <div v-if="locationSuccess" class="text-green-500 font-bold">
          <p>Your changes were saved successfully.</p>
        </div>
        <div v-if="debug">
          <pre class="whitespace-pre-wrap">
{{ fields }}
          </pre>
        </div>
      </Form>
    </section>

    <section class="space-y-6">
      <section class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Group Rules</h2>
        <p>Create custom rules for the group.</p>
      </section>
      <Form @on-submit="rulesSubmit" :schema="rulesSchema" :initial-values="initialRules" v-slot="{ fields, errors }" class="space-y-3">
        <section>
          <CustomArray name="rules" :limit="12" label="Rules" :cmpnt="RuleObject" add-label="Add Rule" />
        </section>

        <div class="pt-3">
          <button type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 font-semibold" :disabled="working">{{ rulesButtonLabel }}</button>
        </div>

        <div v-if="rulesError" class="text-red-500 font-bold">
          <p>There was an error saving your changes.</p>
        </div>
        <div v-if="rulesSuccess" class="text-green-500 font-bold">
          <p>Your changes were saved successfully.</p>
        </div>

        <section class="space-y-3" v-if="debug">
          <h3 class="font-bold">Debug</h3>
          <div>
            <div class="block mb-1 text-sm font-bold">Registered Fields</div>
            <pre>
{{ fields }}
            </pre>
          </div>
          <div>
            <div class="block mb-1 text-sm font-bold">Errors</div>
            <pre>
{{ errors }}
            </pre>
          </div>
        </section>
      </Form>
    </section>

    <section class="space-y-6" v-if="!personalsOnly">
      <section class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Post Rules</h2>
        <p>Set rules to be displayed when group members create new posts and edit existing ones. Each rule is limited to 256 characters.</p>
      </section>

      <Form @on-submit="postRulesSubmit" :schema="postRulesSchema" :initial-values="initialPostRules" class="space-y-3" v-slot="{ fields, errors }">

        <div>
          <Label field="postingRules">New Post Rules</Label>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Displayed any time a group member creates a new post.</p>
          <Field name="postingRules" type="textarea" rows="3" max-length="256" />
        </div>

        <div>
          <Label field="editingRules">Post Editing Rules</Label>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Displayed any time a group member edits an existing post.</p>
          <Field name="editingRules" type="textarea" rows="3" max-length="256" />
        </div>

        <div class="pt-3">
          <button type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 font-semibold" :disabled="working">{{ postRulesButtonLabel }}</button>
        </div>

        <div v-if="postRulesError" class="text-red-500 font-bold">
          <p>There was an error saving your changes.</p>
        </div>
        <div v-if="postRulesSuccess" class="text-green-500 font-bold">
          <p>Your changes were saved successfully.</p>
        </div>

        <section class="space-y-3" v-if="debug">
          <h3 class="font-bold">Debug</h3>
          <div>
            <div class="block mb-1 text-sm font-bold">Registered Fields</div>
            <pre>
{{ fields }}
            </pre>
          </div>
          <div>
            <div class="block mb-1 text-sm font-bold">Errors</div>
            <pre>
{{ errors }}
            </pre>
          </div>
        </section>
      </Form>
    </section>

    <section class="space-y-6" v-if="Group.type !== 'official'">
      <section class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Personals</h2>
        <p>Add a separate discussion board for group members to post personals. This lets you keep your main discussions board clear of personals and on-topic. You can also set your group to be only a personals group, with no regular discussion board.</p>
      </section>

      <section class="space-y-3">
        <div>
          <Label field="hasPersonals">Personals Board</Label>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Enable or disable the personals discussion board for this group. If disabling a personals board, all posts will be removed permanently.</p>
          <SelfSubmit v-model="hasPersonalsField" name="hasPersonals" :initialValue="hasPersonalsField" :apiEndpoint="baseRoute" :options="edOptions" method="PUT" />
        </div>
        <div>
          <Label field="personalsOnly">Personals Only</Label>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Enable personals only to remove the general discussion board. Any posts that exist on the general discussion board will be preserved.</p>
          <SelfSubmit v-model="personalsOnlyField" name="personalsOnly" :initialValue="personalsOnlyField" :apiEndpoint="baseRoute" :options="edOptions" method="PUT" :disabled="!hasPersonals" />
        </div>
      </section>
    </section>

    <section class="space-y-6" v-if="hasPersonals">
      <section class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Personals Post Rules</h2>
        <p>Set rules to be displayed when group members create new personals posts and edit existing ones. Each rule is limited to 256 characters.</p>
      </section>

      <Form @on-submit="personalRulesSubmit" :schema="personalsRulesSchema" :initial-values="initialPersonalsRules" class="space-y-3" v-slot="{ fields, errors }">

        <div>
          <Label field="personalsRules">New Personals Post Rules</Label>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Displayed any time a group member creates a new personals post.</p>
          <Field name="personalsRules" type="textarea" rows="3" max-length="256" />
        </div>

        <div>
          <Label field="personalsEditingRules">Personals Editing Rules</Label>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Displayed any time a group member edits an existing personals post.</p>
          <Field name="personalsEditingRules" type="textarea" rows="3" max-length="256" />
        </div>

        <div class="pt-3">
          <button type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 font-semibold" :disabled="working">{{ personalsRulesButtonLabel }}</button>
        </div>

        <div v-if="personalsRulesError" class="text-red-500 font-bold">
          <p>There was an error saving your changes.</p>
        </div>
        <div v-if="personalsRulesSuccess" class="text-green-500 font-bold">
          <p>Your changes were saved successfully.</p>
        </div>

        <section class="space-y-3" v-if="debug">
          <h3 class="font-bold">Debug</h3>
          <div>
            <div class="block mb-1 text-sm font-bold">Registered Fields</div>
            <pre>
{{ fields }}
            </pre>
          </div>
          <div>
            <div class="block mb-1 text-sm font-bold">Errors</div>
            <pre>
{{ errors }}
            </pre>
          </div>
        </section>
      </Form>
    </section>

    <section class="space-y-6" v-if="Group.type === 'official'">
      <section class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Multi-Board Settings</h2>
        <p>Enable multi-board functionality for this group. This allows you to create multiple boards within the group, each with their own settings.</p>
      </section>
      <section class="space-y-3">
        <div>
          <Label field="hasMultiBoard">Multi Board</Label>
          <p class="text-sm text-gray-500 dark:text-gray-400 mb-2">Enable or disable the multi-board functionality for this group.</p>
          <SelfSubmit v-model="hasMultiBoardField" name="isMultiBoard" :initialValue="hasMultiBoardField" :apiEndpoint="baseRoute" :options="edOptions" method="PUT" />
        </div>
        <Form @on-submit="boardSubmit" :schema="boardSchema" :initial-values="initialBoards" class="space-y-3" v-slot="{ fields, errors }" v-if="multiBoard">
          <CustomArray name="boards" :limit="4" label="Boards" :cmpnt="BoardObject" add-label="Add Board" />
          <div>
            <button type="submit" class="text-sm bg-gold-700 dark:bg-gold-500 text-black rounded-md px-4 py-1.5 font-semibold" :disabled="working">{{ boardButtonLabel }}</button>
          </div>
          <div v-if="boardError" class="text-red-500 font-bold">
            <p>There was an error saving your changes.</p>
          </div>
          <div v-if="boardSuccess" class="text-green-500 font-bold">
            <p>Your changes were saved successfully.</p>
          </div>
          <section class="space-y-3" v-if="debug">
            <h3 class="font-bold">Debug</h3>
            <div>
              <div class="block mb-1 text-sm font-bold">Registered Fields</div>
              <pre>
{{ fields }}
              </pre>
            </div>
          </section>
        </Form>
      </section>
    </section>

    <section class="space-y-6">
      <section class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Ownership Transfer</h2>
        <p>If you're no longer interested in running this group, you can send a group transfer request to another member (or moderator) of the group. Currently ownership transfers are disabled but will be enabled shortly.</p>
      </section>
    </section>

    <section class="space-y-6">
      <section class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Group Deletion</h2>
        <p>Deletion is permanent, be careful with this! Deleting this group will permanently remove all posts, comments, and any associated data with no chance of recovery.</p>
        <button @click="toggleModal" class="font-display font-bold rounded-md bg-red-500 text-black py-2 px-12">Delete Group</button>
      </section>
      <TransitionRoot appear :show="modalOpen" as="template">
        <Dialog as="div" @close="toggleModal" class="relative z-[100]">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>
          <div class="fixed inset-0 overflow-y-auto">
            <div
              class="flex min-h-full items-center justify-center p-4 text-center"
            >
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <DialogPanel
                  class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                >
                  <DialogTitle
                    as="h3"
                    class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                  >
                    Delete Group?
                  </DialogTitle>
                  <div class="flex flex-col mt-2 space-y-4">
                    <p class="text-sm">Are you sure you want to permanently delete this group? This action is irreversible!</p>
                    <div class="flex self-end">
                      <button
                        type="button"
                        @click="toggleModal"
                        class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        class="inline-flex justify-center rounded-md border border-transparent dark:bg-red-500/90 bg-red-700 px-4 py-2 text-sm font-medium text-black hover:bg-red-700 dark:hover:bg-red-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        :disabled="working"
                        @click="deleteGroup"
                      >
                        {{ buttonLabel }}
                      </button>
                    </div>
                    <div class="bg-red-800 p-2 rounded-md text-xs font-bold text-red-200" v-if="error">
                      <p>{{ errorMessage }} <small>({{ errorCode }})</small></p>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </section>
  </main>
</template>
