<script setup lang="ts">
import ViewCircle from "@/components/circles/ViewCircle.vue"
import Label from "@/components/input/Label.vue"
import Field from "@/components/input/Field.vue"
import Form from "@/components/input/Form.vue"
import { ref, computed, reactive } from "vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"
import * as zod from "zod"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

const UserStore = useUserStore()
const { defaultVisibility, circles } = storeToRefs(UserStore)

const schema = zod.object({
  audience: zod.string()
    .min(1, { message: "This is required." })
})

const initialValues = reactive({
  audience: defaultVisibility.value
})

const props = defineProps({
  circle: {
    type: Object,
    required: true
  }
})
const emits = defineEmits(["deleted", "removedUserFromCircle"])

const working = ref(false)
const deleteModalOpen = ref(false)
const checkingCircle = ref(false)
const circleHasContent = ref(false)

const filteredCircles = computed(() => {
  return circles.value.filter((c: any) => c.code !== props.circle.code)
})

async function toggleDeleteModal() {
  if (!deleteModalOpen.value) {
    checkingCircle.value = true
    deleteModalOpen.value = true
    const res = await API().get(`/circles/${props.circle.code}/content`)
    circleHasContent.value = res.data.data
    checkingCircle.value = false
  } else {
    deleteModalOpen.value = false
    circleHasContent.value = false
  }
}

async function deleteCircle() {
  if (working.value) return
  working.value = true
  try {
    await API().delete(`/circles/${props.circle.code}`)
    emits("deleted", props.circle.code)
    UserStore.removeCircle(props.circle.code)
  } catch (err) {
    console.error(err)
  } finally {
    working.value = false
  }
}

async function handleSubmit(values: any) {
  if (working.value) return
  working.value = true
  try {
    await API().delete(`/circles/${props.circle.code}`, {
      data: {
        audience: values.audience
      }
    })
    emits("deleted", props.circle.code)
    UserStore.removeCircle(props.circle.code)
    toggleDeleteModal()
  } catch (err) {
    console.error(err)
  } finally {
    working.value = false
  }
}

function handleRemovedUserFromCircle(data: any) {
  emits("removedUserFromCircle", data)
}
</script>

<template>
  <li class="text-sm bg-white dark:bg-submit-700 dark:hover:bg-submit-500 rounded-md p-4" :key="circle._id">
    <h3 class="font-bold">{{ circle.name }}</h3>
    <p class="text-xs text-gray-500 dark:text-gray-400">{{ circle.count }}/{{ circle.type === "xl" ? 256 : 64 }} users</p>
    <div class="mt-2 flex justify-between">
      <ViewCircle :circle="circle" label="View" @remove-from-circle="handleRemovedUserFromCircle" />
      <button @click="toggleDeleteModal" type="button" class="text-red-500 dark:text-red-400">Delete</button>
      <TransitionRoot appear :show="deleteModalOpen" as="template">
        <Dialog as="div" @close="toggleDeleteModal" class="relative z-[100]">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>
          <div class="fixed inset-0 overflow-y-auto">
            <div
              class="flex min-h-full items-center justify-center p-4 text-center"
            >
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <DialogPanel
                  class="w-full max-w-md transform overflow-hidden rounded-2xl bg-neutral-125 dark:bg-submit-900 p-6 text-left align-middle shadow-xl transition-all"
                >
                  <DialogTitle
                    as="h3"
                    class="text-lg font-bold leading-6 text-black dark:text-white mb-4"
                  >
                    Delete {{ circle.name }}?
                  </DialogTitle>
                  <div class="flex flex-col mt-2 space-y-4">
                    <p class="text-sm">Are you sure you want to permanently delete this circle? This action cannot be undone.</p>
                    <p class="text-sm" v-if="checkingCircle">Checking circle for content...</p>
                    <p v-if="!checkingCircle" class="text-sm">This circle has {{ circleHasContent ? "content" : "no content" }} and {{ circle.count }} user{{ circle.count === 1 ? "" : "s" }}.</p>
                    <Form @onSubmit="handleSubmit" :schema="schema" :initialValues="initialValues" v-if="!checkingCircle && circleHasContent" class="flex flex-col space-y-3">
                      <div>
                        <Label field="audience">New Audience</Label>
                        <Field name="audience" type="select" helpText="This will be the new audience for all of your content in this circle." alt>
                          <option value="public">Everyone</option>
                          <option value="followers">Followers</option>
                          <option value="relationships">Relationships</option>
                          <optgroup label="Circles" v-if="filteredCircles.length > 0">
                            <option v-for="circle in filteredCircles" :key="circle._id" :value="circle.code">{{ circle.name }}</option>
                          </optgroup>
                        </Field>
                      </div>
                      <div class="flex self-end">
                        <button
                          type="button"
                          @click="toggleDeleteModal"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                          :disabled="working"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          :disabled="working || checkingCircle"
                        >
                          {{ working ? "Deleting..." : "Delete" }}
                        </button>
                      </div>
                    </Form>
                    <div class="flex self-end" v-if="!checkingCircle && !circleHasContent">
                      <button
                        type="button"
                        @click="toggleDeleteModal"
                        class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mr-2"
                        :disabled="working"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        @click="deleteCircle"
                        class="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-black hover:bg-white dark:hover:bg-submit-500 dark:hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        :disabled="working || checkingCircle"
                      >
                        {{ working ? "Deleting..." : "Delete" }}
                      </button>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>
  </li>
</template>
