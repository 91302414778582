<script setup lang="ts">
import { inject, onBeforeMount, onMounted, ref, watch } from "vue"
import Filter from "@/components/input/Filter.vue"
import setupCommons from "@/composables/Common"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const User = useUserStore()
const { roles, announcementList } = storeToRefs(User)
const { actionHandler, scrollToTop } = setupCommons()

const sortOptions = [
  { label: "2025", value: "2025" },
  { label: "2024", value: "2024" }
]

const setTitle = inject("setTitle") as (title: string) => void
const initialLoadComplete = ref(false)
const sortBy = ref(new Date().getFullYear().toString())
const working = ref(false)
const error = ref(false)
const totalItems = ref(0)

const announcements = ref<any[]>([])
const pageInfo = ref<any>({
  endCursor: undefined
})

watch(sortBy, async () => {
  announcements.value = []
  pageInfo.value = {
    endCursor: undefined
  }
  initialLoadComplete.value = false
  await fetchAnnouncements()
  initialLoadComplete.value = true
})

onBeforeMount(() => {
  setTitle("Announcements")
})

onMounted(async () => {
  await fetchAnnouncements()
  initialLoadComplete.value = true
  if (pageInfo.value.totalItems > 11) {
    let target = document.querySelector('#loadMore')
    observer.observe(target as any)
  }
})

function checkIfRead(id: string) {
  return announcementList.value.includes(id)
}

async function fetchAnnouncements() {
  if (working.value) return
  working.value = true

  let path = `/announcements`

  let searchParams = new URLSearchParams({
    sortBy: sortBy.value
  })

  if (pageInfo.value.endCursor) {
    searchParams.append('cursor', pageInfo.value.endCursor)
  }

  path = `/announcements?${searchParams.toString()}`

  try {
    const response = await API().get(path)
    const data = response.data
    announcements.value.push(...data.data)
    if (!pageInfo.value.endCursor) {
      totalItems.value = data.pageInfo.totalItems
    }
    pageInfo.value = data.pageInfo

    working.value = false
  } catch (err) {
    error.value = true
    working.value = false
    return
  }
}

let options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.1
}

let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      if (
        initialLoadComplete.value &&
        !working.value &&
        pageInfo.value.hasNextPage
      ) {
        fetchAnnouncements()
      }
    }
  })
}, options)
</script>

<template>
  <div class="grid grid-cols-1 content-start">
    <header class="flex justify-between items-center text-sm font-bold">
      <div>{{ sortBy }} Announcements</div>
      <div class="flex gap-2">
        <Filter label="Year" :options="sortOptions" v-model="sortBy" />
        <router-link v-if="roles.includes('admin')" to="/announcements/new" class="relative flex border hover:border-neutral-400 dark:border-gray-800 dark:hover:border-gray-700 rounded-md items-center bg-white dark:bg-submit-900 py-1.5 px-3 gap-2 text-sm group">
          <svg class="flex-none h-3 dark:fill-gray-400 dark:group-hover:fill-gold-500" xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
        </router-link>
      </div>
    </header>
    <main class="mt-4" v-if="initialLoadComplete">
      <ul @click="actionHandler" class="ItemList grid grid-cols-1 gap-2">
        <li v-if="announcements.length === 0">There have been no Announcements published in this year yet.</li>
        <li class="dark:bg-submit-900 bg-white hover:dark:bg-submit-700 cursor-pointer px-4 pt-4 pb-3 rounded-md ListItem" :data-to="`/announcements/${item.slug}`" v-if="initialLoadComplete && announcements.length > 0" v-for="item in announcements" :key="item._id">
          <router-link class="flex mb-2 items-center gap-1.5" :to="`/announcements/${item.slug}`">
            <span class="flex text-xs px-1.5 py-0.5 bg-red-500 text-red-950 font-bold rounded-md" v-if="!checkIfRead(item._id)">New!</span>
            <span class="inline-block text-xl font-display font-semibold dark:text-gold-500 text-gold-700">{{ item.title }}</span>
          </router-link>
          <p class="text-sm">{{ item.summary }}</p>
          <ul class="mt-2 text-xs flex space-x-4 text-gray-500 dark:text-gray-400">
            <li>
              <time :title="D(item.publishedAt).format('LLLL')">
                Posted {{ D(item.publishedAt).fromNow() }}
              </time>
            </li>
          </ul>
        </li>
        <li class="flex flex-col items-center gap-y-6 mt-8 TheEnd" v-if="announcements.length > 0 && !pageInfo.hasNextPage">
          <h2 class="text-xl text-gray-400 dark:text-gray-700">You've reached the end</h2>
          <button @click="scrollToTop" type="button" class="flex text-sm text-gray-400 dark:text-gray-700 dark:hover:text-gray-400 group gap-3 align-middle">
            <svg class="h-4 flex-none fill-gray-400 dark:fill-gray-700 dark:group-hover:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M32 32C14.3 32 0 46.3 0 64S14.3 96 32 96H352c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM214.6 169.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 269.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V269.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z"/></svg>
            Go to the top
          </button>
        </li>
      </ul>
      <div id="#loadmore"></div>
    </main>
    <main class="mt-4 text-sm font-bold" v-else>Loading...</main>
  </div>
</template>
