<script setup lang="ts">
import EveryoneSettings from "@/components/feed/EveryoneSettings.vue"
import GroupSettings from "@/components/feed/GroupSettings.vue"
import Featured from "@/components/announcements/Featured.vue"
import { ref, provide, computed, watch, onMounted } from "vue"
import Publisher from "@/components/feed/Publisher.vue"
import Selector from "@/components/feed/Selector.vue"
import { usePlatformStore } from "@/stores/Platform"
import Alert from "@/components/alerts/Alert.vue"
import { useRoute, useRouter } from "vue-router"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const PlatformStore = usePlatformStore()
const { loading } = storeToRefs(PlatformStore)
const UserStore = useUserStore()
const { backer, announcements, unseenFeaturedAnnouncements, circles } = storeToRefs(UserStore)
const Router = useRouter()
const Route = useRoute()
const loadingTrending = ref(true)
const trendingError = ref(false)
const trending = ref<any[]>([])
const currentHashtag = ref(Route.query.hashtag as string | undefined)

const circleValue = ref("")

const contextCategory = computed(() => {
  switch (Route.name) {
    case "Home.Following":
    case "Home.Groups":
    case "Home.Everyone":
      return "feed"
    case "Home.Circle":
      return "circle"
  }
})
provide('contextCategory', contextCategory)

const feedContext = computed(() => {
  switch (Route.name) {
    case "Home.Following":
      return "all"
    case "Home.Groups":
      return "groups"
    case "Home.Everyone":
      return "everyone"
    case "Home.Circle":
      return Route.params.code as string
  }
})
provide('feedContext', feedContext)
const groupsSetting = ref(false)
provide('groupsSetting', groupsSetting)
const toggleGroupsSetting = () => groupsSetting.value = !groupsSetting.value
provide('toggleGroupsSetting', toggleGroupsSetting)

const everyoneSetting = ref(false)
provide('everyoneSetting', everyoneSetting)
const toggleEveryoneSetting = () => everyoneSetting.value = !everyoneSetting.value
provide('toggleEveryoneSetting', toggleEveryoneSetting)

function handleCircleChange(e: Event) {
  const target = e.target as HTMLSelectElement
  if (!target.value || target.value === "") {
    circleValue.value = ""
    Router.push('/home')
    return
  }
  circleValue.value = target.value
  Router.push(`/circles/${target.value}`)
}

async function getTrending() {
  try {
    const res = await API().get('/status/trending')
    trending.value = res.data.trending
  } catch (err) {
    console.error(err)
    trendingError.value = true
  } finally {
    loadingTrending.value = false
  }
}

onMounted(async () => {
  await getTrending()
})

watch(
  () => Route.name,
  (newRouteName) => {
    if (newRouteName === 'Home.Circle') {
      circleValue.value = Route.params.code as string
    } else {
      circleValue.value = ""
    }
  },
  { immediate: true }
)

watch(() => Route.query.hashtag, async (newHashtag) => {
  currentHashtag.value = newHashtag as string | undefined
})
</script>

<template>
  <div class="px-2" v-if="!loading">
    <main class="Page container mx-auto">
      <ul class="PageControls space-y-4 text-sm dark:text-gray-400 text-gray-500">
        <li class="flex items-center gap-2 font-semibold select-none">
          <svg
            :class="`h-4 w-4 ${contextCategory === 'feed' ? 'fill-black dark:fill-white' : 'fill-gray-500 dark:fill-gray-400'} flex-none`"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM64 256c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H96c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>
          </svg>
          <span :class="`${contextCategory === 'feed' ? 'text-black dark:text-white' : ''}`">Feeds</span>
        </li>
        <li class="flex gap-2">
          <div class="w-4 h-4"></div>
          <router-link
            :class="[
              contextCategory === 'feed' && feedContext === 'all' ? 'text-gold-700 dark:text-gold-500' : '',
              'hover:text-black dark:hover:text-white'
            ]"
            to="/home"
          >
            Following
          </router-link>
        </li>
        <li class="flex gap-2">
          <div class="w-4 h-4"></div>
          <router-link
            :class="[
              contextCategory === 'feed' && feedContext === 'groups' ? 'text-gold-700 dark:text-gold-500' : '',
              'hover:text-black dark:hover:text-white'
            ]"
            to="/home/groups"
          >
            Groups
          </router-link>
        </li>
        <li class="flex gap-2">
          <div class="w-4 h-4"></div>
          <router-link
            :class="[
              contextCategory === 'feed' && feedContext === 'everyone' ? 'text-gold-700 dark:text-gold-500' : '',
              'hover:text-black dark:hover:text-white'
            ]"
            to="/home/everyone"
          >
            Everyone
          </router-link>
        </li>
        <li class="flex gap-2 w-full group">
          <svg
            :class="[
              'h-4 w-4 flex-none',
              contextCategory === 'circle' ? 'fill-black dark:fill-white' : 'fill-gray-500 dark:fill-gray-400 group-hover:fill-black dark:group-hover:fill-white'
            ]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M1.1 232C6 179.3 26.9 131.3 58.8 92.7l45.5 45.5C83.7 164.8 69.9 196.9 65.5 232L1.1 232zM138.3 104.3L92.7 58.8C131.3 26.9 179.3 6 232 1.1l0 64.4c-35.1 4.4-67.2 18.2-93.7 38.8zM280 446.5c35.1-4.4 67.2-18.2 93.7-38.8l45.5 45.5C380.7 485.1 332.7 506 280 510.9l0-64.4zm127.7-72.8c20.6-26.6 34.5-58.7 38.8-93.7l64.4 0c-4.9 52.7-25.8 100.7-57.7 139.3l-45.5-45.5zM92.7 453.2l45.5-45.5c26.6 20.6 58.7 34.5 93.7 38.8l0 64.4c-52.7-4.9-100.7-25.8-139.3-57.7zM58.8 419.3C26.9 380.7 6 332.7 1.1 280l64.4 0c4.4 35.1 18.2 67.2 38.8 93.7L58.8 419.3zM419.3 58.8l-45.5 45.5C347.2 83.7 315.1 69.9 280 65.5l0-64.4C332.7 6 380.7 26.9 419.3 58.8zm-11.6 79.5l45.5-45.5c31.9 38.5 52.8 86.6 57.7 139.3l-64.4 0c-4.4-35.1-18.2-67.2-38.8-93.7z"/>
          </svg>
          <select
            v-model="circleValue"
            @change="handleCircleChange"
            :class="[
              contextCategory === 'circle' ? 'text-gold-700 dark:text-gold-500' : 'text-gray-500 dark:text-gray-400',
              'dark:bg-submit-950 bg-neutral-125 border-none pl-0 py-0 text-sm w-full hover:text-black dark:hover:text-white cursor-pointer'
            ]"
          >
            <option value="">Circles</option>
            <option v-for="circle in circles" :key="circle._id" :value="circle.code">{{ circle.name }}</option>
          </select>
        </li>
        <li>
          <router-link to="/announcements" class="group">
            <figure class="flex flex-none w-4 h-4 justify-center items-center *:group-hover:fill-black *:dark:group-hover:fill-white">
              <svg :class="`h-4 w-4 ${announcements > 0 ? 'fill-red-500' : 'fill-gray-500 dark:fill-gray-400'}`" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path class="fa-secondary" opacity=".4" d="M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75H192v32 32 96 32 32h8.7c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32z"/><path class="fa-primary" d="M0 192c0-35.3 28.7-64 64-64H192V320v32V480c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32l0-128c-35.3 0-64-28.7-64-64V192zm512 48c0 27.9-13.4 51.6-32 60.4V179.6c18.6 8.8 32 32.5 32 60.4z"/></svg>
            </figure>
            <span class="group-hover:text-black dark:group-hover:text-white">Announcements</span>
          </router-link>
        </li>
        <li>
          <router-link to="/goals" class="group">
            <figure class="flex flex-none w-4 h-4 justify-center items-center *:group-hover:fill-black *:dark:group-hover:fill-white">
              <svg v-if="backer" class="h-4 w-4 fill-gray-500 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
              <svg v-else class="fill-pink-450" width="16" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
                <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
                <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
              </svg>
            </figure>
            <span
              :class="[
                backer ? '' : 'text-pink-450 font-bold',
                'group-hover:text-black dark:group-hover:text-white'
              ]"
            >
              {{ backer ? "Goals" : "Support Submit!" }}
            </span>
          </router-link>
        </li>
        <li v-if="feedContext === 'groups'">
          <button @click="toggleGroupsSetting" class=" flex gap-3 items-center group">
            <figure class="flex flex-none w-4 h-4 justify-center items-center *:group-hover:fill-black *:dark:group-hover:fill-white">
              <svg class="h-4 w-4 fill-gray-500 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z"/></svg>
            </figure>
            <span class="group-hover:text-black dark:group-hover:text-white">Preferences</span>
          </button>
        </li>
        <li v-if="feedContext === 'everyone'">
          <button @click="toggleEveryoneSetting" class=" flex gap-3 items-center group">
            <figure class="flex flex-none w-4 h-4 justify-center items-center *:group-hover:fill-black *:dark:group-hover:fill-white">
              <svg class="h-4 w-4 fill-gray-500 dark:fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z"/></svg>
            </figure>
            <span class="group-hover:text-black dark:group-hover:text-white">Preferences</span>
          </button>
        </li>
      </ul>
      <div class="grid gap-4 md:gap-8">
        <Featured v-if="unseenFeaturedAnnouncements.length > 0" />
        <Alert v-if="UserStore.alert" />
        <Publisher v-if="feedContext !== 'groups'" />
        <Selector />
        <div class="flex 2xl:hidden gap-2" v-if="feedContext !== 'groups'">
          <h2 class="text-sm font-bold">Trending</h2>
          <p class="text-sm text-gray-500 dark:text-gray-400" v-if="!loadingTrending && trending.length === 0">No trending hashtags</p>
          <p class="text-sm text-gray-500 dark:text-gray-400" v-else-if="loadingTrending">Loading trending hashtags...</p>
          <p class="text-sm text-gray-500 dark:text-gray-400" v-else-if="loadingTrending && trendingError">Error loading trending hashtags</p>
          <ul class="flex flex-wrap gap-2" v-else>
            <li v-for="tag in trending" :key="tag" class="text-sm text-gray-500 dark:text-gray-400">
              <router-link
                :class="[
                  currentHashtag === tag.tag ? 'text-gold-700 dark:text-gold-500' : '',
                  'underline decoration-dotted hover:decoration-solid'
                  ]"
                :to="`/home/everyone?hashtag=${tag.tag}`"
              >
                #{{ tag.tag }}
              </router-link>
            </li>
          </ul>
        </div>
        <router-view />
        <GroupSettings v-if="feedContext === 'groups'" />
        <EveryoneSettings v-if="feedContext === 'everyone'" />
      </div>
      <div class="hidden lg:grid grid-cols-1 gap-4 content-start">
        <div class="flex flex-col gap-2">
          <h2 class="text-sm font-bold">Trending</h2>
          <p class="text-sm text-gray-500 dark:text-gray-400" v-if="!loadingTrending && trending.length === 0">No trending hashtags.</p>
          <p class="text-sm text-gray-500 dark:text-gray-400" v-else-if="loadingTrending">Loading trending hashtags...</p>
          <p class="text-sm text-gray-500 dark:text-gray-400" v-else-if="loadingTrending && trendingError">Error loading trending hashtags.</p>
          <ul v-else>
            <li v-for="tag in trending" :key="tag" class="text-sm text-gray-500 dark:text-gray-400">
              <router-link
                :class="[
                  currentHashtag === tag.tag ? 'text-gold-700 dark:text-gold-500' : '',
                  'underline decoration-dotted hover:decoration-solid'
                ]"
                :to="`/home/everyone?hashtag=${tag.tag}`"
              >
                #{{ tag.tag }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="flex flex-col gap-2">
          <h2 class="text-sm font-bold">Upcoming Events</h2>
          <p class="text-sm text-gray-500 dark:text-gray-400">No upcoming events</p>
        </div>
      </div>
    </main>
  </div>
</template>
