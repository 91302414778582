<script setup lang="ts">
import Checkbox from "@/components/input/Checkbox.vue"
import { usePlatformStore } from "@/stores/Platform"
import Field from "@/components/input/Field.vue"
import { useRouter, useRoute } from "vue-router"
import Label from "@/components/input/Label.vue"
import Code from "@/components/input/Code.vue"
import Form from "@/components/input/Form.vue"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { storeToRefs } from "pinia"
import { useUDC } from "@/main"
import API from "@/api/api"
import * as zod from "zod"
import { ref } from "vue"
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle
} from "@headlessui/vue"

const router = useRouter()
const route = useRoute()

const platformStore = usePlatformStore()
const userStore = useUserStore()
const { debug } = storeToRefs(platformStore)

const modalOpen = ref(false)
const toggleModal = () => {
  modalOpen.value = !modalOpen.value
}

const initialValues = {
  email: "",
  password: "",
  rememberMe: false
}

const schema = zod.object({
  email: zod.string()
    .email({ message: "Must be a valid email address." })
    .min(1, { message: "This is required." }),
  password: zod.string()
    .min(1, { message: "This is required." } ),
  rememberMe: zod.boolean().optional()
})

const deleteSchema = zod.object({
  password: zod.string()
    .min(1, { message: "This is required." })
})

const recoverySchema = zod.object({
  code: zod.string()
    .min(16, { message: "Recovery codes must be 16 characters long." })
    .max(16, { message: "Recovery codes must be 16 characters long." })
})

const working = ref(false)
const error = ref<boolean>(false)
const localDebug = ref(debug.value)
const view = ref("signin")

const TOTPid = ref<string>("")
const TOTPtoken = ref<string>("")
const TOTPRemember = ref(false)
const sentTOTP = ref(false)
const validTOTP = ref(false)
const invalidTOTP = ref(false)
const TOTPexpired = ref(false)

const punishment = ref<any | undefined>(undefined)

function checkCode(code: string) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API(false).post("/totp/a", {
        id: TOTPid.value,
        token: TOTPtoken.value,
        code: code,
        rememberMe: TOTPRemember.value
      })
      resolve(response.data.data)
    } catch (err) {
      reject(err)
    }
  })
}

async function handleCode(code: string) {
  if (working.value) return
  working.value = true
  try {
    await checkCode(code)
    validTOTP.value = true
    sentTOTP.value = true
    working.value = false
    setTimeout(() => {
      route.query.redirect ?
      router.push({ path: route.query.redirect as string}) :
      router.push({ name: "Home.Following" })
    }, 500)
  } catch (err) {
    invalidTOTP.value = true
    sentTOTP.value = true
    working.value = false
  }
}

async function handleRecoveryCode(values: any) {
  if (working.value) return
  working.value = true
  try {
    await checkCode(values.code)
    validTOTP.value = true
    sentTOTP.value = true
    working.value = false
    setTimeout(() => {
      route.query.redirect ?
      router.push({ path: route.query.redirect as string}) :
      router.push({ name: "Home.Following" })
    }, 500)
  } catch (err) {
    invalidTOTP.value = true
    sentTOTP.value = true
    working.value = false
  }
}

function resetCode() {
  sentTOTP.value = false
  validTOTP.value = false
  invalidTOTP.value = false
  working.value = false
}

const onSubmit = async (values: any) => {
  working.value = true
  try {
    const response: any = await userStore.signIn({
      email: values.email,
      password: values.password
    }, { rememberMe: values.rememberMe })
    if (response.status === 'signedIn') {
      await userStore.initUser()
      useUDC().setUserId(userStore.id)
      working.value = false
      route.query.redirect ?
      router.push({ path: route.query.redirect as string}) :
      router.push({ name: "Home.Following" })
    }
    if (response.status === 'totp') {
      working.value = false
      TOTPid.value = response.data.id
      TOTPtoken.value = response.data.token
      TOTPRemember.value = values.rememberMe
      view.value = 'totp'
      // set timeout for 5 minutes
      setTimeout(() => {
        TOTPexpired.value = true
      }, 300000)
    }
  } catch (err: any) {
    if (err.status === 'punished') {
      working.value = false
      punishment.value = err.data
      view.value = 'punishment'
    } else {
      error.value = true
      working.value = false
    }
  }
}

const onDelete = async (values: any) => {
  if (working.value) return
  working.value = true
  try {
    await API(false).delete("/users/pd", {
      headers: { "Content-Type": "application/json" },
      data: {
        id: punishment.value.id,
        password: values.password
      }
    })
    working.value = false
    view.value = 'deleted'
    useUDC().setUserId(null)
  } catch (err) {
    working.value = false
    error.value = true
  }
}
</script>

<template>
  <section>
    <h1 v-if="view !== 'punishment' && view !== 'deleted'" class="font-display text-2xl dark:text-white font-bold mb-6">{{ $t("signin") }}</h1>
    <h1 v-if="view === 'punishment'" class="font-display text-2xl dark:text-white font-bold mb-6">
      {{ punishment && punishment.type === 'banned' ? "Banned" : "Timeout" }}
    </h1>
    <h1 v-if="view === 'deleted'" class="font-display text-2xl dark:text-white font-bold mb-6">Account Deleted</h1>
    <Form @on-submit="onSubmit" :schema="schema" :initialValues="initialValues" v-slot="{ fields, errors }" class="space-y-3" v-if="view === 'signin'">
      <div>
        <label for="email" class="block text-sm font-bold mb-2">{{ $t("email") }}</label>
        <Field name="email" />
      </div>
      <div>
        <label for="password" class="block text-sm font-bold mb-2">{{ $t("password")}}</label>
        <Field name="password" type="password" />
      </div>
      <div>
        <Checkbox name="rememberMe">
          {{ $t("rememberme") }}
        </Checkbox>
      </div>
      <div>
        <button class="mt-6 font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 hover:dark:bg-gold-500 text-black py-4 w-full" type="submit" :disabled="working">
          {{ working ? $t("signingin") : $t("signin") }}
        </button>
      </div>
      <div v-if="localDebug">
        <pre>
{{ fields }}
        </pre>
        <pre>
{{ errors }}
        </pre>
      </div>
      <div v-if="error" class="mt-6 transition-all text-center text-xs rounded-md bg-red-800 px-6 py-4">
        <h2 class="font-bold">
          <span class="text-white">Authentication Failed</span>
        </h2>
        <p class="text-[#FF9595]">
          <span>The credentials you provided are invalid.</span>
        </p>
      </div>
    </Form>
    <div v-if="view === 'totp'" class="space-y-3">
      <p>Enter your two factor code to complete signing in.</p>
      <div>
        <Label field="code">Code:</Label>
        <Code
          :number="6"
          :sending="working"
          :sent="sentTOTP"
          :valid="validTOTP"
          :invalid="invalidTOTP"
          @complete="handleCode"
          @reset="resetCode"
          type="2fa"
        />
      </div>
      <div v-if="TOTPexpired">
        <p class="text-sm text-red-500 font-bold">Your session has expired, you'll need to sign in again to continue.</p>
      </div>
      <p class="text-sm pt-3 text-gray-500">
        <a @click="view = 'recovery'" href="#">Lose access to your authenticator?</a>
      </p>
    </div>
    <Form @on-submit="handleRecoveryCode" :schema="recoverySchema" v-if="view === 'recovery'" class="space-y-3">
      <p>If you've lost access to your authenticator, you can use a recovery code to sign in. Once you've signed in, you can use another recovery code to disable and re-enable your 2FA.</p>
      <Label field="code">Recovery Code:</Label>
      <Field name="code" />
      <p class="text-xs">If you don't have access to your recovery codes, you'll need to email <a class="underline hover:decoration-2 dark:hover:decoration-gold-500" href="mailto:support@submit.gg">support@submit.gg</a> for help.</p>
      <button class="mt-6 font-display font-bold rounded-md bg-gold-700/90 hover:bg-gold-700 dark:bg-gold-500/90 hover:dark:bg-gold-500 text-black py-4 w-full" type="submit" :disabled="working">
        {{ working ? $t("signingin") : $t("signin") }}
      </button>
      <div v-if="TOTPexpired">
        <p class="text-sm text-red-500 font-bold">Your session has expired, you'll need to sign in again to continue.</p>
      </div>
    </Form>
    <div v-if="view === 'deleted'" class="space-y-3">
      <article>
        <p>Your account has beeen permanently deleted.</p>
      </article>
    </div>
    <div v-if="view === 'punishment'" class="space-y-3">
      <article class="space-y-6 text-sm" v-if="punishment.type === 'timeout'">
        <p>You have been timed out for {{ punishment.punishment.duration }} days.</p>
        <section>
          <h2 class="font-display text-indigo-950 dark:text-neutral-50 font-bold mb-1">Reason</h2>
          <p>{{ punishment.punishment.reason }}</p>
        </section>
        <section v-if="punishment.punishment.rules.length > 0">
          <h2 class="font-display text-indigo-950 dark:text-neutral-50 font-bold mb-1">Rules Violated</h2>
          <p>{{ punishment.punishment.rules }}</p>
        </section>
        <section>
          <h2 class="font-display text-indigo-950 dark:text-neutral-50 font-bold mb-1">Duration &amp; Expiry</h2>
          <ul>
            <li>Duration (days): {{ punishment.punishment.duration }}</li>
            <li>Expires: <time>{{ D(punishment.punishment.expiresAt).format('LLL') }}</time></li>
          </ul>
        </section>
        <p>Your access will be reinstated upon the completion of the timeout. Read our guidelines on <a class="underline hover:decoration-2" href="https://docs.submit.gg/appeal-reinstatement">Appeal &amp; Reinstatement</a>.</p>
        <button @click="toggleModal" type="button">Delete Your Account</button>
        <TransitionRoot appear :show="modalOpen" as="template">
          <Dialog as="div" @close="toggleModal" class="relative z-[100]">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>
            <div class="fixed inset-0 overflow-y-auto">
              <div
                class="flex min-h-full items-center justify-center p-4 text-center"
              >
                <TransitionChild
                  as="template"
                  enter="duration-300 ease-out"
                  enter-from="opacity-0 scale-95"
                  enter-to="opacity-100 scale-100"
                  leave="duration-200 ease-in"
                  leave-from="opacity-100 scale-100"
                  leave-to="opacity-0 scale-95"
                >
                  <DialogPanel
                    class="w-full max-w-md transform overflow-hidden rounded-md bg-neutral-125 dark:bg-submit-900 px-2 xs:px-4 py-6 sm:px-6 sm:py-6 text-left align-middle shadow-xl transition-all"
                  >
                    <DialogTitle
                      as="h3"
                      class="text-xl text-center font-bold leading-6 text-black dark:text-white mb-4"
                    >
                      Confirm Account Deletion
                    </DialogTitle>
                    <div class="flex flex-col mt-6">
                      <div class="space-y-3 text-sm">
                        <p>
                          Are you sure you want to delete your account? This action is irreversible and will permanently delete your account and all associated data.
                        </p>
                        <p class="text-xs"><strong>Note:</strong> Deleting your account as an attempt to circumvent your punishment will result in being permanently banned.</p>
                      </div>
                      <Form :schema="deleteSchema" @on-submit="onDelete" class="space-y-3 mt-6">
                        <div>
                          <Label field="password">Password:</Label>
                          <Field name="password" type="password" alt />
                        </div>
                        <div class="flex justify-center mt-6">
                          <button
                            class="font-display font-bold rounded-md bg-red-700/90 hover:bg-red-700 dark:bg-red-500/90 hover:dark:bg-red-500 text-black py-4 w-full"
                            type="submit"
                          >
                            Delete Account
                          </button>
                        </div>
                        <div v-if="error" class="mt-6 transition-all text-center text-xs rounded-md bg-red-800 px-6 py-4">
                          <h2 class="font-bold">
                            <span class="text-white">Authentication Failed</span>
                          </h2>
                          <p class="text-[#FF9595]">
                            <span>The credentials you provided are invalid.</span>
                          </p>
                        </div>
                      </Form>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </TransitionRoot>
      </article>
      <article class="space-y-6 text-sm" v-if="punishment.type === 'banned'">
        <p>You have been permanently banned from Submit.</p>
        <section>
          <h2 class="font-display text-indigo-950 dark:text-neutral-50 font-bold mb-1">Reason</h2>
          <p>{{ punishment.punishment.reason }}</p>
        </section>
        <section v-if="punishment.punishment.rules.length > 0">
          <h2 class="font-display text-indigo-950 dark:text-neutral-50 font-bold mb-1">Rules Violated</h2>
          <p>{{ punishment.punishment.rules }}</p>
        </section>
        <p>Your account will be permanently deleted within the next 10 days. Read our guidelines on <a class="underline hover:decoration-2" href="https://docs.submit.gg/appeal-reinstatement">Appeal &amp; Reinstatement</a>.</p>
      </article>
    </div>
  </section>
</template>
