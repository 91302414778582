<script setup lang="ts">
import { ref, onBeforeMount, inject, reactive } from "vue"
import Comments from "@/components/comments/Comments.vue"
import ReactV2 from "@/components/reactions/ReactV2.vue"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { useRoute } from "vue-router"
import { storeToRefs } from "pinia"
import API from "@/api/api"

const Route = useRoute()
const User = useUserStore()
const { id } = storeToRefs(User)

const slug = Route.params.slug as string

const content = reactive({
  contentAuthor: "",
  contentId: "",
  contentType: "",
  contentAudience: ""
})

const setTitle = inject("setTitle") as (title: string) => void
const initialLoadComplete = ref(false)
const announcement = ref<any>(null)

onBeforeMount(async () => {
  setTitle("Announcements")
  await getAnnouncement()
  initialLoadComplete.value = true
  document.title = `${announcement.value.title} - Announcements - Submit`
  content.contentAuthor = announcement.value.user._id
  content.contentId = announcement.value._id
  content.contentType = "announcement"
  content.contentAudience = "public"
  // Add the announcement to the user's list of announcements
  User.addAnnouncementToList(announcement.value._id)
})

async function getAnnouncement() {
  try {
    const response = await API().get(`/announcements/${slug}`)
    announcement.value = response.data.data
  } catch (err) {
    console.error(err)
  }
}
</script>

<template>
  <article class="space-y-6" v-if="initialLoadComplete">
    <header class="mb-6">
      <h1 class="text-2xl font-display font-semibold">{{ announcement.title }}</h1>
      <div class="text-xs">
        <time :title="D(announcement.createdAt).format('LLLL')">Published {{ D(announcement.createdAt).fromNow() }}</time>
        <span class="dark:text-submit-949 text-neutral-124 select-none pointer-events-none overflow-hidden">{{ id }}</span>
      </div>
    </header>
    <div class="break-modern CommonBody" v-html="announcement.html"></div>
    <footer class="" v-if="announcement.comments || announcement.reactions">
      <ReactV2 v-if="announcement.reactions" :self="false" :reacted="announcement.hasReacted ? announcement.hasReacted : false" :reactions="announcement.reactionsCount" :reaction-data="announcement.reactionData ? announcement.reactionData : undefined" :content="content" />
    </footer>
    <Comments type="announcement" size="medium" :forAuthor="announcement.user._id" forAudience="public" :content="announcement._id" :locked="announcement.commentsLocked ? announcement.commentsLocked : false" />
  </article>
  <main v-else>Loading...</main>
</template>
