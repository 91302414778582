<script setup lang="ts">
import { ref, computed, onMounted, inject, nextTick, watch, type Ref, type CSSProperties } from "vue"
import { useUserStore } from "@/stores/User"
import { storeToRefs } from "pinia"

const UserStore = useUserStore()
const { circles } = storeToRefs(UserStore)

const feedOptions = ref([
  { id: 'all', label: 'Following', path: '/home' },
  { id: 'groups', label: 'Groups', path: '/home/groups' },
  { id: 'everyone', label: 'Everyone', path: '/home/everyone' }
])

const circleOptions = ref(circles.value.map((circle: any) => ({
  id: circle.code, label: circle.name, path: `/circles/${circle.code}`
})))

const contextCategory = inject<Ref<string>>('contextCategory')
const feedContext = inject<Ref<string>>('feedContext')
const toggleGroupsSetting = inject<() => void>('toggleGroupsSetting')
const toggleEveryoneSetting = inject<() => void>('toggleEveryoneSetting')

const activeView = ref(contextCategory?.value || 'feed')

const isFeeds = computed(() => activeView.value === 'feed')
const containerRef = ref<HTMLElement | null>(null)

// Function to update CSS custom property based on container width
const updateContainerWidth = () => {
  if (containerRef.value) {
    const width = containerRef.value.offsetWidth
    containerRef.value.style.setProperty('--container-width', `${width}px`)
  }
}

const isInitialLoad = ref(true)

// Set up resize observer
onMounted(() => {
  const observer = new ResizeObserver(updateContainerWidth)
  if (containerRef.value) {
    observer.observe(containerRef.value)
  }

  // Small timeout to ensure DOM is ready
  setTimeout(() => {
    isInitialLoad.value = false
    scrollActiveItemIntoView()
  }, 100)

  // Initial calculation
  updateContainerWidth()
})

const circleIconStyles = computed((): CSSProperties => ({
  position: 'absolute',
  right: '1.8rem',
  transform: isFeeds.value
    ? 'translateX(0)'
    : 'translateX(calc((var(--container-width) * -1) + 5.5rem))',
  transition: isInitialLoad.value
    ? 'none'
    : 'transform 650ms cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDelay: isInitialLoad.value ? '0ms' : '150ms',
  pointerEvents: 'auto',
  zIndex: 20
}))

// Also update other transition styles to prevent initial animation
const listContainerStyles = computed((): CSSProperties => ({
  transition: isInitialLoad.value
    ? 'none'
    : 'all 500ms cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDelay: isInitialLoad.value ? '0ms' : '150ms'
}))

const listStyles = computed((): CSSProperties => ({
  transform: isFeeds.value ? 'translateX(0)' : 'translateX(-100%)',
  opacity: isFeeds.value ? '1' : '0',
  transition: isInitialLoad.value
    ? 'none'
    : 'all 650ms cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDelay: isInitialLoad.value ? '0ms' : '150ms'
}))

const circleListStyles = computed((): CSSProperties => ({
  transform: isFeeds.value ? 'translateX(100%)' : 'translateX(0)',
  opacity: isFeeds.value ? '0' : '1',
  transition: isInitialLoad.value
    ? 'none'
    : 'all 650ms cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDelay: isInitialLoad.value ? '0ms' : '150ms'
}))

const feedsListRef = ref<HTMLElement | null>(null)
const feedsContentRef = ref<HTMLElement | null>(null)
const circlesListRef = ref<HTMLElement | null>(null)
const circlesContentRef = ref<HTMLElement | null>(null)

const scrollActiveItemIntoView = () => {
  nextTick(() => {
    const activeList = isFeeds.value ? feedsListRef.value : circlesListRef.value
    const activeContent = isFeeds.value ? feedsContentRef.value : circlesContentRef.value

    if (!activeList || !activeContent) return

    // Find active item based on current context
    const activeItem = activeContent.querySelector(
      isFeeds.value
        ? `[href="${
            feedContext?.value === 'all'
              ? '/home'
              : feedContext?.value === 'everyone'
                ? '/home/everyone'
                : '/home/groups'
          }"]`
        : `[href="/circles/${feedContext?.value}"]`
    ) as HTMLElement

    if (!activeItem) return

    const containerWidth = activeList.clientWidth
    const itemLeft = activeItem.offsetLeft
    const itemWidth = activeItem.offsetWidth

    // Calculate ideal scroll position (center item if possible)
    const idealScroll = itemLeft - (containerWidth / 2) + (itemWidth / 2)

    activeList.scrollTo({
      left: Math.max(0, idealScroll),
      behavior: isInitialLoad.value ? 'instant' : 'smooth'
    })
  })
}

// Computed properties to determine if padding is needed
const feedsPadding = computed(() => {
  if (!feedsListRef.value || !feedsContentRef.value) return ''
  const containerWidth = feedsListRef.value.clientWidth - 120 // Subtract gradient width
  const contentWidth = feedsContentRef.value.scrollWidth
  return contentWidth > containerWidth ? 'pr-20' : ''
})

const circlesPadding = computed(() => {
  if (!circlesListRef.value || !circlesContentRef.value) return ''
  const containerWidth = circlesListRef.value.clientWidth - 120 // Subtract gradient width
  const contentWidth = circlesContentRef.value.scrollWidth
  return contentWidth > containerWidth ? 'pr-20' : ''
})

watch(() => [feedContext?.value, contextCategory?.value], () => {
  if (!isInitialLoad.value) {
    scrollActiveItemIntoView()
  }
})
</script>

<template>
  <div
    ref="containerRef"
    class="text-gray-500 w-full block md:hidden px-2"
  >
    <div class="h-8 flex items-center relative">
      <!-- Static Feed Icon -->
      <button
        @click="activeView = 'feed'"
        class="shrink-0"
      >
        <svg
          :class="[
            contextCategory === 'feed' ? 'dark:fill-white fill-black' : 'dark:fill-gray-500 fill-gray-600',
            'w-4 h-4'
          ]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM64 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L96 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"/>
        </svg>
      </button>

      <!-- Lists Container -->
      <div
        class="relative flex-1 mr-3 overflow-hidden h-full"
        :class="[
          isFeeds ? 'ml-3' : 'ml-10'
        ]"
        :style="listContainerStyles"
      >
        <!-- Feeds List -->
        <div
          ref="feedsListRef"
          class="absolute w-full h-full flex items-center overflow-x-auto scrollbar-hide"
          :style="listStyles"
        >
          <div
            ref="feedsContentRef"
            class="flex space-x-6"
            :class="feedsPadding"
          >
            <router-link
              v-for="option in feedOptions"
              :key="option.id"
              :to="option.path"
              :class="[
                feedContext === option.id ? 'dark:text-white text-black' : '',
                'text-sm whitespace-nowrap transition-colors dark:hover:text-white hover:text-black'
              ]"
            >
              {{ option.label }}
            </router-link>
          </div>
        </div>

        <!-- Circles List -->
        <div
          ref="circlesListRef"
          class="absolute w-full h-full flex items-center overflow-x-auto scrollbar-hide"
          :style="circleListStyles"
        >
          <div
            ref="circlesContentRef"
            class="flex space-x-6"
            :class="circlesPadding"
          >
            <router-link
              v-for="option in circleOptions"
              :key="option.id"
              :to="option.path"
              :class="[
                feedContext === option.id ? 'dark:text-white text-black' : '',
                'text-sm whitespace-nowrap transition-colors dark:hover:text-white hover:text-black'
              ]"
            >
              {{ option.label }}
            </router-link>
          </div>
        </div>

        <!-- Gradient Fade -->
        <div class="absolute right-0 top-0 bottom-0 w-5 bg-gradient-to-r from-transparent dark:to-submit-950 to-neutral-125 pointer-events-none" />
      </div>

      <!-- Circle Icon -->
      <div class="absolute inset-x-0 flex items-center pointer-events-none">
        <button
          @click="activeView = 'circles'"
          class="shrink-0 z-10"
          :style="circleIconStyles"
        >
          <svg
            :class="[
              contextCategory === 'circle' ? 'dark:fill-white fill-black' : 'dark:fill-gray-500 fill-gray-600',
              'w-4 h-4'
            ]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M1.1 232C6 179.3 26.9 131.3 58.8 92.7l45.5 45.5C83.7 164.8 69.9 196.9 65.5 232L1.1 232zM138.3 104.3L92.7 58.8C131.3 26.9 179.3 6 232 1.1l0 64.4c-35.1 4.4-67.2 18.2-93.7 38.8zM280 446.5c35.1-4.4 67.2-18.2 93.7-38.8l45.5 45.5C380.7 485.1 332.7 506 280 510.9l0-64.4zm127.7-72.8c20.6-26.6 34.5-58.7 38.8-93.7l64.4 0c-4.9 52.7-25.8 100.7-57.7 139.3l-45.5-45.5zM92.7 453.2l45.5-45.5c26.6 20.6 58.7 34.5 93.7 38.8l0 64.4c-52.7-4.9-100.7-25.8-139.3-57.7zM58.8 419.3C26.9 380.7 6 332.7 1.1 280l64.4 0c4.4 35.1 18.2 67.2 38.8 93.7L58.8 419.3zM419.3 58.8l-45.5 45.5C347.2 83.7 315.1 69.9 280 65.5l0-64.4C332.7 6 380.7 26.9 419.3 58.8zm-11.6 79.5l45.5-45.5c31.9 38.5 52.8 86.6 57.7 139.3l-64.4 0c-4.4-35.1-18.2-67.2-38.8-93.7z"/>
          </svg>
        </button>
      </div>

      <!-- Static Utility Icons -->
      <div class="flex items-center space-x-6 ml-auto">
        <button
          type="button"
          @click="
            feedContext === 'groups'
              ? toggleGroupsSetting?.()
              : feedContext === 'everyone'
                ? toggleEveryoneSetting?.()
                : null
          "
          class="shrink-0"
        >
          <svg class="w-4 h-4 dark:fill-gray-500 fill-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z"/></svg>
        </button>
      </div>
    </div>
  </div>
</template>
