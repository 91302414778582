<script setup lang="ts">
import { useHead } from "@unhead/vue"

useHead({
  title: "Transparency - Submit",
  meta: [
    { name: "description", content: "Discover how Submit prioritizes transparency, accountability, and trust. Explore our commitment to privacy, safety, and ethical practices, fostering an inclusive and respectful community." },
    { name: "robots", content: "index,follow" },
  ]
})
</script>

<template>
  <main class="px-2">
    <article class="my-24 max-w-[672px] lg:max-w-[656px] w-full mx-auto flex flex-col flex-grow space-y-16">
      <header class="text-center space-y-3">
        <h1 class="font-display text-3xl sm:text-5xl font-bold dark:text-gold-500 text-gold-700">Transparency</h1>
      </header>
      <section class="space-y-6">
        <p>At Submit, we believe that openness is the foundation of trust. This portal is your gateway to understanding how we operate, grow, and stay true to our mission. Whether it’s updates on our processes, insights into platform performance, or reports on our progress as a Public Benefit Corporation, we're committed to keeping you informed.</p>
        <p>Transparency is more than a principle&mdash;it's how we ensure accountability to the community we serve. Explore our monthly updates, quarterly analytics, and annual mission reports to see the work happening behind the scenes to make Submit the safest, most inclusive space for BDSM, fetish, and kink communities.</p>
      </section>
      <section class="space-y-6">
        <h2 class="text-2xl">Monthly Updates</h2>
        <ul>
          <li>
            <button class="underline decoration-2 underline-offset-4 dark:decoration-gold-500 decoration-gold-700">2024</button>
          </li>
        </ul>
        <ul class="grid grid-cols-2 sm:grid-cols-4 gap-4">
          <li class="dark:text-gray-500 text-gray-400">
            <span class="cursor-not-allowed">January</span>
          </li>
          <li class="dark:text-gray-500 text-gray-400">
            <span class="cursor-not-allowed">February</span>
          </li>
          <li class="dark:text-gray-500 text-gray-400">
            <span class="cursor-not-allowed">March</span>
          </li>
          <li class="dark:text-gray-500 text-gray-400">
            <span class="cursor-not-allowed">April</span>
          </li>
          <li class="dark:text-gray-500 text-gray-400">
            <span class="cursor-not-allowed">May</span>
          </li>
          <li class="dark:text-gray-500 text-gray-400">
            <span class="cursor-not-allowed">June</span>
          </li>
          <li class="dark:text-gray-500 text-gray-400">
            <span class="cursor-not-allowed">July</span>
          </li>
          <li class="dark:text-gray-500 text-gray-400">
            <span class="cursor-not-allowed">August</span>
          </li>
          <li class="dark:text-gray-500 text-gray-400">
            <span class="cursor-not-allowed">September</span>
          </li>
          <li class="dark:text-gray-500 text-gray-400">
            <span class="cursor-not-allowed">October</span>
          </li>
          <li>
            <router-link class="hover:dark:text-white hover:underline" to="/transparency/2024/monthly/11">November</router-link>
          </li>
          <li>
            <router-link class="hover:dark:text-white hover:underline" to="/transparency/2024/monthly/12">December</router-link>
          </li>
        </ul>
      </section>
      <section class="space-y-6">
        <h2 class="text-2xl">Quarterly Analytics</h2>
        <ul>
          <li>
            <button class="underline decoration-2 underline-offset-4 dark:decoration-gold-500 decoration-gold-700">2024</button>
          </li>
        </ul>
        <ul class="grid grid-cols-4 gap-4">
          <li class="dark:text-gray-500 text-gray-400">
            <span class="cursor-not-allowed">Q1</span>
          </li>
          <li class="dark:text-gray-500 text-gray-400">
            <span class="cursor-not-allowed">Q2</span>
          </li>
          <li class="dark:text-gray-500 text-gray-400">
            <span class="cursor-not-allowed">Q3</span>
          </li>
          <li class="dark:text-gray-500 text-gray-400">
            <span class="cursor-not-allowed">Q4</span>
          </li>
        </ul>
        <p>Transparency reporting started in November 2024, the first report will be made available in January 2025.</p>
      </section>
      <section class="space-y-6">
        <h2 class="text-2xl">Annual Mission Reports</h2>
        <p>Submit completes its first year as a Public Benefit Corporation in December 2025. The first annual mission report will be made available in January 2026.</p>
      </section>
    </article>
  </main>
</template>
