<script setup lang="ts">
import AddOrRemoveToCircle from "@/components/actions/AddOrRemoveToCircle.vue"
import { ref, computed, watch, onBeforeMount, inject, provide, type Ref} from "vue"
import Message from "@/components/actions/Message.vue"
import Follow from "@/components/actions/Follow.vue"
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import { useRoute } from "vue-router"
import { storeToRefs } from "pinia"

const Platform = usePlatformStore()
const UserStore = useUserStore()
const { pfp: PFP, firstLetter: FL, flags } = storeToRefs(UserStore)

const route = useRoute()

interface Props {
  profileData: any
  profileContext: string
}

const props = defineProps<Props>()

const pfp = ref(props.profileData.self ? PFP : props.profileData.pfp)
const firstLetter = ref(props.profileData.self ? FL : props.profileData.firstLetter)
const hideElement = ref(false)

const currentRoute = computed(() => route.name as string)

const followerCount = inject("followerCount") as number
const followingCount = inject("followingCount") as number
const isFollowing = inject("isFollowing") as Ref<boolean>
const followsMe = inject("followsMe") as Ref<boolean>

const followData = computed(() => {
  return {
    _id: props.profileData._id,
    username: props.profileData.username || props.profileData.handle,
    following: isFollowing.value,
    followsMe: followsMe.value,
    privacy: props.profileData.privacy || {},
    private: props.profileData.private || false,
    requested: props.profileData.requested || false,
    self: props.profileData.self || false
  }
})

const messageModalOpen = ref(false)

function toggleMessageModal() {
  messageModalOpen.value = !messageModalOpen.value
}

provide("toggleMessageModal", toggleMessageModal)
const requestSent = ref(false)
function setRequestSent() {
  requestSent.value = true
}
provide("requestSent", {
  requestSent,
  setRequestSent
})

const needsMessageRequest = computed(() => {
  const conditions = props.profileData.messageConditions || {}
  if (!conditions.needsRequest) return false
  if (conditions.needsRequest && conditions.approvedSender) return false
  return true
})

onBeforeMount(() => {
  hideElement.value = smallHeader.value ? true : false
})

const canCircle = computed(() => {
  return flags.value.includes("circles")
})

const canMessage = computed(() => {
  if (!flags.value.includes("messaging")) return false
  if (props.profileContext === "organization") return true
  return props.profileData.messageConditions.canMessage
})

const followVisibility = computed(() => {
  if (props.profileContext === "organization") return true
  if (props.profileData.privacy.following === "disabled") return false
  if (props.profileData.self) return true
  if (props.profileData.privacy.followVisibility === "private" && !props.profileData.self) return false
  if (props.profileData.privacy.followVisibility === "public") return true
  if (props.profileData.privacy.followVisibility === "followers" && !props.profileData.following) return false
  if (props.profileData.privacy.followVisibility === "friends") return false
  if (props.profileData.privacy.followVisibility === "relationships") return false
  return true
})

const smallHeader = computed(() => {
  const routeName = route.name as string
  if (routeName.includes("Profile.Media")) return true
  if (routeName.includes("Profile.Writing")) return true
  if (routeName.includes("Profile.Status")) return true
  if (routeName.includes("Profile.Events")) return true
  return false
})

const noDefaultRoute = computed(() => {
  return [
    "Profile.Media.Media"
  ].includes(currentRoute.value)
})

watch(smallHeader, (newVal) => {
  if (!newVal) {
    // Delay the rendering of the element
    setTimeout(() => {
      hideElement.value = false
    }, 400) // Delay in milliseconds
  } else {
    hideElement.value = true
  }
})

const handleFollow = inject("handleFollow") as () => void
const handleUnfollow = inject("handleUnfollow") as () => void

</script>

<template>
  <section class="bg-submit-925 w-full px-2">
    <Message
      :user="profileData"
      :can-message="canMessage"
      :needs-message-request="needsMessageRequest"
      :message-modal-open="messageModalOpen"
    />
    <div
      :class="[
        smallHeader ? 'relative flex container mx-auto min-h-12 items-end' : 'min-h-[9.375rem] items-end',
        'relative flex container mx-auto ProfileHeaderAnimate'
      ]"
    >
      <router-link
        :to="profileContext === 'user' ? `/${profileData.username}/media` : `/${profileData.handle}`"
        :class="[
          smallHeader ? 'w-16 h-16 -bottom-[24px] text-2xl' : 'left-0 right-0 mx-auto xs:left-auto xs:right-auto xs:mx-0 -bottom-[40px] w-40 h-40 xs:w-36 xs:h-36 sm:w-40 sm:h-40 text-6xl',
          'absolute box-content flex rounded-full bg-neutral-200 dark:bg-submit-800 items-center justify-center font-semibold bg-cover ProfileHeaderAnimate'
        ]"
        :style=" pfp ? `background-image: url('${pfp}')` : ''"
      >
        {{ pfp ? '' : firstLetter }}
      </router-link>
      <div
        :class="[
          smallHeader ? 'ml-16 pl-2 pb-1' : 'hidden xs:flex flex-col ml-40 pl-0 sm:pl-4 w-full pb-2',
          'ProfileHeaderAnimate'
        ]"
      >
        <div class="flex w-full justify-between items-baseline">
          <h1
            :class="[
              smallHeader ? 'text-xl' : 'leading-10  sm:text-[2rem] text-3xl',
              'font-display break-all flex font-bold text-white gap-x-2 items-baseline ProfileHeaderAnimate'
            ]"
          >
            <router-link :to="profileContext === 'user' ? `/${profileData.username}` : `/${profileData.handle}`">{{profileContext === 'user' ? profileData.username : profileData.name}}</router-link>
            <span v-if="!smallHeader && profileData.verified" class="inline md:hidden">
              <svg class="h-[1rem] fill-[#00C2FF]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
            </span>
            <span v-if="!smallHeader && profileContext === 'user' && profileData.backer && profileData.preferences.backerDisplay === 'enabled'" class="inline md:hidden">
              <svg
                :class="[
                  profileData.preferences.backerDisplayType === 'default' ? 'fill-gold-500' : '',
                  profileData.preferences.backerDisplayType === 'green' ? 'fill-green-500' : '',
                  profileData.preferences.backerDisplayType === 'pink' ? 'fill-pink-450' : '',
                  'h-4'
                ]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M160 0c17.7 0 32 14.3 32 32V191.9l0 .1v96c0 35.3 28.7 64 64 64c19.1 0 36.3-8.4 48-21.7c11.7 13.3 28.9 21.7 48 21.7c35.3 0 64-28.7 64-64V224.1l0-.1V96c0-17.7 14.3-32 32-32s32 14.3 32 32V336c0 97.2-78.8 176-176 176H284.8c-59.6 0-116.9-22.9-160-64L12.4 341c-16-15.2-16.6-40.6-1.4-56.6s40.6-16.6 56.6-1.4l60.5 57.6c0-1.5-.1-3.1-.1-4.6V32c0-17.7 14.3-32 32-32zm64 192c0-17.7 14.3-32 32-32s32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V192zm128 0c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"/>
              </svg>
            </span>
            <span v-if="!smallHeader && profileContext === 'user' && (profileData.staff || profileData.volunteer)" class="inline md:hidden">
              <svg class="fill-gold-500" height="16" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
                <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
                <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
              </svg>
            </span>
            <span class="inline md:hidden text-xs font-normal text-gray-400" v-if="profileContext === 'organization'">Org</span>
          </h1>
          <ul
            :class="[
              smallHeader ? 'flex gap-x-1.5 ml-2' : 'hidden md:flex gap-x-4 font-semibold font-display text-gray-500 dark:text-gray-400',
              'ProfileHeaderAnimate text-xs'
            ]"
          >
            <li v-if="profileData.verified" class="flex items-center gap-x-1">
              <svg
                :class="[
                  smallHeader ? 'h-3' : 'h-4',
                  'fill-[#00C2FF]'
                ]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/>
              </svg>
              <span class="text-gray-400" v-if="!smallHeader">Verified</span>
            </li>
            <li v-if="profileContext === 'user' && profileData.backer && profileData.preferences.backerDisplay === 'enabled'" class="flex items-center gap-x-1">
              <svg
                :class="[
                  smallHeader ? 'h-3' : 'h-4',
                  profileData.preferences.backerDisplayType === 'default' ? 'fill-gold-500' : '',
                  profileData.preferences.backerDisplayType === 'green' ? 'fill-green-500' : '',
                  profileData.preferences.backerDisplayType === 'pink' ? 'fill-pink-450' : ''
                ]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M160 0c17.7 0 32 14.3 32 32V191.9l0 .1v96c0 35.3 28.7 64 64 64c19.1 0 36.3-8.4 48-21.7c11.7 13.3 28.9 21.7 48 21.7c35.3 0 64-28.7 64-64V224.1l0-.1V96c0-17.7 14.3-32 32-32s32 14.3 32 32V336c0 97.2-78.8 176-176 176H284.8c-59.6 0-116.9-22.9-160-64L12.4 341c-16-15.2-16.6-40.6-1.4-56.6s40.6-16.6 56.6-1.4l60.5 57.6c0-1.5-.1-3.1-.1-4.6V32c0-17.7 14.3-32 32-32zm64 192c0-17.7 14.3-32 32-32s32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V192zm128 0c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"/>
              </svg>
              <span class="text-gray-400" v-if="!smallHeader">Backer</span>
            </li>
            <li v-if="profileContext === 'user' && (profileData.staff || profileData.volunteer)" class="flex items-center gap-x-1">
              <svg
                :class="[
                  smallHeader ? 'h-3' : 'h-4',
                  'fill-gold-500'
                ]"
                height="16"
                viewBox="0 0 118 143"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
                <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
                <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
              </svg>
              <span class="text-gray-400" v-if="!smallHeader && profileContext === 'user' && profileData.staff">Team</span>
              <span class="text-gray-400" v-if="!smallHeader && profileContext === 'user' && profileData.volunteer">Volunteer</span>
            </li>
            <li v-if="!smallHeader && profileContext === 'organization'">
              <span class="text-gray-400">Org</span>
            </li>
          </ul>
        </div>
        <transition name="fade">
          <div class="text-xs sm:text-sm text-gray-400 font-semibold" v-if="!hideElement">
            {{ profileContext === 'user' ? profileData.meta.metaLabel : profileData.type === 'socialnetwork' ? 'Social Network' : 'Unknown Type' }}
          </div>
        </transition>
        <transition name="fade">
          <div class="text-xs sm:text-sm text-gray-400 font-semibold">
            <template v-if="!hideElement && profileData.location && !profileData.location.version">
              {{ profileContext === 'user' ? profileData.location.display : profileData.location ? profileData.location.display : 'San Francisco, California, USA'}}
            </template>
            <template v-else-if="!hideElement && profileData.location && profileData.location.version && profileData.location.version === 2">
              <template v-if="profileContext === 'organization'">
                San Francisco, California, USA
              </template>
              <template v-else-if="profileData.location.version === 2 && Array.isArray(profileData.location.display)">
                <ul class="flex">
                  <li class="inline" v-for="(location, idx) in profileData.location.display" :key="idx">
                    <router-link class="hover:underline" :to="`/l${location.path}`">{{ location.name }}</router-link>
                    <span v-if="idx < profileData.location.display.length - 1">,&thinsp;</span>
                  </li>
                </ul>
              </template>
              <template v-else>
                {{ profileData.location.display }}
              </template>
            </template>
          </div>
        </transition>
      </div>
      <div class="hidden md:block absolute right-0 -bottom-[18px] ProfileHeaderAnimate">
        <ul class="flex w-full mt-10 gap-x-2 xs:gap-x-4 md:mt-14 md:gap-x-4">
          <AddOrRemoveToCircle v-if="canCircle && (isFollowing || followsMe)" :user="profileData._id" />
          <li class="flex-grow" v-if="!profileData.self && profileContext === 'user'">
            <Follow :user="followData" @follow="handleFollow" @unfollow="handleUnfollow" />
          </li>
          <li class="flex-grow" v-if="!profileData.self && profileContext === 'organization'">
            <Follow :org="followData" @follow="handleFollow" @unfollow="handleUnfollow" />
          </li>
          <li class="flex-grow" v-if="!profileData.self && profileContext === 'user'">
            <button @click="toggleMessageModal" type="button" :class="`bg-submit-600 w-full py-2.5 px-2 xs:px-4 sm:px-8 rounded-[10px] text-xs font-bold transition-all ${canMessage ? 'text-gray-300 hover:text-gold-500' : 'cursor-not-allowed text-gray-300/25'}`" :disabled="!canMessage">
              Message
            </button>
          </li>
          <li v-if="profileData.self" class="bg-submit-600 text-gray-300 flex-grow md:flex-auto py-2.5 px-8 rounded-[10px] text-xs font-bold text-center">
            Your Profile
          </li>
        </ul>
      </div>
    </div>
  </section>
  <section class="flex flex-col w-full xs:hidden mt-12 px-2 justify-center items-center" v-if="!smallHeader">
    <h1 class="font-display mb-1 break-all flex font-bold text-3xl sm:text-[2rem] text-black dark:text-white leading-10 gap-x-2 items-baseline">
      <span>{{profileContext === 'user' ? profileData.username : profileData.name}}</span>
      <span v-if="profileData.verified" class="inline xs:hidden">
        <svg class="h-[1rem] fill-[#00C2FF]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/></svg>
      </span>
      <span v-if="profileContext === 'user' && profileData.backer && profileData.preferences.backerDisplay === 'enabled'" class="inline xs:hidden">
        <svg
          :class="[
            profileData.preferences.backerDisplayType === 'default' ? 'fill-gold-500' : '',
            profileData.preferences.backerDisplayType === 'green' ? 'fill-green-500' : '',
            profileData.preferences.backerDisplayType === 'pink' ? 'fill-pink-450' : '',
            'h-4'
          ]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path d="M160 0c17.7 0 32 14.3 32 32V191.9l0 .1v96c0 35.3 28.7 64 64 64c19.1 0 36.3-8.4 48-21.7c11.7 13.3 28.9 21.7 48 21.7c35.3 0 64-28.7 64-64V224.1l0-.1V96c0-17.7 14.3-32 32-32s32 14.3 32 32V336c0 97.2-78.8 176-176 176H284.8c-59.6 0-116.9-22.9-160-64L12.4 341c-16-15.2-16.6-40.6-1.4-56.6s40.6-16.6 56.6-1.4l60.5 57.6c0-1.5-.1-3.1-.1-4.6V32c0-17.7 14.3-32 32-32zm64 192c0-17.7 14.3-32 32-32s32 14.3 32 32v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V192zm128 0c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"/>
        </svg>
      </span>
      <span v-if="profileContext === 'user' && (profileData.staff || profileData.volunteer)" class="inline xs:hidden">
        <svg class="dark:fill-gold-500 fill-gold-700" height="16" viewBox="0 0 118 143" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.82812 110.309L90.5094 22.6273C94.4146 18.7221 100.746 18.7221 104.652 22.6273L114.551 32.5268L26.8698 120.208C22.9645 124.113 16.6329 124.113 12.7276 120.208L2.82812 110.309Z" />
          <path d="M7.07107 74.9533C3.16583 71.048 3.16582 64.7164 7.07107 60.8111L60.8112 7.07102C64.7164 3.16577 71.0481 3.16577 74.9533 7.07101L77.7817 9.89944C81.687 13.8047 81.687 20.1363 77.7817 24.0416L16.9706 84.8528L7.07107 74.9533Z" />
          <path d="M39.5979 132.936C35.6927 129.031 35.6927 122.699 39.5979 118.794L100.409 57.9827L110.309 67.8822C114.214 71.7875 114.214 78.1191 110.309 82.0244L56.5685 135.764C52.6632 139.67 46.3316 139.67 42.4264 135.764L39.5979 132.936Z" />
        </svg>
      </span>
    </h1>
    <div class="text-sm text-grey-500 dark:text-grey-400 font-semibold">
      {{ profileContext === 'user' ? profileData.meta.metaLabel : profileData.type === 'socialnetwork' ? 'Social Network' : 'Unknown Type' }}
    </div>
    <div class="text-sm text-grey-500 dark:text-grey-400" v-if="profileData.location && !profileData.location.version">
      {{ profileContext === 'user' ? profileData.location.display : profileData.location ? profileData.location.display : 'San Francisco, California, USA'}}
    </div>
    <div class="text-sm text-grey-500 dark:text-grey-400" v-else>
      <template v-if="!hideElement && profileData.location && !profileData.location.version">
        {{ profileContext === 'user' ? profileData.location.display : profileData.location ? profileData.location.display : 'San Francisco, California, USA'}}
      </template>
      <template v-else-if="!hideElement && profileData.location && profileData.location.version && profileData.location.version === 2">
        <template v-if="profileContext === 'organization'">
          San Francisco, California, USA
        </template>
        <template v-else-if="profileData.location.version === 2 && Array.isArray(profileData.location.display)">
          <ul class="flex">
            <li class="inline" v-for="(location, idx) in profileData.location.display" :key="idx">
              <router-link class="hover:underline" :to="`/l${location.path}`">{{ location.name }}</router-link>
              <span v-if="idx < profileData.location.display.length - 1">,&thinsp;</span>
            </li>
          </ul>
        </template>
        <template v-else>
          {{ profileData.location.display }}
        </template>
      </template>
    </div>
  </section>
  <section class="px-2">
    <div
      :class="[
        smallHeader ? 'mt-1' : 'mt-2',
        'container mx-auto flex flex-col'
      ]"
    >
      <ul
        v-if="followVisibility && !noDefaultRoute"
        :class="[
          smallHeader ? 'text-xs ml-16 pl-2' : 'text-sm justify-center xs:justify-normal mt-2 xs:mt-0 xs:ml-40 sm:ml-[10.625rem] pl-0 sm:pl-2',
          'flex text-gray-500 dark:text-gray-400 gap-x-4 ProfileHeaderAnimate'
        ]"
      >
        <li>
          <router-link class="hover:underline" :to="`/${profileContext === 'user' ? profileData.username : profileData.handle}/followers`">
            <span id="followerCount" class="dark:text-white">{{ followerCount }}</span> Follower{{ followerCount === 1 ? '' : 's' }}
          </router-link>
        </li>
        <li v-if="profileContext === 'user'">
          <router-link class="hover:underline" :to="`/${profileData.username}/following`">
            <span id="followingCount" class="dark:text-white">{{ followingCount }}</span> Following
          </router-link>
        </li>
        <li v-if="!profileData.self && profileData.followsMe" class="italic">Follows You</li>
      </ul>
      <div class="text-xs ml-16 pl-2" v-if="smallHeader && currentRoute === 'Profile.Media.Media'">
        <router-link class="dark:hover:text-white hover:text-black" :to="{name: 'Profile.Media.Photos', params: { username: profileData.username || profileData.handle } }">Go to Media</router-link>
      </div>
      <div v-if="!followVisibility && !smallHeader" class="hidden xs:block mt-2 xs:mt-0 h-5"></div>
      <div class="text-xs ml-16 pl-2" v-if="!followVisibility && smallHeader && currentRoute !== 'Profile.Media.Media'">{{ profileData.meta.metaLabel }}</div>
      <div class="flex md:hidden w-full">
        <ul
          :class="[
            smallHeader ? 'mt-4' : 'mt-4 xs:mt-8',
            'flex w-full gap-x-2 xs:gap-x-4'
          ]"
        >
          <AddOrRemoveToCircle v-if="canCircle && (isFollowing || followsMe)" :user="profileData._id" />
          <li class="flex-grow" v-if="!profileData.self">
            <Follow :user="followData" @follow="handleFollow" @unfollow="handleUnfollow" />
          </li>
          <li class="flex-grow" v-if="!profileData.self && profileContext === 'user'">
            <button @click="toggleMessageModal" type="button" :class="`bg-submit-600 w-full py-2.5 px-2 xs:px-4 sm:px-8 rounded-[10px] text-xs font-bold transition-all ${canMessage ? 'text-gray-300 hover:text-gold-500' : 'cursor-not-allowed text-gray-300/25'}`" :disabled="!canMessage">
              Message
            </button>
          </li>
          <li v-if="profileData.self" class="bg-submit-600 text-gray-300 flex-grow md:flex-auto py-2.5 px-8 rounded-[10px] text-xs font-bold text-center">
            Your Profile
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style scoped>
@media (min-width: 480px) {
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-to {
    opacity: 1;
  }
}
</style>
